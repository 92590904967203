

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
// DPS UI  React icons
import { IoBuild } from "react-icons/io5";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MyDocumentImage from "assets/images/send-wizard/my_documents.png";
import MyNftsImage from "assets/images/send-wizard/nfts.png";
import MyInbox from "assets/images/send-wizard/inbox.png";
import UploadImg from "assets/images/send-wizard/upload-icon.png";
function UploadPdf() {
  const customButtonStyles = ({
    functions: { pxToRem },
    borders: { borderWidth },
    palette: { transparent, info },
  }) => ({
    width: pxToRem(150),
    height: pxToRem(150),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      border: `${borderWidth[2]} solid ${info.main} !important`,
    },
  });

  const Input = styled("input")({
    display: "none",
  });

  return (
    <VuiBox mt={10}>
      <VuiBox mt={0}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={3}>
            <VuiBox textAlign="center">
              <label htmlFor="contained-button-file">
                <Input accept="image/*" id="contained-button-file" multiple type="file" />
                <VuiButton color="info" variant={"outline"} sx={customButtonStyles}>
                  <img src={UploadImg} style={{ width: 120, height: 120 }} alt="Upload Img" />
                </VuiButton>
                <VuiTypography variant="h6" color="dark">
                  UPLOAD PDF
                </VuiTypography>
              </label>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <VuiBox textAlign="center">
              <VuiButton color="white" variant={"contained"} sx={customButtonStyles}>
                <img src={MyDocumentImage} style={{ width: 120, height: 120 }} alt="My Document" />
              </VuiButton>
              <VuiTypography variant="h6" color="dark">
                MY DOCUMENTS
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <VuiBox textAlign="center">
              <VuiButton color="white" variant={"contained"} sx={customButtonStyles}>
                <img src={MyNftsImage} style={{ width: 120, height: 120 }} alt="nfts img" />
              </VuiButton>
              <VuiTypography variant="h6" color="dark">
                MY NFTS
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <VuiBox textAlign="center">
              <VuiButton color="white" variant={"contained"} sx={customButtonStyles}>
                <img src={MyInbox} style={{ width: 120, height: 120 }} alt="inbox" />
              </VuiButton>
              <VuiTypography variant="h6" color="dark">
                INBOX
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  );
}

export default UploadPdf;
