// @flow 
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
const index = () => {
    const history = useHistory()
    useEffect(() => {
        history.push("/dashboard")
    })
    return (
        <div>Ok</div>
    );
};
export default index;