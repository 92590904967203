

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { connect } from "react-redux";
import { PostPackagePricings } from "redux/Actions/wizard";
import _ from "lodash";
import { setActSelectPackingPricing, setActSelectPackingPricingData } from "redux/Actions/wizard";
import Spin from "components/Spin";
import axios from "axios";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import dps_usps_logo from "../../../../../assets/images/dps_usps_logo.png";
import ReactHtmlParser from "react-html-parser";
import { Typography } from "@mui/material";



function ePostagePostageType(props) {
    const { values, setFieldValue } = props.formData;
    const { getBtnState, packageType, packageData, packagePricings, SelectedPackagePricing } = props;
    const [selectedsWizard, setWizardSelection] = useState("");
    const [loadPostage, setLoadPostage] = useState(false);

    const [rates, setRates] = useState([]);


    const getShippingRates = () => {
        setLoadPostage(true);
        let payload = null;
        if (values.packageType == "USPS FLAT RATE BOX") {
            payload = {
                DestinationZip: values.recZip,
                OriginZip: values.zip,
                Weight: "10",
                PriceType: "retail",
                Unit: "oz",
                PackageType: values.packageType,
                IsHazardous: props?.hazardous
            };
        } else {
            payload = {
                DestinationZip: values.recZip,
                OriginZip: values.zip,
                PriceType: "retail",
                Unit: "oz",
                PackageType: values.packageType,
                Weight: values.packageWeight,
                Width: values.customWidth,
                Length: values.customLength,
                Height: values.customHeight,
                IsHazardous: props?.hazardous
            };
        }

        let headers = {
            "Content-Type": "application/json",
        };
        let url = `${process.env.REACT_APP_API_BASE_URL}/postage/latest-rates`;

        axios
            .post(url, payload, {
                headers: headers,
            })
            .then((response) => {
                setRates(response.data);
            })
            .finally(() => {
                setLoadPostage(false);
            });
    };

    useEffect(() => {
        getShippingRates();
    }, [])



    const handleWizardSelect = (value, data) => {
        if (value === selectedsWizard) {
            setWizardSelection("");
            props.setActSelectPackingPricing("");
            setFieldValue('flatRate', '')
        } else {
            setWizardSelection(value);
            props.setActSelectPackingPricing(value);
            setFieldValue('flatRate', data.Container)
        }
        data["Rate"] = parseFloat(data.DPSPrice).toFixed(2);
        props.setActSelectPackingPricingData(data);
        props?.onSubmit('next');
    };

    return (
        <Spin tip="Loading..." spinning={loadPostage}>
            <VuiBox>
                <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
                    <VuiBox mb={1}>
                        <VuiTypography variant="lg" fontWeight="bold" color="inherit">
                            SELECT POSTAGE TYPE
                        </VuiTypography>
                    </VuiBox>
                </VuiBox>
                <VuiBox mt={2}>
                    <Grid container spacing={2} justifyContent="center">
                        {_.map(rates || [], (rate, index) => (
                            <Grid item xs={12} md={6} lg={6} key={`postage_${index}`}>
                                <Card style={{ padding: 5, border: `${rate?.ServiceCode}_${index}` === selectedsWizard ? '3px solid #344767' : 'none' }} onClick={() => {
                                }}>
                                    <CardHeader
                                        title={
                                            <strong style={{ fontSize: 16 }}>
                                                <img src={dps_usps_logo} alt="dps-logo" style={{ width: 35 }} />
                                                {ReactHtmlParser(rate?.Title ? rate?.Title : rate?.MailingService)}
                                            </strong>
                                        }
                                    ></CardHeader>
                                    <CardContent style={{ fontSize: 15, paddingBottom: 5 }}>
                                        <div style={{ fontSize: "10px", color: "grey", fontWeight: "bold" }}>
                                            Zone {rate?.Zone}, Commercial Pricing
                                        </div>
                                        {
                                            rate?.DeliveryEstimate?.Location?.ScheduleDeliveryDate ? (
                                                <h6 style={{ fontSize: 14 }}>Estimated delivery by {new Date(rate?.DeliveryEstimate?.Location?.ScheduleDeliveryDate).toDateString()} if shipped today</h6>
                                            ) : rate?.DeliveryEstimate?.ScheduleDeliveryDate ? (
                                                <h6 style={{ fontSize: 14 }}>Estimated delivery by {new Date(rate?.DeliveryEstimate?.ScheduleDeliveryDate).toDateString()} if shipped today</h6>
                                            ) : null
                                        }
                                        <div style={{ fontSize: 12, minHeight: 50 }}>
                                            <span>{rate?.DisplayName?.info}</span>
                                            <br />
                                            <Typography variant="caption">{rate?.SpecialServiceTitle} </Typography>
                                        </div>

                                        <div
                                            style={{
                                                border: "1px solid #1b221b",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                padding: "5px",
                                                alignItems: "center",
                                                backgroundColor: "#3d7c3d5c",
                                            }}
                                        >
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span style={{ color: "green", fontWeight: "bold", fontSize: 20 }}>
                                                    Save{" "}
                                                    {Math.round(((rate?.RegularPrice - parseFloat(rate?.DPSPrice)) / rate?.RegularPrice) * 100)}%
                                                </span>
                                                <span style={{ textDecoration: "line-through" }}>
                                                    ${rate?.RegularPrice}{" "}
                                                    {parseFloat(rate?.RegularPrice) === parseFloat(rate?.DPSPrice)
                                                        ? "commercial"
                                                        : "retail"}
                                                </span>
                                            </div>
                                            <div>
                                                <strong style={{ display: "block", textAlign: "right" }}>
                                                    ${rate?.DPSPrice}
                                                </strong>
                                            </div>
                                        </div>
                                    </CardContent>
                                    <CardActions style={{ display: 'flex', justifyContent: 'end' }}>
                                        <VuiButton color="primary" onClick={() => {
                                            handleWizardSelect(`${rate?.ServiceCode}_${index}`, rate);
                                        }}>
                                            Ship Now
                                        </VuiButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </VuiBox>
            </VuiBox>
        </Spin>
    );
}

export default connect(
    (state) => ({
        loader: state.CommonStore.loader,
        packageType: state.WizardStore.packageType,
        packageData: state.WizardStore.packageData,
        packagePricings: state.WizardStore.packagePricings,
        SelectedPackagePricing: state.WizardStore.SelectedPackagePricing,
    }),
    { PostPackagePricings, setActSelectPackingPricing, setActSelectPackingPricingData }
)(ePostagePostageType);
