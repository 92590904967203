import {
  SET_LOADER,
  LOGIN_SUCCESS,
  SET_PROFILE_STATUS_CODE,
  SET_PROFILE_DATA_SUCCESS,
  SET_PROFILE_DATA_RES,
  SET_USER_FULL_NAME,
} from "../Type";
import { PURGE } from "redux-persist";
const initialState = {
  loader: false,
  isAuthenticated: false,
  Profile: null,
  profile_success: false,
  profile_status: "",
  userFullName: "",
};

const CommonStore = (state = initialState, { type, status, data, status_code }) => {
  switch (type) {
    case PURGE:
      return initialState;
    case SET_LOADER:
      return {
        ...state,
        loader: status,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: status,
      };
    case SET_PROFILE_DATA_RES:
      return {
        ...state,
        Profile: data,
      };
    case SET_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        profile_success: status,
      };
    case SET_PROFILE_STATUS_CODE:
      return {
        ...state,
        profile_status: status_code,
      };
    case SET_USER_FULL_NAME:
      return {
        ...state,
        userFullName: data,
      };
    default:
      return state;
  }
};

export default CommonStore;
