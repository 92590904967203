

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import RecordManagement from "./components/RecordManagment";
import CodRecordManagement from "../COD/components/CodRecordManagment";
import BlockChainRecordManagement from "../Record/components/RecordManagment";
import "./style.css";
import Breadcrumbs from "examples/Breadcrumbs";
import { connect } from "react-redux";
import Spin from "components/Spin";
import {
  setActCertificateOfDelivery,
  setActCertificateOfMailing,
  setActRecordManagement,
  GetCertificateAndStoreage,
} from "redux/Actions/wizard";
function COD(props) {
  const [loadCOM, setLoadCOM] = useState(false);
  const {
    CertificateOfMailing,
    CertificateOfDelivery,
    RecordManagementValue,
    CertificateAndStoreage,
    CertificateAndStoreageSuccess,
    SelectedPackagePricingData,
  } = props;

  useEffect(() => {
    setLoadCOM(true);
    props
      .GetCertificateAndStoreage(props?.Profile?.ShipperHash, SelectedPackagePricingData?.ServiceCode)
      .then((res) => {
        if (res) {
          setLoadCOM(false);
        }
      });
  }, []);

  return (
    <Spin tip="Loading..." spinning={loadCOM}>
      <VuiBox>
        <Breadcrumbs
          icon="home"
          title="Certificates"
          route={[
            "dashboard",
            "General",
            "Sender Info",
            "Recipient Info",
            "Package Type",
            "Postage Type",
            "Certificates",
          ]}
        />
        <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
          <VuiBox mb={1}>
            <VuiTypography variant="xl" fontWeight="bold" color="dark">
              SELECT CERTIFICATES
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <VuiBox mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <VuiBox>
                <RecordManagement
                  prices={CertificateAndStoreageSuccess ? CertificateAndStoreage?.ComPrices : []}
                  CertificateOfMailing={CertificateOfMailing || ""}
                  setActCertificateOfMailing={props.setActCertificateOfMailing}
                />
              </VuiBox>
            </Grid>
            {CertificateAndStoreage?.CodPrices ? (
              <Grid item xs={12} md={4}>
                <VuiBox>
                  <CodRecordManagement
                    prices={CertificateAndStoreageSuccess ? CertificateAndStoreage?.CodPrices : []}
                    CertificateOfDelivery={CertificateOfDelivery || ""}
                    setActCertificateOfDelivery={props.setActCertificateOfDelivery}
                  />
                </VuiBox>
              </Grid>
            ) : (
              ""
            )}

            <Grid item xs={12} md={4}>
              <VuiBox>
                <BlockChainRecordManagement
                  prices={
                    CertificateAndStoreageSuccess ? CertificateAndStoreage?.StoragePrices : []
                  }
                  RecordManagementValue={RecordManagementValue || ""}
                  setActRecordManagement={props.setActRecordManagement}
                />
              </VuiBox>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
    </Spin>
  );
}

export default connect(
  (state) => ({
    CertificateOfDelivery: state.WizardStore.CertificateOfDelivery,
    CertificateOfMailing: state.WizardStore.CertificateOfMailing,
    RecordManagementValue: state.WizardStore.RecordManagement,
    CertificateAndStoreage: state.WizardStore.CertificateAndStoreage,
    CertificateAndStoreageSuccess: state.WizardStore.CertificateAndStoreageSuccess,
    SelectedPackagePricingData: state.WizardStore.SelectedPackagePricingData,
    Profile: state.CommonStore.Profile,
  }),
  {
    setActCertificateOfDelivery,
    setActCertificateOfMailing,
    setActRecordManagement,
    GetCertificateAndStoreage,
  }
)(COD);
