

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import configureStore from "./redux/store";

// DPS UI Context Provider
import { VisionUIControllerProvider } from "context";
let { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <VisionUIControllerProvider>
          <App />
        </VisionUIControllerProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
