
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import VuiButton from "components/VuiButton";
import AccountTable from "./AccountTable";

function GridDiologBox(props) {
  const {
    openGrid,
    getTopicFromTable,
    handleCloseGrid,
    handleTopicPayload,
    previousMailings,
  } = props;
  const [open, setOpen] = useState(openGrid);

  useEffect(() => {
    setOpen(openGrid);
  }, [openGrid]);

  const handleClose = () => {
    handleCloseGrid(false);
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} maxWidth="lg" fullWidth={true} open={open}>
      <DialogTitle>Select Any Previous Mailing</DialogTitle>

      <DialogContent style={{ width: "100%", padding: 10 }}>
        <div style={{ width: "100%", background: "white", padding: 20 }}>
          <AccountTable
            getTopicFromTable={getTopicFromTable}
            previousMailings={previousMailings}
            handleClose={handleClose}
            formData={props.formData}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <VuiButton onClick={handleClose} variant="contained" color="info">
          Close
        </VuiButton>
      </DialogActions>
    </Dialog>
  );
}

export default GridDiologBox;
