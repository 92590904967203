import axios from "axios";

export const PostTrackApi = (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/trackings`;
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
