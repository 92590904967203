

import * as Yup from "yup";
import checkout from "layouts/applications/verify-wizard/schemas/form";

const {
  formField: {
    nftId,
  },
} = checkout;

export default [
  Yup.object().shape({
    [nftId.name]: Yup.string().required(nftId.errorMsg),
  })
];
