import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { GetProfile, setLoaderStatus, setProfileDataRes } from "redux/Actions/common";

import CircularProgress from '@mui/material/CircularProgress';
import GradientBorder from "examples/GradientBorder";
import { Link } from "react-router-dom";
import ResetPasswordForm from "./reset-password-form";
import SignUpVerifyForm from "./verify-email-form";
import UserPool from '../../userPool';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// DPS UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";
import borders from "assets/theme/base/borders";
import { connect } from "react-redux";
import palette from "assets/theme/base/colors";
// DPS UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import { useHistory } from "react-router-dom";
import { useState } from "react";

function CognitoLogin(props) {
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [resetPasswordError, setResetPasswordError] = useState('');
    const [cognitoError, setCognitoError] = useState('');



    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [verifyProcess, setVerifyProcess] = useState(false);



    const [passwordVisible, setPasswordVisible] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);


    const togglePassword = () => setPasswordVisible(prevCheck => !prevCheck);
    const toggleResetPassword = () => setResetPassword(prevCheck => !prevCheck);

    const validateEmail = () => {
        let flag = false;
        setUsernameError('');
        if (username == '' || username == null || username == undefined) {
            setUsernameError('This field is required!');
            flag = true;
        }
        let regEmail = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;

        if (!regEmail.test(username)) {
            setUsernameError('Enter valid email!');
            flag = true;
        }
        return flag;
    }


    const onSubmit = (e) => {
        if (e != null)
            e.preventDefault();


        setPasswordError('');

        if (validateEmail()) {
            return;
        }

        if (password == '' || password == null || password == undefined) {
            setPasswordError('This field is required!');
            return;
        }


        setLoading(true);

        const user = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        });

        user.authenticateUser(authDetails, {
            onSuccess: (result) => {
                localStorage.setItem("username", username);
                localStorage.setItem("id_token", result.getIdToken().getJwtToken());
                localStorage.setItem("access_token", result.getIdToken().getJwtToken());
                localStorage.setItem("token_type", 'Bearer');
                localStorage.setItem("token_exp", result.accessToken.payload.exp);
                console.log('login success', result);
                user.getUserAttributes(function (err, result) {
                    for (let i = 0; i < result.length; i++) {
                        if (result[i].getName() == 'phone_number') {
                            localStorage.setItem('phone_number', result[i].getValue());
                        }
                    }
                    props?.GetProfile().then(res => {
                        setLoading(false);
                        if (res.status == 200) {
                            props?.setProfileDataRes(res.data[0]);
                            if (res.data[0]?.PersonName != null && res.data[0]?.PersonName != undefined) {
                                localStorage.setItem("PersonName", res.data[0]?.PersonName);
                            }
                            if (res.data[0]?.ShipperHash != null && res.data[0]?.ShipperHash != undefined) {
                                localStorage.setItem("ShipperHash", res.data[0].ShipperHash);
                            }
                            history.push('prepare-session?code=login');
                        }
                        else {
                            history.push('prepare-session?code=login');
                        }
                    });

                });

            },
            onFailure: (err) => {
                debugger;
                setLoading(false);
                if (err.code == 'UserNotConfirmedException') {
                    setVerifyProcess(true);
                    onResendCode(null);
                }
                else {
                    setCognitoError(err.message);
                }
            },
            newPasswordRequired: (data) => {
                setLoading(false);
                console.log('new password required', data);
            },
        });
    };

    const onResendCode = (e) => {
        if (loading) {
            return;
        }
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });
        setLoading(true);
        user.resendConfirmationCode(function (err, result) {
            setLoading(false);
            if (err) {
                alert(err.message || JSON.stringify(err));
                return;
            }
            console.log('call result: ' + result);
        });
    }

    const verifyAccount = (OTP, param) => {
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });
        setLoading(true);
        user.confirmRegistration(OTP, true, (err, data) => {
            setLoading(false);
            if (err) {
                console.log(err);
                alert("Couldn't verify account");
            } else {
                if (param != 'reset') {
                    onSubmit(null);
                }
                else {
                    changePassword()
                }
            }
        });
    };

    const changePassword = (verificationCode, newPassword) => {
        setLoading(true);
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });
        user.confirmPassword(verificationCode, newPassword, {
            onSuccess() {
                setLoading(false);
                setResetPassword(false);
            },
            onFailure(err) {
                setLoading(false);
                console.log('Password not confirmed!');
                setResetPasswordError(err.message);
            },
        });
    }

    const resetPasswordInit = () => {
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });

        user.forgotPassword({
            onSuccess: function (data) {
                // successfully initiated reset password request
                console.log('CodeDeliveryData from forgotPassword: ' + data);
            },
            onFailure: function (err) {
                alert(err.message || JSON.stringify(err));
            },
            //Optional automatic callback
            inputVerificationCode: function (data) {
                console.log('Code sent to: ' + data);
            },
        });
    }

    return (
        <div>
            {resetPassword ? (
                <ResetPasswordForm resetPasswordError={resetPasswordError} loading={loading} email={username} back={toggleResetPassword} onSubmit={(otp, password) => {
                    changePassword(otp, password)
                }} onResendCode={onResendCode} />
            ) : (

                <div>
                    {
                        verifyProcess == false ? (
                            <form autoComplete="off" onSubmit={onSubmit} noValidate={true}>
                                <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
                                    <VuiBox
                                        component="div"
                                        borderRadius="inherit"
                                        className="SUMAN"
                                        p="45px"
                                        style={{ minWidth: 380 }}
                                        sx={({ palette: { secondary } }) => ({
                                            backgroundColor: secondary.focus,
                                        })}
                                    >
                                        <VuiTypography
                                            color="white"
                                            fontWeight="bold"
                                            textAlign="center"
                                            mb="24px"
                                            sx={({ typography: { size } }) => ({
                                                fontSize: size.lg,
                                            })}
                                        >
                                            Login With
                                        </VuiTypography>


                                        <VuiBox mb={2}>
                                            <VuiBox mb={1} ml={0.5}>
                                                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                                                    Email
                                                </VuiTypography>
                                            </VuiBox>
                                            <GradientBorder
                                                minWidth="100%"
                                                borderRadius={borders.borderRadius.lg}
                                                padding="1px"
                                                backgroundImage={radialGradient(
                                                    palette.gradients.borderLight.main,
                                                    palette.gradients.borderLight.state,
                                                    palette.gradients.borderLight.angle
                                                )}
                                            >
                                                <VuiInput
                                                    type="email"
                                                    placeholder="Your email..."
                                                    error={usernameError.length > 0}
                                                    autoComplete="off"
                                                    sx={({ typography: { size } }) => ({
                                                        fontSize: size.sm,
                                                    })}
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                />
                                            </GradientBorder>
                                            <span style={{ display: 'block', color: 'red', fontSize: 11, fontWeight: 'bold', paddingLeft: 5 }}>{usernameError}</span>
                                        </VuiBox>
                                        <VuiBox mb={2} style={{ position: 'relative' }}>
                                            <VuiBox mb={1} ml={0.5}>
                                                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                                                    Password
                                                </VuiTypography>
                                            </VuiBox>
                                            <GradientBorder
                                                minWidth="100%"
                                                borderRadius={borders.borderRadius.lg}
                                                padding="1px"
                                                backgroundImage={radialGradient(
                                                    palette.gradients.borderLight.main,
                                                    palette.gradients.borderLight.state,
                                                    palette.gradients.borderLight.angle
                                                )}
                                            >
                                                <VuiInput
                                                    type={passwordVisible ? "text" : "password"}
                                                    error={passwordError.length > 0}
                                                    style={{ paddingRight: 40 }}
                                                    placeholder="Your password..."
                                                    autoComplete="new-password"
                                                    sx={({ typography: { size } }) => ({
                                                        fontSize: size.sm,
                                                    })}
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </GradientBorder>
                                            <span style={{ display: 'block', color: 'red', fontSize: 11, fontWeight: 'bold', paddingLeft: 5 }}>{passwordError}</span>

                                            <span style={{ position: 'absolute', top: 38, right: 10, cursor: 'pointer' }} onClick={togglePassword}>
                                                {
                                                    passwordVisible ? <VisibilityOffIcon fontSize={'medium'} /> : <VisibilityIcon fontSize={'medium'} />
                                                }
                                            </span>

                                            <VuiTypography variant="button" color="white" fontWeight="regular">
                                                <VuiTypography
                                                    variant="button"
                                                    color="white"
                                                    fontWeight="medium"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        if (!validateEmail()) {
                                                            resetPasswordInit();
                                                            toggleResetPassword();
                                                        }
                                                    }}
                                                >
                                                    Forgot your password?
                                                </VuiTypography>
                                            </VuiTypography>
                                        </VuiBox>

                                        <VuiBox mb={2}>
                                            <span style={{ display: 'block', color: 'red', fontSize: 11, fontWeight: 'bold', paddingLeft: 5 }}>{cognitoError}</span>
                                        </VuiBox>

                                        <VuiBox mt={4} mb={1}>
                                            <VuiButton color="info" fullWidth type="submit" disabled={loading}>
                                                SIGN IN {loading ? <CircularProgress size={16} style={{ marginLeft: 8 }} color="error" /> : ""}
                                            </VuiButton>
                                        </VuiBox>
                                        <VuiBox mt={3} textAlign="center">
                                            <VuiTypography variant="button" color="white" fontWeight="regular">
                                                {/* Need an account?{" "}
                                                <VuiTypography
                                                    component={Link}
                                                    to="/sign-up"
                                                    variant="button"
                                                    color="white"
                                                    fontWeight="medium"
                                                >
                                                    Sign up
                                                </VuiTypography> */}
                                            </VuiTypography>
                                        </VuiBox>
                                    </VuiBox>
                                </GradientBorder>

                            </form>
                        ) : <SignUpVerifyForm loading={loading} email={username} onSubmit={verifyAccount} onResendCode={onResendCode} />
                    }
                </div>
            )
            }
        </div>
    )
}

// export default CognitoLogin;

export default connect(
    (state) => ({
        Profile: state.CommonStore.Profile,
    }),
    {
        GetProfile,
        setProfileDataRes,
        setLoaderStatus
    }
)(CognitoLogin);