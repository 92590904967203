import React, { useState } from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from "moment";


import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';


function Row(props) {
    const { row } = props;

    const [actionButtonOpen, setActionButtonOpen] = useState(false);
    const actionRef = React.useRef(null);

    const handleActionToggle = () => {
        setActionButtonOpen((prevOpen) => !prevOpen);
    };

    const handleActionClose = (event) => {
        if (actionRef.current && actionRef.current.contains(event.target)) {
            return;
        }

        setActionButtonOpen(false);
    };

    return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
                <div title={row?.DocumentName} style={{ maxWidth: 220, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <a href="#" onClick={() => {
                        props?.onRowClick(row, 'document_preview')
                    }}>{row?.DocumentName}</a>
                </div>
            </TableCell>
            <TableCell>{row?.Category}</TableCell>
            <TableCell>{row?.PageCount}</TableCell>
            <TableCell>
                {row?.eNotarized ? moment(row?.eNotarized).format("MMM, DD YYYY") : ""}
            </TableCell>
            {/* <TableCell>{row?.LastStatus}</TableCell> */}
            <TableCell>
                <span>Page Size: {row?.DocInfo?.length > 0 ? row?.DocInfo[0]?.Dimension : ""}</span>
                <br />
                <span>{row?.Print2MailAcceptable ? "Acceptable for Print2Mail" : ""}</span>
            </TableCell>
            <TableCell>

                <>
                    {/* <ButtonGroup variant="contained" ref={actionRef} style={{ boxShadow: 'none' }} size="small">
                        <Button ref={actionRef} size="small" style={{ paddingLeft: 10, paddingRight: 10 }} onClick={handleActionToggle} endIcon={<ArrowDropDownIcon />}>Send</Button>
                    </ButtonGroup> */}
                    <Button ref={actionRef} variant="contained" color="primary" size="small" style={{ paddingLeft: 10, paddingRight: 10 }} onClick={handleActionToggle} endIcon={<ArrowDropDownIcon />}>Send</Button>

                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={actionButtonOpen}
                        anchorEl={actionRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper style={{ minHeight: 50 }}>
                                    <ClickAwayListener onClickAway={handleActionClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            <MenuItem onClick={() => { props?.onRowClick(row, 'ePostage') }}>ePostage</MenuItem>
                                            {
                                                row?.Print2MailAcceptable ? (
                                                    <MenuItem onClick={() => { props?.onRowClick(row, 'print_to_mail') }}>Print to Mail</MenuItem>
                                                ) : null
                                            }
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}

                    </Popper>
                </>

            </TableCell>
        </TableRow>
    )
}

export default Row;