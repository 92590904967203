import axios from "axios";

export const getTopicApi = (name, subject) => {
  let url = `${process.env.REACT_APP_API_BASE_URL}/topics/${localStorage.ShipperHash}?Name=${name}`;
  if (subject != null) {
    url = url + '&Subject=' + subject
  }
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .get(url, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const PostAddressVerifyApi = (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/addresses/verify-address`;
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const PostPackagePricingsApi = (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/postage/pricings`;
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const PostTopicApi = (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/topics`;
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const PostJobApi = (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/jobs`;
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const PostPackageCheckOutApi = (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/jobs/package-checkout`;
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const PostRecipientApi = (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/addresses`;
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const GetCertificateAndStoreageApi = (VendorHash, PostageType) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/prices/${VendorHash}?PostageType=${PostageType}`;
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .get(url, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const GetSenderInfoApi = () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/shippers/details/${localStorage.username}`;
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .get(url, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const GetAddressBookApi = (params) => {
  var url = `${process.env.REACT_APP_API_BASE_URL}/addressbook/${localStorage.ShipperHash
    }/${-1}/address`;
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios(url, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
