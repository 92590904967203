

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// DPS UI  React icons
import { IoBuild } from "react-icons/io5";
import { IoCube } from "react-icons/io5";
import { RiPencilFill } from "react-icons/ri";
import DocumentType from "./Components/DocumentType";

function Document(props) {
  const { getSendNextBtnState, getSelectedWizard } = props;
  const [selectedsWizard, setWizardSelection] = useState("");

  const handleWizardSelect = (value) => {
    if (value === selectedsWizard) {
      setWizardSelection("");
      getSendNextBtnState(true);
    } else {
      setWizardSelection(value);
      getSendNextBtnState(false);
    }
  };

  const customButtonStyles = ({
    functions: { pxToRem },
    borders: { borderWidth },
    palette: { transparent, info },
  }) => ({
    width: pxToRem(150),
    height: pxToRem(150),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      border: `${borderWidth[2]} solid ${info.main} !important`,
    },
  });

  useEffect(() => {
    if (selectedsWizard) {
      getSendNextBtnState(false);
    } else {
      getSendNextBtnState(true);
    }
  }, [selectedsWizard]);

  return (
    <VuiBox mt={10}>
      <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <VuiBox mb={1}>
          <VuiTypography variant="lg" fontWeight="bold" color="white">
            SELECT DOCUMENT TYPE
          </VuiTypography>
        </VuiBox>
      </VuiBox>
      <VuiBox mt={2}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={2.5}>
            <VuiBox textAlign="center">
              <VuiButton
                color="info"
                variant={selectedsWizard === "MY DOCUMENTS" ? "contained" : "outlined"}
                onClick={() => {
                  handleWizardSelect("MY DOCUMENTS");
                }}
                sx={customButtonStyles}
              >
                <IoBuild size="54px" color="white" />
              </VuiButton>
              <VuiTypography variant="h6" color="dark">
                MY DOCUMENTS
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={2.5}>
            <VuiBox textAlign="center">
              <VuiButton
                color="info"
                variant={selectedsWizard === "PACKAGE" ? "contained" : "outlined"}
                onClick={() => {
                  handleWizardSelect("PACKAGE");
                }}
                sx={customButtonStyles}
              >
                <IoCube size="54px" color="white" />
              </VuiButton>
              <VuiTypography variant="h6" color="dark">
                MY NFTS
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={2.5}>
            <VuiBox textAlign="center">
              <VuiButton
                color="info"
                variant={selectedsWizard === "DIGITAL FILE" ? "contained" : "outlined"}
                onClick={() => {
                  handleWizardSelect("DIGITAL FILE");
                }}
                sx={customButtonStyles}
              >
                <RiPencilFill size="54px" color="white" />
              </VuiButton>
              <VuiTypography variant="h6" color="dark">
                INBOX
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
      {selectedsWizard === "MY DOCUMENTS" ? <DocumentType /> : ""}
    </VuiBox>
  );
}

export default Document;
