import {
  POST_ADDRESS_VERIFY_RES,
  POST_ADDRESS_VERIFY_SUCCESS,
  POST_PACKAGE_PRICINGS_RES,
  POST_PACKAGE_PRICINGS_SUCCESS,
  SET_ACTIVE_STEP,
  SET_CERTIFICATE_OF_DELIVERY,
  SET_CERTIFICATE_OF_MAILING,
  SET_IS_ADDRESS_VERIY,
  SET_PACKAGE_DATA,
  SET_PACKAGE_TYPE,
  SET_RECIPIENT_INFO,
  SET_RECORD_MANAGEMENT,
  SET_SELECTED_TOPIC_DATA,
  SET_SELECT_PACKAGE_PRICING,
  SET_SELECT_PACKAGE_PRICING_DATA,
  SET_SENDER_INFO,
  SET_SEARCH_TOPIC_DATA,
  SET_POST_TOPIC_SUCCESS,
  SET_POST_TOPIC_RES,
  SET_POST_JOB_RES,
  SET_PACKAGE_CHECKOUT_RES,
  SET_PACKAGE_CHECKOUT_SUCCESS,
  SET_JOB_POST_SUCCESS,
  SET_RECIPIENT_RES,
  SET_RECIPIENT_SUCCESS,
  SET_CERTIFICATE_AND_STOREAGE_RES,
  SET_CERTIFICATE_AND_STOREAGE_SUCCESS,
  SET_SENDER_INFO_RES,
  SET_SENDER_INFO_SUCCESS,
  SET_POST_TOPIC_ERROR,
  SET_JOB_POST_ERROR,
  SET_RECIPIENT_ERROR,
  SET_PACKAGE_CHECKOUT_ERROR,
  LOAD_SENDER_INFO,
  RESET_WIZARD,
  SET_POST_TOPIC_RES_EMPTY,
  SET_ADDRESS_BOOK_RES,
  SET_ADDRESS_BOOK_SUCCESS,
} from "../Type";
import {
  GetAddressBookApi,
  GetCertificateAndStoreageApi,
  GetSenderInfoApi,
  getTopicApi,
  PostAddressVerifyApi,
  PostJobApi,
  PostPackageCheckOutApi,
  PostPackagePricingsApi,
  PostRecipientApi,
  PostTopicApi,
} from "../Api/wizard";
import { setLoaderStatus } from "./common";

export const setActiveStep = (args) => {
  return {
    type: SET_ACTIVE_STEP,
    args,
  };
};

export const setTopicData = (data) => {
  return {
    type: SET_SEARCH_TOPIC_DATA,
    data,
  };
};

export const getTopic = (name, subject) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return getTopicApi(name, subject);
};

export const setIsAddressVerify = (status) => {
  return {
    type: SET_IS_ADDRESS_VERIY,
    status,
  };
};

export const setSenderInfo = (data) => {
  return {
    type: SET_SENDER_INFO,
    data,
  };
};

export const PostVerifyAddressRes = (data) => {
  return {
    type: POST_ADDRESS_VERIFY_RES,
    data,
  };
};

export const PostVerifyAddressSuccess = (status) => {
  return {
    type: POST_ADDRESS_VERIFY_SUCCESS,
    status,
  };
};

export const PostVerifyAddress = (data) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return PostAddressVerifyApi(data);
};

export const setActRecipientInfo = (data) => {
  return {
    type: SET_RECIPIENT_INFO,
    data,
  };
};

export const setActPackageType = (data) => {
  return {
    type: SET_PACKAGE_TYPE,
    data,
  };
};

export const setActPackageData = (data) => {
  return {
    type: SET_PACKAGE_DATA,
    data,
  };
};

export const PostPackagePricingsRes = (data) => {
  return {
    type: POST_PACKAGE_PRICINGS_RES,
    data,
  };
};

export const PostPackagePricingsSuccess = (status) => {
  return {
    type: POST_PACKAGE_PRICINGS_SUCCESS,
    status,
  };
};

export const PostPackagePricings = (data) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return PostPackagePricingsApi(data).then((res) => {
    if (res.status === 200) {
      dispatch(setLoaderStatus(false));
      dispatch(PostPackagePricingsRes(res.data));
      dispatch(PostPackagePricingsSuccess(true));
      return true;
    } else {
      dispatch(setLoaderStatus(false));
      try {
        dispatch(PostPackagePricingsSuccess(false));
        return updateError(error.message);
      } catch {
        return updateError("network error");
      }
    }
  });
};

export const setActCertificateOfDelivery = (data) => {
  return {
    type: SET_CERTIFICATE_OF_DELIVERY,
    data,
  };
};

export const setActCertificateOfMailing = (data) => {
  return {
    type: SET_CERTIFICATE_OF_MAILING,
    data,
  };
};

export const setActRecordManagement = (data) => {
  return {
    type: SET_RECORD_MANAGEMENT,
    data,
  };
};

export const setActSelectPackingPricing = (data) => {
  return {
    type: SET_SELECT_PACKAGE_PRICING,
    data,
  };
};

export const setActSelectPackingPricingData = (data) => {
  return {
    type: SET_SELECT_PACKAGE_PRICING_DATA,
    data,
  };
};

export const setActSeletedTopicData = (data) => {
  return {
    type: SET_SELECTED_TOPIC_DATA,
    data,
  };
};

export const setActTopicPostRes = (data) => {
  return {
    type: SET_POST_TOPIC_RES,
    data,
  };
};

export const setActTopicPostResEmpty = () => {
  return {
    type: SET_POST_TOPIC_RES_EMPTY,
  };
};

export const setActTopicSuccess = (status) => {
  return {
    type: SET_POST_TOPIC_SUCCESS,
    status,
  };
};

export const setActTopicError = (status) => {
  return {
    type: SET_POST_TOPIC_ERROR,
    status,
  };
};

export const ActPostTopic = (data) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return PostTopicApi(data);
};

export const setPostJobRes = (data) => {
  return {
    type: SET_POST_JOB_RES,
    data,
  };
};

export const setJobPostSuccess = (status) => {
  return {
    type: SET_JOB_POST_SUCCESS,
    status,
  };
};

export const setJobPostError = (status) => {
  return {
    type: SET_JOB_POST_ERROR,
    status,
  };
};

export const ActPostJob = (data) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return PostJobApi(data).then((res) => {
    if (res.status === 200) {
      dispatch(setLoaderStatus(false));
      dispatch(setPostJobRes(res.data));
      dispatch(setJobPostSuccess(true));
      return updateSuccess(res.data);
    } else {
      dispatch(setLoaderStatus(false));
      try {
        dispatch(setJobPostError(true));
        return updateError(error.message);
      } catch {
        return updateError("network error");
      }
    }
  });
};

export const setActPostPackageCheckOutRes = (data) => {
  return {
    type: SET_PACKAGE_CHECKOUT_RES,
    data,
  };
};

export const setActPostPackageCheckOutSuccess = (status) => {
  return {
    type: SET_PACKAGE_CHECKOUT_SUCCESS,
    status,
  };
};

export const setActPostPackageCheckOutError = (status) => {
  return {
    type: SET_PACKAGE_CHECKOUT_ERROR,
    status,
  };
};

export const PostPackageCheckOut = (data) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return PostPackageCheckOutApi(data).then((res) => {
    if (res.status === 200) {
      dispatch(setLoaderStatus(false));
      dispatch(setActPostPackageCheckOutRes(res.data));
      dispatch(setActPostPackageCheckOutSuccess(true));
      return updateSuccess(res.data);
    } else {
      dispatch(setLoaderStatus(false));
      try {
        dispatch(setActPostPackageCheckOutError(true));
        return updateError(error.message);
      } catch {
        return updateError("network error");
      }
    }
  });
};

export const setActPostRecipientRes = (data) => {
  return {
    type: SET_RECIPIENT_RES,
    data,
  };
};

export const setActRecipientSuccess = (status) => {
  return {
    type: SET_RECIPIENT_SUCCESS,
    status,
  };
};

export const setActRecipientError = (status) => {
  return {
    type: SET_RECIPIENT_ERROR,
    status,
  };
};

export const PostRecipient = (data) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return PostRecipientApi(data).then((res) => {
    if (res.status === 200) {
      dispatch(setLoaderStatus(false));
      dispatch(setActPostRecipientRes(res.data));
      dispatch(setActRecipientSuccess(true));
      return updateSuccess(res.data);
    } else {
      dispatch(setLoaderStatus(false));
      try {
        dispatch(setActRecipientError(true));
        return updateError(error.message);
      } catch {
        return updateError("network error");
      }
    }
  });
};

export const setActCertificateAndStoreageRes = (data) => {
  return {
    type: SET_CERTIFICATE_AND_STOREAGE_RES,
    data,
  };
};

export const UpdateLoadSenderInfo = (status) => {
  return {
    type: LOAD_SENDER_INFO,
    status,
  };
};

export const ResetWizard = () => {
  return {
    type: RESET_WIZARD,
  };
};

export const setActCertificateAndStorageSuccess = (status) => {
  return {
    type: SET_CERTIFICATE_AND_STOREAGE_SUCCESS,
    status,
  };
};

export const GetCertificateAndStoreage = (VendorHash, PostageType) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return GetCertificateAndStoreageApi(VendorHash, PostageType).then((res) => {
    if (res.status === 200) {
      dispatch(setLoaderStatus(false));
      dispatch(setActCertificateAndStoreageRes(res.data));
      dispatch(setActCertificateAndStorageSuccess(true));
      return true;
    } else {
      dispatch(setLoaderStatus(false));
      try {
        dispatch(setActCertificateAndStorageSuccess(false));
        return updateError(error.message);
      } catch {
        return updateError("network error");
      }
    }
  });
};

export const setActSenderInfoRes = (data) => {
  return {
    type: SET_SENDER_INFO_RES,
    data,
  };
};

export const setActSenderInfoSuccess = (status) => {
  return {
    type: SET_SENDER_INFO_SUCCESS,
    status,
  };
};

export const GetActSenderInfo = () => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return GetSenderInfoApi().then((res) => {
    if (res.status === 200) {
      dispatch(setLoaderStatus(false));
      dispatch(setActSenderInfoRes(res.data));
      dispatch(setActSenderInfoSuccess(true));
    } else {
      dispatch(setLoaderStatus(false));
      try {
        dispatch(setActSenderInfoSuccess(false));
        return updateError(error.message);
      } catch {
        return updateError("network error");
      }
    }
  });
};

const updateError = (error_msg) => {
  return {
    status: false,
    error: error_msg,
  };
};

const updateSuccess = (res_data) => {
  return {
    status: true,
    data: res_data,
  };
};

export const setActAddressBookRes = (data) => {
  return {
    type: SET_ADDRESS_BOOK_RES,
    data,
  };
};

export const setActAddressBookSuccess = (status) => {
  return {
    type: SET_ADDRESS_BOOK_SUCCESS,
    status,
  };
};

export const ActGetAddressBook = (params) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return GetAddressBookApi(params).then((res) => {
    if (res.status === 200) {
      dispatch(setLoaderStatus(false));
      dispatch(setActAddressBookRes(res.data));
      dispatch(setActAddressBookSuccess(true));
    } else {
      dispatch(setLoaderStatus(false));
      try {
        dispatch(setActAddressBookSuccess(false));
        dispatch(setActAddressBookRes([]));
        return updateError(error.message);
      } catch {
        return updateError("network error");
      }
    }
  });
};
