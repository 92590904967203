import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import { useHistory } from "react-router-dom";

const Page404 = () => {
    const history = useHistory();
    return (
        <VuiBox>
            <div style={{ width: '50%', margin: '10% auto' }}>
                <VuiTypography color="error" fontWeight="bold" variant="h1" textGradient={true}>Error 404</VuiTypography>
                <br />
                <br />
                <VuiTypography color="secondary" variant="h3" textGradient>Page not found</VuiTypography>
                <br />
                <br />
                <VuiButton color="secondary" style={{ marginLeft: 80 }} onClick={() => {
                    history.push('/');
                }}>Go to Home Page</VuiButton>
            </div>
        </VuiBox>
    );
}
export default Page404;