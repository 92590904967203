

// DPS UI  React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// DPS UI  React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";

const { borderWidth, borderColor } = borders;
const { transparent, gradients, info, white, grey } = colors;

export default {
  styleOverrides: {
    root: {
      width: pxToRem(20),
      height: pxToRem(20),
      marginRight: pxToRem(6),
      padding: 0,
      color: grey[700],
      border: `${borderWidth[1]} solid ${borderColor}`,
      borderRadius: pxToRem(5.6),
      transition: "all 250ms ease",
    },
  },
};
