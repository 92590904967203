export const TEST = "TEST";
export const TEST1 = "TEST1";
export const SET_SHIPPER_HASH = "SET_SHIPPER_HASH";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_SEARCH_TOPIC_DATA = "SET_SEARCH_TOPIC_DATA";
export const SET_LOADER = "SET_LOADER";
export const SET_STATE = "SET_STATE";
export const SET_IS_ADDRESS_VERIY = "SET_IS_ADDRESS_VERIY";
export const SET_SENDER_INFO = "SET_SENDER_INFO";
export const POST_ADDRESS_VERIFY_RES = "POST_ADDRESS_VERIFY_RES";
export const POST_ADDRESS_VERIFY_SUCCESS = "POST_ADDRESS_VERIFY_SUCCESS";
export const SET_ADDRESS_BOOK_DATA = "SET_ADDRESS_BOOK_DATA";
export const SET_RECIPIENT_INFO = "SET_RECIPIENT_INFO";
export const SET_PACKAGE_TYPE = "SET_PACKAGE_TYPE";
export const SET_PACKAGE_DATA = "SET_PACKAGE_DATA";
export const POST_PACKAGE_PRICINGS_RES = "POST_PACKAGE_PRICINGS_RES";
export const POST_PACKAGE_PRICINGS_SUCCESS = "POST_PACKAGE_PRICINGS_SUCCESS";
export const SET_CERTIFICATE_OF_DELIVERY = "SET_CERTIFICATE_OF_DELIVERY";
export const SET_CERTIFICATE_OF_MAILING = "SET_CERTIFICATE_OF_MAILING";
export const SET_RECORD_MANAGEMENT = "SET_RECORD_MANAGEMENT";
export const SET_SELECT_PACKAGE_PRICING = "SET_SELECT_PACKAGE_PRICING";
export const SET_SELECT_PACKAGE_PRICING_DATA = "SET_SELECT_PACKAGE_PRICING_DATA";
export const SET_POST_TOPIC_RES = "SET_POST_TOPIC_RES";
export const SET_POST_TOPIC_RES_EMPTY = "SET_POST_TOPIC_RES_EMPTY";
export const SET_POST_TOPIC_SUCCESS = "SET_POST_TOPIC_SUCCESS";
export const SET_POST_TOPIC_ERROR = "SET_POST_TOPIC_ERROR";
export const SET_POST_JOB_RES = "SET_POST_JOB_RES";
export const SET_JOB_POST_SUCCESS = "SET_JOB_POST_SUCCESS";
export const SET_JOB_POST_ERROR = "SET_JOB_POST_ERROR";
export const SET_PACKAGE_CHECKOUT_RES = "SET_PACKAGE_CHECKOUT_RES";
export const SET_PACKAGE_CHECKOUT_SUCCESS = "SET_PACKAGE_CHECKOUT_SUCCESS";
export const SET_PACKAGE_CHECKOUT_ERROR = "SET_PACKAGE_CHECKOUT_ERROR";
export const SET_RECIPIENT_RES = "SET_RECIPIENT_RES";
export const SET_RECIPIENT_SUCCESS = "SET_RECIPIENT_SUCCESS";
export const SET_RECIPIENT_ERROR = "SET_RECIPIENT_ERROR";
export const SET_CERTIFICATE_AND_STOREAGE_RES = "SET_CERTIFICATE_AND_STOREAGE_RES";
export const SET_CERTIFICATE_AND_STOREAGE_SUCCESS = "SET_CERTIFICATE_AND_STOREAGE_SUCCESS";
export const SET_SELECTED_TOPIC_DATA = "SET_SELECTED_TOPIC_DATA"; // BOTH NEW OR EXISTING TOPIC
export const SET_SENDER_INFO_RES = "SET_SENDER_INFO_RES";
export const SET_SENDER_INFO_SUCCESS = "SET_SENDER_INFO_SUCCESS";
export const LOAD_SENDER_INFO = "LOAD_SENDER_INFO";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const RESET_WIZARD = "RESET_WIZARD";
export const SET_POST_TRACK = "SET_POST_TRACK";
export const SET_POST_TRACK_SUCCESS = "SET_POST_TRACK_SUCCESS";
export const SET_POST_TRACK_ERROR = "SET_POST_TRACK_ERROR";
export const SET_GET_DOCUMENT = "SET_GET_DOCUMENT";
export const SET_GET_DOCUMENT_SUCCESS = "SET_GET_DOCUMENT_SUCCESS";
export const SET_GET_DOCUMENT_ERROR = "SET_GET_DOCUMENT_ERROR";
export const SET_GET_NFT = "SET_GET_NFT";
export const SET_GET_NFT_SUCCESS = "SET_GET_NFT_SUCCESS";
export const SET_GET_NFT_ERROR = "SET_GET_NFT_ERROR";
export const SET_NFT_RESPONSE = "SET_NFT_RESPONSE";
export const SET_VERIFY_RESPONSE = "SET_VERIFY_RESPONSE";
export const SET_VERIFY_WIZARD = "SET_VERIFY_WIZARD";
export const SET_ADDRESS_BOOK_RES = "SET_ADDRESS_BOOK_RES";
export const SET_ADDRESS_BOOK_SUCCESS = "SET_ADDRESS_BOOK_SUCCESS";
export const SET_PROFILE_DATA_RES = "SET_PROFILE_DATA_RES";
export const SET_PROFILE_DATA_SUCCESS = "SET_PROFILE_DATA_SUCCESS";
export const SET_PROFILE_STATUS_CODE = "SET_PROFILE_STATUS_CODE";
export const SET_USER_FULL_NAME = "SET_USER_FULL_NAME";
export const SET_CREATE_ADDRESS_BOOK_RES = "SET_CREATE_ADDRESS_BOOK_RES";
export const SET_CREATE_ADDRESS_BOOK_SUCCESS = "SET_CREATE_ADDRESS_BOOK_SUCCESS";
