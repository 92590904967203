

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useEffect, useState } from "react";

function Breadcrumbs({ icon, title, route, light }) {
  // const routes = route.slice(0, -1);
  const [routes, setRoutes] = useState([]);
  const [currentTitle, setCurrentTitle] = useState('');

  useEffect(() => {
    if (route != null && route != undefined) {
      let x = route.slice(0, -1);
      let arr = [];
      x.map(item => {
        let y = +item;
        if (isNaN(y)) {
          arr.push(item)
          setCurrentTitle(item)
        }
      });
      setRoutes(arr);
    }
    else {
      setRoutes([]);
    }
  }, [route])

  return (
    <VuiBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/">
          <VuiTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </VuiTypography>
        </Link>
        {routes.map((el) => (
          // (+el) == NaN ?
          <Link to={`/${el}`} key={el}>
            <VuiTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="uppercase"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {el}
            </VuiTypography>
          </Link>
        ))}
        {/* <VuiTypography
          variant="button"
          fontWeight="regular"
          textTransform="uppercase"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {title.replace("-", " ")}
        </VuiTypography> */}
      </MuiBreadcrumbs>
      {/* <VuiTypography
        fontWeight="bold"
        textTransform="uppercase"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {currentTitle.replace("-", " ")}
      </VuiTypography> */}
    </VuiBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
