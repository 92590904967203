
/**
 * The base breakpoints for the Vision UI Dashboard PRO Material.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire Vision UI Dashboard PRO Material using thie file.
 */

export default {
  // values: {
  //   xs: 375,
  //   sm: 425,
  //   md: 768,
  //   lg: 1024,
  //   xl: 1440,
  //   xxl: 1680,
  // },
  // Above breakpoints are modified. Below breakpoints are restored from original source by Suman
  // Do not change it again
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
};
