import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiInput from "components/VuiInput";
// @mui material components
import Grid from "@mui/material/Grid";
import { Field } from "formik";
import { useEffect, useState } from "react";
import FormField from "layouts/pages/users/new-user/components/FormField";

const CustomizedDimension = (props) => {
  const { formField, values, errors, touched, setFieldValue } = props.formData;
  const {
    flatRate,
    packageWeight,
    unit,
    customLength,
    customWidth,
    customHeight,
    softPackLength,
    softPackWidth,
    softPackHeight,
  } = formField;
  const {
    flatRate: flatRateV,
    packageWeight: packageWeightV,
    unit: unitV,
    customLength: customLengthV,
    customWidth: customWidthV,
    customHeight: customHeightV,
    softPackLength: softPackLengthV,
    softPackWidth: softPackWidthV,
    softPackHeigh: softPackHeightV,
  } = values;
  const { PackageType, packageData, setActPackageData, senderInfo, recipientInfo } = props;
  const [Container, setContainer] = useState("");
  const [Weight, setWeight] = useState(0);
  const [Width, setWidth] = useState(0);
  const [Length, setLength] = useState(0);
  const [Height, setHeight] = useState(0);
  const [Unit, setUnit] = useState("");

  useEffect(() => {
    var data = packageData ? packageData : {};
    if (PackageType !== "USPS FLAT RATE BOX") {
      data["Container"] = "";
    } else {
      data["Width"] = 0;
      data["Length"] = 0;
      data["Height"] = 0;
    }
    data["Unit"] = "OZ";
    setActPackageData(data);
  }, []);

  useEffect(() => {
    var data = packageData ? packageData : {};
    if (PackageType === "USPS FLAT RATE BOX") {
      data["PriceType"] = "commercial";
      data["OriginZip"] = senderInfo?.Zip;
      data["DestinationZip"] = recipientInfo?.Zip;
      setActPackageData(data);
      setContainer(packageData?.Container);
      setWeight(packageData?.Weight);
      setUnit(packageData?.Unit);
    } else {
      data["PriceType"] = "commercial";
      data["OriginZip"] = senderInfo?.Zip;
      data["DestinationZip"] = recipientInfo?.Zip;
      setWidth(packageData?.Width);
      setLength(packageData?.Length);
      setHeight(packageData?.Height);
      setWeight(packageData?.Weight);
      setUnit(packageData?.Unit);
    }
    if (PackageType !== "USPS FLAT RATE BOX") {
      data["Container"] = "";
    } else {
      data["Width"] = 0;
      data["Length"] = 0;
      data["Height"] = 0;
    }
    setActPackageData(data);
  }, [packageData, PackageType]);

  useEffect(() => {
    var data = packageData ? packageData : {};
    if (PackageType !== "USPS FLAT RATE BOX") {
      data["Container"] = "";
    } else {
      data["Width"] = 0;
      data["Length"] = 0;
      data["Height"] = 0;
    }
    setActPackageData(data);
  }, [Container, Weight, Width, Length, Height, Unit]);

  const flateRateData = [
    {
      value: "FLAT RATE ENVELOPE",
      label: "Flat Rate Envelope (12.5 x 9.5 in)",
    },
    {
      value: "PADDED FLAT RATE ENVELOPE",
      label: "Flat Rate Padded Envelope (12.5 x 9.5 in)",
    },
    {
      value: "SM FLAT RATE BOX",
      label: "Small Flat Rate Box (8.69 x 5.44 x 1.75 in)",
    },
    {
      value: "MD FLAT RATE BOX",
      label: "Medium Flat Rate Box (11.25 x 8.75 in)",
    },
    {
      value: "LG FLAT RATE BOX",
      label: "Large Flat Rate Box (12.25 x 12.25 x 6 in)",
    },
  ];

  const unitData = [
    { value: "OZ", label: "OZ" },
    { value: "lb", label: "LB" },
  ];

  const HandleUnitChange = (e) => {
    var data = packageData ? packageData : {};
    data["Unit"] = e.value;
    data["PriceType"] = "commercial";
    setActPackageData(data);
    setUnit(e.value);
  };
  const HandleContainerChange = (e) => {
    var data = packageData ? packageData : {};
    data["Container"] = e.value;
    setActPackageData(data);
    setContainer(e.value);
  };
  const HandleWeightChange = (e) => {
    var data = packageData ? packageData : {};
    data["Weight"] = e.target.value;
    setActPackageData(data);
    setWeight(e.target.value);
  };
  const HandleWidthChange = (e) => {
    var data = packageData ? packageData : {};
    data["Width"] = e.target.value;
    setActPackageData(data);
    setWidth(e.target.value);
  };
  const HandleLengthChange = (e) => {
    var data = packageData ? packageData : {};
    data["Length"] = e.target.value;
    setActPackageData(data);
    setLength(e.target.value);
  };
  const HandleHeightChange = (e) => {
    var data = packageData ? packageData : {};
    data["Height"] = e.target.value;
    setActPackageData(data);
    setHeight(e.target.value);
  };
  return (
    <VuiBox mt={5} pb={5} mb={8} shadow="lg" bgColor="white" borderRadius="lg">
      {PackageType === "USPS FLAT RATE BOX" ? (
        <>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={7.5}>
              <VuiTypography variant="lg" color="white">
                USPS flat rate box
              </VuiTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={7.5}>
              <VuiBox mb={1.5}>
                <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <VuiTypography
                    component="label"
                    variant="caption"
                    color="white"
                    fontWeight="bold"
                    textTransform="capitalize"
                    style={{ color: "black" }}
                  >
                    {flatRate.label}
                  </VuiTypography>
                </VuiBox>
                <Field name={flatRate.name}>
                  {({ field, form }) => (
                    <VuiSelect
                      menuPlacement="top"
                      type="text"
                      name={flatRate.name}
                      value={flateRateData.find((obj) => obj.value === flatRateV)}
                      label={flatRate.label}
                      options={flateRateData}
                      onChange={(option) => {
                        form.setFieldValue("flatRate", option.value);
                      }}
                      placeholder={flatRate.placeholder}
                      success={flatRateV.length > 0 && !errors.flatRate}
                    />
                  )}
                </Field>
                <VuiBox mt={0.75}>
                  <VuiTypography component="div" variant="caption" color="error">
                    {errors.flatRate && <div className="input-feedback">{errors.flatRate}</div>}
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={7.5}>
              <VuiTypography variant="lg" color="white">
                Customized Package Dimension
              </VuiTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={2.5}>
              <FormField
                label={customLength.label}
                name={customLength.name}
                type={customLength.type}
                value={customLengthV}
                placeholder={customLength.placeholder}
                error={errors.customLength && touched.customLength}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} md={2.5}>
              <FormField
                label={customWidth.label}
                name={customWidth.name}
                type={customWidth.type}
                value={customWidthV}
                placeholder={customWidth.placeholder}
                error={errors.customWidth && touched.customWidth}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} md={2.5}>
              <FormField
                label={customHeight.label}
                name={customHeight.name}
                type={customHeight.type}
                value={customHeightV}
                placeholder={customHeight.placeholder}
                error={errors.customHeight && touched.customHeight}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={2.5}>
          <FormField
            label={packageWeight.label}
            name={packageWeight.name}
            type={packageWeight.type}
            value={packageWeightV}
            placeholder={packageWeight.placeholder}
            error={errors.packageWeight && touched.packageWeight}
            inputProps={{ autoComplete: "" }}
          />
        </Grid>
        <Grid item xs={12} md={2.5}>
          <VuiBox mb={1.5}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
                component="label"
                variant="caption"
                color="white"
                fontWeight="bold"
                textTransform="capitalize"
                style={{ color: "black" }}
              >
                {unit.label}
              </VuiTypography>
            </VuiBox>
            <Field name={unit.name}>
              {({ field, form }) => (
                <VuiSelect
                  menuPlacement="top"
                  type="text"
                  name={unit.name}
                  value={unitData.find((obj) => obj.value === unitV)}
                  label={unit.label}
                  options={unitData}
                  onChange={(option) => {
                    form.setFieldValue("unit", option.value);
                  }}
                  placeholder={unit.placeholder}
                  success={unitV.length > 0 && !errors.unit}
                />
              )}
            </Field>
            <VuiBox mt={0.75}>
              <VuiTypography component="div" variant="caption" color="error">
                {errors.unit && <div className="input-feedback">{errors.unit}</div>}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </Grid>
      </Grid>
    </VuiBox>
  );
};
export default CustomizedDimension;
