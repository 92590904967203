import { SET_VERIFY_RESPONSE } from "../Type";
const initialState = {
  verify_response: null,
};

const VerifyStore = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_VERIFY_RESPONSE:
      return {
        ...state,
        verify_response: data,
      };
    default:
      return state;
  }
};

export default VerifyStore;
