import React, { useEffect, useState } from "react";
import Spin from "components/Spin";



import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Row from './row';

function TransactionHistory(props) {
    const [transactionHostory, setTransactionHostory] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getTransactionsHistory();
    }, []);


    const getTransactionsHistory = () => {
        setLoading(true);
        let url = `${process.env.REACT_APP_API_BASE_URL}/accounts/transactions-history/${localStorage.ShipperHash}`;

        var headers = {
            Authorization: `Bearer ${localStorage.id_token}`,
            ShipperHash: localStorage.ShipperHash,
            "x-api-key": "6f40874a-9568-457f-8543-901fd614741e",
        };

        axios
            .get(url, {
                headers: headers,
            })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res);
                    setTransactionHostory(res.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onRowClick = (row, event) => {

    };

    return (
        <Spin tip="Loading..." spinning={loading}>
            <Paper>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Transaction History

                                <IconButton color="primary" aria-label="refresh page" component="label" onClick={getTransactionsHistory}>
                                    <RefreshIcon />
                                </IconButton>
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </Box>
                <TableContainer component={"div"} sx={{ maxHeight: 440 }}>
                    <Table aria-label="collapsible table" stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Transaction Date</TableCell>
                                <TableCell>Job/Document Hash</TableCell>
                                <TableCell>Transaction Type</TableCell>
                                <TableCell>Desciption</TableCell>
                                <TableCell align="right">Amount Charged</TableCell>
                                <TableCell align="right">Amound Refunded</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactionHostory?.map((row, index) => (
                                <Row key={index} row={row} onRowClick={onRowClick} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <Pagination
                    count={TotalNoOfPage}
                    defaultPage={1}
                    page={CurrentPage}
                    size="large"
                    onChange={(event, current) => {
                        SetCurrentPage(current);
                    }}
                    showFirstButton
                    showLastButton
                /> */}
            </Paper>
        </Spin>
    );
}

export default TransactionHistory;