import React, { useState } from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';;
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


function Row(props) {
    const { row } = props;

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>{row.PersonName}</TableCell>
                <TableCell>{row.CompanyName}</TableCell>
                <TableCell>{row.AddressLine1}</TableCell>
                <TableCell>{row.AddressLine2}</TableCell>
                <TableCell>{row.City}</TableCell>
                <TableCell>{row.Zip}</TableCell>
                <TableCell>
                    <div title={row?.NftWalletAddress} style={{ maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row?.NftWalletAddress}
                    </div>
                </TableCell>
                <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                        <IconButton onClick={() => {
                            props?.onRowClick(row, 'edit')
                        }}>
                            <EditIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => {
                            props?.onRowClick(row, 'delete')
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </ButtonGroup>
                </TableCell>
            </TableRow>

        </React.Fragment>
    )
}

export default Row;