

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
// import Link from "@mui/material/Link";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";



import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// DPS UI  React base styles
import typography from "assets/theme/base/typography";

function Footer({ links }) {
  const { size } = typography;
  const renderLinks = () =>
    links.map((link) => (
      <VuiBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href}>
          <VuiTypography variant="button" fontWeight="regular" color="dark">
            {link.name} TEST
          </VuiTypography>
        </Link>
      </VuiBox>
    ));

  return (
    <VuiBox
      width="80%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      style={{
        position: 'fixed', left: window.location.href.includes('onboarding') ? '0rem' : '17.125rem', bottom: '0', right: '0', width: window.location.href.includes('onboarding') ? 'calc(100% - 0rem)' : 'calc(100% - 17.125rem)',
        padding: '20px', background: '#f8f9fa'
      }}
    >
      <VuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="dark"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()} DPS. All Rights Reserved.

      </VuiBox>
      <VuiBox
        component="ul"
        style={{ marginRight: 80 }}
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          color: "dark",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {/* {renderLinks()} */}

        <VuiBox component="li" px={2} lineHeight={1}>
          <Link to={'/privacy'}>
            <VuiTypography variant="button" fontWeight="regular" color="dark">
              Privacy Policy
            </VuiTypography>
          </Link>
        </VuiBox>

        <VuiBox component="li" px={2} lineHeight={1}>
          <Link to={'/terms'}>
            <VuiTypography variant="button" fontWeight="regular" color="dark">
              Terms & Conditions
            </VuiTypography>
          </Link>
        </VuiBox>
      </VuiBox>
    </VuiBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  links: [
    { href: "#", name: "Privacy Policy" },
    { href: "#", name: "Terms & Conditions" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
