

// @mui material components
import Footer from "examples/Footer";
import { connect } from "react-redux";
import React from 'react';


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Route, Redirect, useHistory } from "react-router-dom";
import Spin from "components/Spin";

function PrivateRoute(props) {
  const { component: ComponentInRoute, ...rest } = props;

  React.useEffect(() => {
    const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
      externalScript.id = "external";
      externalScript.async = true;
      externalScript.nonce = "FOQjQGXH2RAbKW1iFgTAJcKybXxNLRvaIHkNo7NXDJRaOd7eF9";
      externalScript.type = "text/javascript";
      // externalScript.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(externalScript);
      externalScript.src = `https://desk.zoho.com/portal/api/web/inapp/775754000000231039?orgId=788846004`;

    };
    LoadExternalScript();
  }, []);


  return (
    <>
      <Route {...rest}>
        <DashboardLayout>
          <DashboardNavbar Profile={props?.Profile} />
          <Spin
            tip="Loading..."
            spinning={
              localStorage.id_token !== null && localStorage.id_token !== undefined ? false : true
            }
          >
            <ComponentInRoute />
          </Spin>
          <Footer />
          {/* <h1>Hello</h1>
          <script type="text/javascript" nonce="FOQjQGXH2RAbKW1iFgTAJcKybXxNLRvaIHkNo7NXDJRaOd7eF9" src="https://desk.zoho.com/portal/api/web/inapp/775754000000231039?orgId=788846004" defer></script> */}
        </DashboardLayout>
      </Route>
      {
        localStorage.id_token === undefined ? <Redirect from="*" to="/sign-in" /> : null
      }
    </>
  );
}

export default connect(
  (state) => ({
    Profile: state.CommonStore.Profile
  })
)(PrivateRoute);

// export default PrivateRoute;
