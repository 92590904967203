import axios from "axios";
const querystring = require("querystring");

export const GetDocumentApi = (data) => {
  let ShipperHash = localStorage.ShipperHash;

  const url = `${process.env.REACT_APP_API_BASE_URL}/documents/notaries/${ShipperHash}`; //${ShipperHash}

  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .get(url, { params: data, headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const GetNFTApi = (params) => {
  let ShipperHash = localStorage.ShipperHash;
  var qs = querystring.stringify(params);
  var url = `${process.env.REACT_APP_API_BASE_URL}/nfts/${ShipperHash}`;
  if (qs) {
    url = `${process.env.REACT_APP_API_BASE_URL}/nfts/${ShipperHash}?${qs}`;
  }
  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .get(url, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
};
