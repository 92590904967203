import { useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiSelect from "components/VuiSelect";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";
import dps_usps_logo from "../../assets/images/dps_usps_logo.png";
import CircularProgress from "@mui/material/CircularProgress";
import VuiAvatar from "components/VuiAvatar";
import VuiBadge from "components/VuiBadge";

function CostEstimate() {

    const [rates, setRates] = useState([]);
    const [printOption, setPrintOption] = useState('PrintOneSide');
    const [colorType, setColorType] = useState('BW');
    const [pageCount, setPageCount] = useState(1);
    const [priceType, setPriceType] = useState('r');

    const [firstClass, setFirstClass] = useState(60);
    const [certifiedMail, setCertifiedMail] = useState(30);
    const [priorityMailExpress, setPriorityMailExpress] = useState(30);
    const [priorityMail, setPriorityMail] = useState(30);
    const [certifiedMailWithSignature, setCertifiedMailWithSignature] = useState(30);
    const [returnEnvelope, setReturnEnvelope] = useState('No');


    const [loading, setLoading] = useState(false);


    const [pageCountError, setPageCountError] = useState('');

    const getEstimate = () => {
        if (pageCount <= 0 || pageCount == '' || pageCount == null || pageCount == undefined) {
            setPageCountError('Page count should be greater than 0');
            return;
        }
        let headers = {
            "Content-Type": "application/json",
        };
        let url = `${process.env.REACT_APP_API_BASE_URL}/postage/cost-estimate`;

        setRates([]);
        setLoading(true);

        const payload = {
            "PrintOption": printOption,
            "Color": colorType == 'BW' ? "False" : "True",
            "PageCount": pageCount,
            "PriceType": priceType == "s" ? "c" : priceType,
            "ReturnEnvelope": returnEnvelope == 'No' ? "False" : "True",
        };

        let percentage = {
            "FC": firstClass,
            "ERR": certifiedMail,
            "ERRSig": certifiedMailWithSignature,
            "PMAIL": priorityMail,
            "PRIL": priorityMailExpress
        }

        axios
            .get(url, {
                params: payload,
                headers: headers,
            })
            .then((response) => {
                let data = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (priceType == 's') {
                        response.data[i].Total = response.data[i].Total + ((response.data[i].Total * percentage[response.data[i].ServiceType]) / 100);
                        response.data[i].Total = response.data[i].Total.toFixed(2);
                    }
                    data.push(response.data[i]);
                }
                setRates(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <VuiBox pt={3} pb={10} style={{ width: "70%", margin: "0px auto" }}>
            <VuiBox pt={3} pb={10} style={{ width: "65%", margin: "0px auto" }}>
                <Grid container justifyContent="space-between" rowSpacing={1} spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                        <VuiTypography fontSize="small">Print Option</VuiTypography>
                        <VuiSelect
                            defaultValue={{ value: "PrintOneSide", label: "One Sided" }}
                            options={[
                                { value: "PrintOneSide", label: "One Sided" },
                                { value: "PrintTwoSide", label: "Two Sided" }
                            ]}
                            size="small"
                            onChange={(e) => {
                                setPrintOption(e.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <VuiTypography fontSize="small">Color Type</VuiTypography>
                        <VuiSelect
                            defaultValue={{ value: 'BW', label: "Black & White" }}
                            options={[
                                { value: 'BW', label: "Black & White" },
                                { value: 'C', label: "Color" }
                            ]}
                            size="small"
                            onChange={(e) => {
                                setColorType(e.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <VuiTypography fontSize="small">Price Type</VuiTypography>
                        <VuiSelect
                            defaultValue={{ value: "r", label: "Retail" }}
                            options={[
                                { value: 'r', label: "Retail Price" },
                                { value: 's', label: "Selling Price" },
                                { value: 'c', label: "Cost Price" }
                            ]}
                            size="small"
                            onChange={(e) => {
                                setPriceType(e.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <VuiTypography fontSize="small">Page Count</VuiTypography>
                        <VuiInput
                            pnaceholder="Length"
                            type="number"
                            step={1}
                            min={1}
                            max={5000}
                            value={pageCount}
                            onChange={(e) => {
                                setPageCountError('');
                                setPageCount(e.target.value);
                            }}
                        />
                        <span style={{ color: 'red' }}>{pageCountError}</span>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <VuiTypography fontSize="small">Return Envelope</VuiTypography>
                        <VuiSelect
                            defaultValue={{ value: "No", label: "No" }}
                            options={[
                                { value: 'Yes', label: "Yes" },
                                { value: 'No', label: "No" }
                            ]}
                            size="small"
                            onChange={(e) => {
                                setReturnEnvelope(e.value);
                            }}
                        />
                    </Grid>

                    {priceType == 's' ? <Grid item xs={12} md={4} lg={4}>
                        <VuiTypography fontSize="small">First Classs (%)</VuiTypography>
                        <VuiInput
                            pnaceholder="Length"
                            type="number"
                            step={1}
                            min={1}
                            max={100}
                            value={firstClass}
                            onChange={(e) => {
                                setFirstClass(e.target.value);
                            }}
                        />
                    </Grid> : null}

                    {priceType == 's' ? <Grid item xs={12} md={4} lg={4}>
                        <VuiTypography fontSize="small">Certified Mail (%)</VuiTypography>
                        <VuiInput
                            pnaceholder="Length"
                            type="number"
                            step={1}
                            min={1}
                            max={100}
                            value={certifiedMail}
                            onChange={(e) => {
                                setCertifiedMail(e.target.value);
                            }}
                        />
                    </Grid> : null}

                    {priceType == 's' ? <Grid item xs={12} md={4} lg={4}>
                        <VuiTypography fontSize="small">Priority Mail (%)</VuiTypography>
                        <VuiInput
                            pnaceholder="Length"
                            type="number"
                            step={1}
                            min={1}
                            max={100}
                            value={priorityMail}
                            onChange={(e) => {
                                setPriorityMail(e.target.value);
                            }}
                        />
                    </Grid> : null}

                    {priceType == 's' ? <Grid item xs={12} md={6} lg={6}>
                        <VuiTypography fontSize="small">Certified Mail W/Recipient Signature (%)</VuiTypography>
                        <VuiInput
                            pnaceholder="Length"
                            type="number"
                            step={1}
                            min={1}
                            value={certifiedMailWithSignature}
                            max={100}
                            onChange={(e) => {
                                setCertifiedMailWithSignature(e.target.value);
                            }}
                        />
                    </Grid> : null}

                    {priceType == 's' ? <Grid item xs={12} md={6} lg={6}>
                        <VuiTypography fontSize="small">Priority Mail Express (%)</VuiTypography>
                        <VuiInput
                            pnaceholder="Length"
                            type="number"
                            step={1}
                            min={1}
                            max={100}
                            value={priorityMailExpress}
                            onChange={(e) => {
                                setPriorityMailExpress(e.target.value);
                            }}
                        />
                    </Grid> : null}



                    <Grid item xs={12} md={12} lg={12}>
                        <VuiButton
                            disabled={loading}
                            color="primary"
                            variant={"gradient"}
                            onClick={getEstimate}
                            style={{ float: "right" }}
                        >
                            {loading ? <CircularProgress color="error" size={16} /> : null}
                            &nbsp;&nbsp;Calculate Cost
                        </VuiButton>
                    </Grid>
                </Grid>
            </VuiBox>
            <Grid container justifyContent="center" rowSpacing={4} spacing={4} direction={"column"}>
                <VuiBox pt={3} pb={10} style={{ width: "75%", margin: "0px auto" }}>
                    {_.map(rates || [], (rate, index) => (
                        <Card style={{ marginBottom: 20 }}>
                            <VuiBox display="flex" justifyContent="space-between" alignItems="flex-start">
                                <VuiBox display="flex" mr={2} mb="22px">
                                    <VuiAvatar size="md" variant="rounded" style={{ backgroundColor: '#dee7f1' }} src={dps_usps_logo} />

                                    <VuiBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                        <VuiTypography
                                            variant="button"
                                            fontWeight="medium"
                                            color="black"
                                            textTransform="capitalize"
                                        >
                                            {rate?.DisplayName}
                                        </VuiTypography>
                                        {/* <VuiTypography variant="button" color="text" fontWeight="regular">
                                            For 1 Mailings
                                        </VuiTypography> */}
                                    </VuiBox>
                                </VuiBox>
                                <VuiBadge color={"info"} badgeContent={"$" + rate?.Total} size="sm" container />
                            </VuiBox>
                            <VuiBox mt="4px" mb={2} lineHeight={0}>
                                <VuiTypography variant="button" fontWeight="regular" color="text">
                                    {rate?.Description}
                                </VuiTypography>
                            </VuiBox>
                            {/* <VuiBox
                        sx={{ background: linearGradient(cardContent.main, cardContent.state, cardContent.deg) }}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderRadius="lg"
                        p={2}>
                        <VuiTypography fontSize={22} color="white" fontWeight="bold">
                            $10.34
                        </VuiTypography>
                    </VuiBox> */}
                        </Card>
                    ))}


                </VuiBox>
            </Grid>
        </VuiBox >
    )
}

export default CostEstimate;