import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { IoBuild } from "react-icons/io5";
import { IoCube } from "react-icons/io5";
import { RiPencilFill } from "react-icons/ri";
import NftIcon from "assets/images/icons/nft-icon.png";
import DocumentIcon from "assets/images/icons/document-icon.png";
import PackageIcon from "assets/images/icons/package-icon.png";
import { useHistory } from "react-router-dom";

function Send() {
  const [selectedsWizard, setWizardSelection] = useState("");
  const history = useHistory();

  const handleWizardSelect = (value) => {
    if (value === selectedsWizard) {
      setWizardSelection("");
    } else {
      setWizardSelection(value);
    }
  };

  const customButtonStyles = ({
    functions: { pxToRem },
    borders: { borderWidth },
    palette: { transparent, info },
  }) => ({
    width: pxToRem(200),
    height: pxToRem(200),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      border: `${borderWidth[2]} solid ${info.main} !important`,
    },
  });

  return (
    <VuiBox style={{ width: "100%", flexDirection: 'column', height: "calc(80vh - 60px)", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <VuiBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <VuiBox mb={1}>
              <VuiTypography variant="lg" fontWeight="bold" color="dark" textAlign="center">
                SELECT SEND TYPE
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <VuiBox textAlign="center">
              <VuiButton
                color="info"
                variant={"outlined"}
                onClick={() => {
                  // history.push(`/send/document`);
                  history.push('/documents/e-notarize?k=w');
                }}
                sx={customButtonStyles}
              >
                <img src={DocumentIcon} style={{ width: 70, height: 70 }} alt="document icon" />
              </VuiButton>
              <VuiTypography variant="h6" color="dark">
                DOCUMENT
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <VuiBox textAlign="center">
              <VuiButton
                color="info"
                variant={"outlined"}
                onClick={() => {
                  history.push(`/applications/package/type`);
                }}
                sx={customButtonStyles}
              >
                <img src={PackageIcon} style={{ width: 70, height: 70 }} alt="package icon" />
              </VuiButton>
              <VuiTypography variant="h6" color="dark">
                PACKAGE
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <VuiBox textAlign="center">
              <VuiButton
                color="info"
                variant={"outlined"}
                onClick={() => {
                  history.push(`/nft/create`);
                }}
                sx={customButtonStyles}
              >
                <img src={NftIcon} style={{ width: 70, height: 70 }} alt="nft icon" />
              </VuiButton>
              <VuiTypography variant="h6" color="dark">
                NFT
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  );
}

export default Send;
