import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import Card from "@mui/material/Card";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { GoLock } from "react-icons/go";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ShowPdfPopup from "./PopUp/ShowPdfPopup";

function DocumentDetail(props) {
  const { values } = props.formData;
  const [mediaUrl, setMediaUrl] = useState(null);
  const [contentType, setContentType] = useState(null);
  const history = useHistory();
  const [downloading, setDownloading] = useState(false);
  const [PageCount, setPageCount] = useState(0);
  const [pageDetails, setPageDetails] = useState([]);
  const [termsAndCondition, setTermsAndCondition] = useState(false);

  const getNotraizedDocumentUrl = () => {
    var data = JSON.stringify({
      Key: props.documentKey,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/downloads`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.id_token}`,
        ShipperHash: localStorage.ShipperHash,
        "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
      },
      data: data,
    };
    setDownloading(true);

    axios(config)
      .then(function (response) {
        if (response.status === 200 && response.data.Url != null && response.data.Url != "") {
          setMediaUrl(response.data.Url);
          setContentType(response.data.ContentType);
          setDownloading(false);
          setPageCount(response.data.PageCount);
          setPageDetails(response.data.PageDetails);
          props.result(response.data.PageDetails[0].Print2MailAcceptable);
        } else {
          setTimeout(() => {
            getNotraizedDocumentUrl();
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
        setTimeout(() => {
          getNotraizedDocumentUrl();
        }, 1000);
      });
  };

  useEffect(() => {
    if (props.documentKey != null && props.documentKey != "") {
      getNotraizedDocumentUrl();
    }
  }, [props.notraized]);

  const handleTermsAndConditionChange = (e) => {
    setTermsAndCondition(e.target.checked);
    props?.termsAccepted(e.target.checked);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <VuiTypography variant="h3" color="dark" textAlign="center" fontWeight="bold">
          {props.notraized == true ? 'TIMESTAMPING COMPLETE' : 'DOCUMENT INFORMATION'}
        </VuiTypography>
      </Grid>
      <Grid item xs={12} md={4} textAlign="center">
        <Card sx={{ overflow: "visible", height: "100%" }} style={{ border: props.notraized ? "5px solid rgb(0 128 0 / 62%)" : "none" }}>

          <div
            style={{
              height: '100%',
              width: '100%',
              overflow: "hidden",
              margin: "0 auto",
              borderRadius: "6px",
              position: "relative",
            }}
          >
            {downloading && props.notraized ? (
              <div
                className="loader"
                style={{
                  position: "absolute",
                  top: 0,
                  background: "#000",
                  opacity: 0.7,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                >
                  {" "}
                  <CircularProgress />
                  <br />
                  Timestamping Document
                </div>
              </div>
            ) : null}
            <iframe src={mediaUrl} width="100%" height="100%" style={{ overflow: 'auto' }} />
            {/* <iframe src={mediaUrl} height={235} width={"100%"} /> */}
          </div>
          <div style={{ textAlign: "center" }}>
            {/* <a href={mediaUrl} target="_blank" style={{ fontSize: 12 }}>
              View in full screen
            </a> */}
            <ShowPdfPopup url={mediaUrl} />
          </div>
          {
            props.notraized ? <GoLock size="54px" color="rgb(0 128 0 / 62%)" style={{ margin: '0px auto' }} /> : null
          }
        </Card>
      </Grid>
      <Grid item xs={12} md={8}>
        <Card style={{ minHeight: 375 }}>
          <VuiBox textAlign="center">
            <Grid container spacing={3} pl={0} pb={10}>
              <Grid item xs={12} md={12}>
                <VuiTypography textAlign="left">NAME: {values?.documentName}</VuiTypography>
                <VuiTypography textAlign="left">
                  {
                    props.notraized == true ? 'DOCUMENT NOTARY ID' : 'DOCUMENT ID'
                  }
                  : {props?.documentHash}</VuiTypography>
                <VuiTypography textAlign="left">
                  DESCRIPTION: {values?.documentDescription}
                </VuiTypography>
                <VuiTypography textAlign="left">Category: {values?.documentCategory}</VuiTypography>
                <VuiTypography textAlign="left">TAGS: {values?.documentTag}</VuiTypography>
                {_.map(pageDetails, (item, i) => (
                  <>
                    <VuiTypography
                      textAlign="left"
                      color={item.Print2MailAcceptable ? "dark" : "error"}
                    >
                      <span style={{ fontWeight: 500 }}> PAGES:</span>{" "}
                      {`${item.Pages} (${item.Dimension})`}
                      <br />
                      <small>
                        <i>
                          {item.Print2MailAcceptable
                            ? "Acceptable for Print2Mail"
                            : "Not Acceptable for Print2Mail"}
                        </i>
                      </small>
                    </VuiTypography>
                  </>
                ))}

                {/* <br />
                <VuiTypography textAlign="left">Notraization Price: $0.1</VuiTypography> */}

                {
                  props?.notraized == false ? (
                    <div>
                      <VuiTypography textAlign="left" style={{ marginLeft: 10 }}>
                        <FormControlLabel style={{ display: 'flex' }}
                          control={<Checkbox onChange={handleTermsAndConditionChange} />}
                          label="I approve that I want this document to be timestamped and stored on DPS blockchain"
                        />
                      </VuiTypography>
                      <VuiBox mt={0.75}>
                        <VuiTypography component="div" variant="caption" color="error" textAlign="left">
                          {termsAndCondition == false && (
                            <div className="input-feedback">Please approve above statement.</div>
                          )}
                        </VuiTypography>
                      </VuiBox>
                    </div>
                  ) : null
                }

              </Grid>
            </Grid>
          </VuiBox>
        </Card>
      </Grid>
    </Grid>
  );
}

export default DocumentDetail;
