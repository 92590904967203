

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// DPS UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";

// DPS UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function SignUpForm(props) {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);

    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [phoneError, setPhoneError] = useState('');


    const togglePassword = () => {
        setPasswordVisible(prevCheck => !prevCheck);
    }

    const validateEmail = () => {
        let flag = false;
        setUsernameError('');
        if (email == '' || email == null || email == undefined) {
            setUsernameError('This field is required!');
            flag = true;
        }
        let regEmail = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;

        if (!regEmail.test(email)) {
            setUsernameError('Enter valid email!');
            flag = true;
        }
        return flag;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (validateEmail()) {
            return;
        }
        setPasswordError('');
        setPhoneError('');

        if (password == '' || password == null || password == undefined) {
            setPasswordError('This field is required!');
            return;
        }
        if (password.length < 8) {
            setPasswordError('Minimum 8 character is required!');
            return;
        }
        if (phone == '' || phone == null || phone == undefined) {
            setPhoneError('This field is required!');
            return;
        }
        if (phone.length != 10) {
            setPhoneError('Please enter a valid phone number!');
            return;
        }
        let regPhone = /^[0-9]/;
        if (!regPhone.test(phone)) {
            setPhoneError('Please enter a valid phone number!');
            return;
        }

        props.onSubmit(email, "+1" + phone, password);
    }

    const handlePhoneChange = (e) => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            setPhone(e.target.value);
        }
        if (e.target.value == '') {
            setPhone('');
        }
    }

    return (
        <form onSubmit={onSubmit} autoComplete="off" noValidate={true}>
            <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
                <VuiBox
                    component="div"
                    borderRadius="inherit"
                    p="45px"
                    style={{ minWidth: 380 }}
                    sx={({ palette: { secondary } }) => ({
                        backgroundColor: secondary.focus,
                    })}
                >
                    <VuiTypography
                        color="white"
                        fontWeight="bold"
                        textAlign="center"
                        mb="24px"
                        sx={({ typography: { size } }) => ({
                            fontSize: size.lg,
                        })}
                    >
                        Register with
                    </VuiTypography>

                    <VuiBox mb={2}>
                        <VuiBox mb={1} ml={0.5}>
                            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                                Email (<span style={{ fontSize: 11 }}>This will be your user name</span>)
                            </VuiTypography>
                        </VuiBox>
                        <GradientBorder
                            minWidth="100%"
                            borderRadius={borders.borderRadius.lg}
                            padding="1px"
                            backgroundImage={radialGradient(
                                palette.gradients.borderLight.main,
                                palette.gradients.borderLight.state,
                                palette.gradients.borderLight.angle
                            )}
                        >
                            <VuiInput
                                type="email"
                                name="email"
                                placeholder="Your email..."
                                autoComplete="off"
                                sx={({ typography: { size } }) => ({
                                    fontSize: size.sm,
                                })}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </GradientBorder>
                        <span style={{ display: 'block', color: 'red', fontSize: 11, fontWeight: 'bold', paddingLeft: 5 }}>{usernameError}</span>
                    </VuiBox>

                    <VuiBox mb={2}>
                        <VuiBox mb={1} ml={0.5}>
                            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                                Phone (<span style={{ fontSize: 11 }}>Enter valid USA phone number</span>)
                            </VuiTypography>
                        </VuiBox>
                        <GradientBorder
                            minWidth="100%"
                            borderRadius={borders.borderRadius.lg}
                            padding="1px"
                            backgroundImage={radialGradient(
                                palette.gradients.borderLight.main,
                                palette.gradients.borderLight.state,
                                palette.gradients.borderLight.angle
                            )}
                        >
                            <VuiInput
                                type={"text"}
                                name="phone_number"
                                placeholder="Your phone number..."
                                autoComplete="off"
                                sx={({ typography: { size } }) => ({
                                    fontSize: size.sm,
                                })}
                                value={phone}
                                onChange={handlePhoneChange}
                            // onChange={(e) => setPhone(e.target.value)}
                            />
                        </GradientBorder>
                        <span style={{ display: 'block', color: 'red', fontSize: 11, fontWeight: 'bold', paddingLeft: 5 }}>{phoneError}</span>
                    </VuiBox>

                    <VuiBox mb={2} style={{ position: 'relative' }}>
                        <VuiBox mb={1} ml={0.5}>
                            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                                Password
                            </VuiTypography>
                        </VuiBox>
                        <GradientBorder
                            minWidth="100%"
                            borderRadius={borders.borderRadius.lg}
                            padding="1px"
                            backgroundImage={radialGradient(
                                palette.gradients.borderLight.main,
                                palette.gradients.borderLight.state,
                                palette.gradients.borderLight.angle
                            )}
                        >
                            <VuiInput
                                type={passwordVisible ? "text" : "password"}
                                name="password"
                                style={{ paddingRight: 40 }}
                                placeholder="Your password..."
                                autoComplete="new-password"
                                sx={({ typography: { size } }) => ({
                                    fontSize: size.sm,
                                })}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </GradientBorder>
                        <span style={{ position: 'absolute', top: 38, right: 10, cursor: 'pointer' }} onClick={togglePassword}>
                            {
                                passwordVisible ? <VisibilityOffIcon fontSize={'medium'} /> : <VisibilityIcon fontSize={'medium'} />
                            }
                        </span>
                        <span style={{ display: 'block', color: 'red', fontSize: 11, fontWeight: 'bold', paddingLeft: 5 }}>{passwordError}</span>
                    </VuiBox>

                    <VuiBox mb={2}>
                        <span style={{ display: 'block', color: 'red', fontSize: 11, fontWeight: 'bold', paddingLeft: 5 }}>{props?.cognitoError}</span>
                    </VuiBox>

                    <VuiBox mt={4} mb={1}>
                        <VuiButton color="info" fullWidth type="submit" disabled={props?.loading}>
                            SIGN UP {" "} {props?.loading ? <CircularProgress size={16} style={{ marginLeft: 8 }} color="error" /> : ""}
                        </VuiButton>
                    </VuiBox>
                    <VuiBox mt={3} textAlign="center">
                        <VuiTypography variant="button" color="white" fontWeight="regular">
                            Already have an account?{" "}
                            <VuiTypography
                                component={Link}
                                to="/sign-in"
                                variant="button"
                                color="white"
                                fontWeight="medium"
                            >
                                Sign in
                            </VuiTypography>
                        </VuiTypography>
                    </VuiBox>
                </VuiBox>
            </GradientBorder>
        </form>
    )
}

export default SignUpForm;
