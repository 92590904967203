import { GetNFTApi } from "redux/Api/nft";
import { GetDocumentApi } from "redux/Api/nft";
import {
  SET_GET_DOCUMENT,
  SET_GET_DOCUMENT_SUCCESS,
  SET_GET_DOCUMENT_ERROR,
  SET_GET_NFT,
  SET_GET_NFT_SUCCESS,
  SET_GET_NFT_ERROR,
  SET_NFT_RESPONSE,
} from "../Type";
import { setLoaderStatus } from "./common";

export const setDocumentRes = (data) => {
  return {
    type: SET_GET_DOCUMENT,
    data,
  };
};

export const setDocumentResSuccess = (status) => {
  return {
    type: SET_GET_DOCUMENT_SUCCESS,
    status,
  };
};

export const setDocumentResFails = (status) => {
  return {
    type: SET_GET_DOCUMENT_ERROR,
    status,
  };
};

export const GetDocument = (data) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return GetDocumentApi(data).then((res) => {
    if (res.status === 200) {
      dispatch(setLoaderStatus(false));
      dispatch(setDocumentRes(res.data));
      dispatch(setDocumentResSuccess(true));
    } else {
      dispatch(setLoaderStatus(false));
      dispatch(setDocumentResFails(true));
    }
  });
};

export const setNFTRes = (data) => {
  return {
    type: SET_GET_NFT,
    data,
  };
};

export const setNFTResSuccess = (status) => {
  return {
    type: SET_GET_NFT_SUCCESS,
    status,
  };
};

export const setNFTResFails = (status) => {
  return {
    type: SET_GET_NFT_ERROR,
    status,
  };
};

export const GetNFT = (params) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return GetNFTApi(params).then((res) => {
    if (res.status === 200) {
      dispatch(setLoaderStatus(false));
      dispatch(setNFTRes(res.data));
      dispatch(setNFTResSuccess(true));
    } else {
      dispatch(setNFTRes([]));
      dispatch(setLoaderStatus(false));
      dispatch(setNFTResFails(true));
    }
  });
};

export const setNftRes = (data) => {
  return {
    type: SET_NFT_RESPONSE,
    data,
  };
};
