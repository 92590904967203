import {
  POST_ADDRESS_VERIFY_RES,
  POST_ADDRESS_VERIFY_SUCCESS,
  POST_PACKAGE_PRICINGS_RES,
  POST_PACKAGE_PRICINGS_SUCCESS,
  SET_ACTIVE_STEP,
  SET_ADDRESS_BOOK_DATA,
  SET_CERTIFICATE_OF_DELIVERY,
  SET_CERTIFICATE_OF_MAILING,
  SET_IS_ADDRESS_VERIY,
  SET_PACKAGE_DATA,
  SET_PACKAGE_TYPE,
  SET_RECIPIENT_INFO,
  SET_RECORD_MANAGEMENT,
  SET_SELECT_PACKAGE_PRICING,
  SET_SELECT_PACKAGE_PRICING_DATA,
  SET_SENDER_INFO,
  SET_SEARCH_TOPIC_DATA,
  SET_SELECTED_TOPIC_DATA,
  SET_POST_TOPIC_RES,
  SET_POST_TOPIC_SUCCESS,
  SET_PACKAGE_CHECKOUT_SUCCESS,
  SET_PACKAGE_CHECKOUT_RES,
  SET_POST_JOB_RES,
  SET_JOB_POST_SUCCESS,
  SET_RECIPIENT_RES,
  SET_RECIPIENT_SUCCESS,
  SET_CERTIFICATE_AND_STOREAGE_RES,
  SET_CERTIFICATE_AND_STOREAGE_SUCCESS,
  SET_SENDER_INFO_RES,
  SET_SENDER_INFO_SUCCESS,
  SET_PACKAGE_CHECKOUT_ERROR,
  SET_JOB_POST_ERROR,
  SET_RECIPIENT_ERROR,
  SET_POST_TOPIC_ERROR,
  LOAD_SENDER_INFO,
  RESET_WIZARD,
  SET_POST_TOPIC_RES_EMPTY,
  SET_ADDRESS_BOOK_RES,
  SET_ADDRESS_BOOK_SUCCESS,
} from "../Type";
import { PURGE } from "redux-persist";
const initialState = {
  activeStep: 0,
  topicData: [],
  is_address_verify: false,
  sender_info: null,
  address_verify_res: null,
  address_verify_success: false,
  addressBookData: [],
  recipient_info: null,
  packageType: "",
  packageData: {},
  packagePricings: [],
  packagePricingsSuccess: false,
  CertificateOfDelivery: "",
  JobResponse: null,
  JobResponseSuccess: false,
  CertificateAndStoreage: null,
  CertificateAndStoreageSuccess: false,
  ShipperInfo: null,
  ShipperInfoSuccess: false,
  TopicError: false,
  RecipientError: false,
  JobResponseError: false,
  PackageCheckOutError: false,
  loadSenderInfo: false,
  TopicRes: null,
  AddressBook: [],
  AddressBookSuccess: false,
};

const WizardStore = (state = initialState, { type, args, data, status }) => {
  switch (type) {
    case PURGE:
      return initialState;
    case SET_ACTIVE_STEP:
      return { ...state, activeStep: args };
    case SET_SEARCH_TOPIC_DATA:
      return {
        ...state,
        topicData: data,
      };
    case SET_IS_ADDRESS_VERIY:
      return {
        ...state,
        is_address_verify: status,
      };
    case SET_SENDER_INFO:
      return {
        ...state,
        sender_info: data,
      };
    case POST_ADDRESS_VERIFY_RES:
      return {
        ...state,
        address_verify_res: data,
      };
    case LOAD_SENDER_INFO:
      return {
        ...state,
        loadSenderInfo: status,
      };
    case POST_ADDRESS_VERIFY_SUCCESS:
      return {
        ...state,
        address_verify_success: status,
      };
    case SET_ADDRESS_BOOK_DATA:
      return {
        ...state,
        addressBookData: data,
      };
    case SET_RECIPIENT_INFO:
      return {
        ...state,
        recipient_info: data,
      };
    case SET_PACKAGE_TYPE:
      return {
        ...state,
        packageType: data,
      };

    case SET_PACKAGE_DATA:
      return {
        ...state,
        packageData: data,
      };

    case POST_PACKAGE_PRICINGS_RES:
      return {
        ...state,
        packagePricings: data,
      };
    case POST_PACKAGE_PRICINGS_SUCCESS:
      return {
        ...state,
        packagePricingsSuccess: status,
      };
    case SET_CERTIFICATE_OF_DELIVERY:
      return {
        ...state,
        CertificateOfDelivery: data,
      };
    case SET_CERTIFICATE_OF_MAILING:
      return {
        ...state,
        CertificateOfMailing: data,
      };
    case SET_RECORD_MANAGEMENT:
      return {
        ...state,
        RecordManagement: data,
      };
    case SET_SELECT_PACKAGE_PRICING:
      return {
        ...state,
        SelectedPackagePricing: data,
      };
    case SET_SELECT_PACKAGE_PRICING_DATA:
      return {
        ...state,
        SelectedPackagePricingData: data,
      };
    case SET_SELECTED_TOPIC_DATA:
      return {
        ...state,
        SelectedTopic: data,
      };
    case SET_POST_TOPIC_RES:
      return {
        ...state,
        TopicRes: data,
      };
    case SET_POST_TOPIC_SUCCESS:
      return {
        ...state,
        TopicSuccess: status,
      };
    case SET_POST_TOPIC_ERROR:
      return {
        ...state,
        TopicError: status,
      };
    case SET_PACKAGE_CHECKOUT_RES:
      return {
        ...state,
        PackageCheckOutRes: data,
      };
    case SET_PACKAGE_CHECKOUT_SUCCESS:
      return {
        ...state,
        PackageCheckOutSuccess: status,
      };
    case SET_PACKAGE_CHECKOUT_ERROR:
      return {
        ...state,
        PackageCheckOutError: status,
      };
    case SET_POST_JOB_RES:
      return {
        ...state,
        JobResponse: data,
      };
    case SET_JOB_POST_SUCCESS:
      return {
        ...state,
        JobResponseSuccess: status,
      };
    case SET_JOB_POST_ERROR:
      return {
        ...state,
        JobResponseError: status,
      };
    case SET_RECIPIENT_RES:
      return {
        ...state,
        RecipientData: data,
      };
    case SET_RECIPIENT_SUCCESS:
      return {
        ...state,
        RecipientSuccess: status,
      };
    case SET_RECIPIENT_ERROR:
      return {
        ...state,
        RecipientError: status,
      };
    case SET_CERTIFICATE_AND_STOREAGE_RES:
      return {
        ...state,
        CertificateAndStoreage: data,
      };
    case SET_CERTIFICATE_AND_STOREAGE_SUCCESS:
      return {
        ...state,
        CertificateAndStoreageSuccess: status,
      };
    case SET_SENDER_INFO_RES:
      return {
        ...state,
        ShipperInfo: data[0],
      };
    case SET_SENDER_INFO_SUCCESS:
      return {
        ...state,
        ShipperInfoSuccess: status,
      };
    case RESET_WIZARD:
      return initialState;

    case SET_POST_TOPIC_RES_EMPTY:
      return { ...state, TopicRes: null };
    case SET_ADDRESS_BOOK_RES:
      return {
        ...state,
        AddressBook: data,
      };
    case SET_ADDRESS_BOOK_SUCCESS:
      return {
        ...state,
        AddressBookSuccess: status,
      };

    default:
      return state;
  }
};

export default WizardStore;
