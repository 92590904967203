import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.6)'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ProhibatedItemsPopUp() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: 'contents' }}>
      <a onClick={handleClickOpen} style={{ color: '#2c56a5' }}>
        prohibited by law or postal regulation.
      </a>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg" fullWidth={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          USPS: Prohibited Items
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography variant="body1" style={{ color: 'red' }} gutterBottom>
            Below are some items that are illegal to ship with the Postal Service
          </Typography>
          <Typography variant="body2" gutterBottom>
            There are some items you can’t ship with USPS—no exceptions!
            <br />
            Be sure to read <a href='#'>all the USPS rules</a> if you want to ship anything that might be dangerous. Following the rules is your responsibility as the shipper.
            <br />
            You’ll notice that all prohibited domestic items are also prohibited from being shipped internationally. However, some items are only prohibited internationally and not domestically (such as aerosols and dry ice).
          </Typography>
          <Typography variant="h5" gutterBottom>
            Prohibited Domestic Items
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                secondary="•	Air bags"
              />
            </ListItem>
            <ListItem >
              <ListItemText
                secondary='•	Alcoholic Beverages'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Ammunition'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Explosives'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Gasoline'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Marijuana (medical or otherwise)'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Mercury'
              />
            </ListItem>
          </List>
          <Typography variant="h5" mt={1} gutterBottom>
            Prohibited International Items
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                secondary="•	Aerosols"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Air Bags'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Alcoholic Beverages'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='• Ammunition'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Cigarettes (these include electronic cigarettes, e-cigars, vaping products, and any components associated with these devices)'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Dry Ice'
              />
            </ListItem>

            <ListItem>
              <ListItemText
                secondary='•	Explosives'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Flammable Liquid'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Fresh Fruits and Vegetables '
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Gasoline'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Lighters'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Marijuana (medical or otherwise) '
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Nail Polish'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Perfumes (containing alcohol)'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='•	Poison'
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>

        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
