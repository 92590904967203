

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import CustomizedDimension from "./components/CustomizeDimension";
import { connect } from "react-redux";
import { setActPackageType, setActPackageData } from "redux/Actions/wizard";
import Breadcrumbs from "examples/Breadcrumbs";

function PackageType(props) {
  const {
    packageList,
    getPacksgeBtnState,
    packageType,
    packageData,
    senderInfo,
    recipientInfo,
  } = props;
  const { setFieldValue, values } = props.formData;
  const [selectedsWizard, setWizardSelection] = useState(values.packageType);
  const handleWizardSelect = (value) => {
    if (value === selectedsWizard) {
      setWizardSelection("");
      setFieldValue("packageType", "");
    } else {
      setWizardSelection(value);
      setFieldValue("packageType", value);
    }
  };

  const customButtonStyles = ({
    functions: { pxToRem },
    borders: { borderWidth },
    palette: { transparent, info },
  }) => ({
    width: pxToRem(150),
    height: pxToRem(150),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      border: `${borderWidth[2]} solid ${info.main} !important`,
    },
  });

  useEffect(() => {
    if (selectedsWizard) {
      getPacksgeBtnState(false);
    } else {
      getPacksgeBtnState(true);
    }
  }, [selectedsWizard]);

  return (
    <VuiBox>
      {/* <Breadcrumbs
        icon="home"
        title="Package Type"
        route={["dashboard", "General", "Sender Info", "Recipient Info", "Package Type"]}
      /> */}
      <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <VuiBox mb={1}>
          <VuiTypography variant="lg" fontWeight="bold" color="dark">
            SELECT PACKAGE TYPE
          </VuiTypography>
        </VuiBox>
      </VuiBox>
      <VuiBox mt={2}>
        <Grid container spacing={2} justifyContent="center">
          {packageList &&
            packageList.map((packData, i) => {
              return (
                <Grid key={i} item xs={12} md={2.5}>
                  <VuiBox textAlign="center">
                    <VuiButton
                      color="info"
                      variant={selectedsWizard === packData.name ? "contained" : "outlined"}
                      onClick={() => {
                        handleWizardSelect(packData.name);
                      }}
                      sx={customButtonStyles}
                    >
                      {packData.icon}
                    </VuiButton>
                    <VuiTypography variant="h6" color="dark">
                      {packData.name.toUpperCase()}
                    </VuiTypography>
                  </VuiBox>
                </Grid>
              );
            })}
        </Grid>
      </VuiBox>
      {selectedsWizard ? (
        <CustomizedDimension
          PackageType={selectedsWizard}
          packageData={packageData}
          setActPackageData={props.setActPackageData}
          senderInfo={senderInfo}
          recipientInfo={recipientInfo}
          formData={props.formData}
        />
      ) : undefined}
    </VuiBox>
  );
}

export default connect(
  (state) => ({
    packageType: state.WizardStore.packageType,
    packageData: state.WizardStore.packageData,
    senderInfo: state.WizardStore.sender_info,
    recipientInfo: state.WizardStore.recipient_info,
  }),
  {
    setActPackageType,
    setActPackageData,
  }
)(PackageType);
