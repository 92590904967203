import axios from "axios";

export const GetProfileApi = () => {
  var url = `${process.env.REACT_APP_API_BASE_URL}/shippers/details/${localStorage.username}`;

  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .get(url, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const PostAddressBookApi = (data) => {
  var url = `${process.env.REACT_APP_API_BASE_URL}/addressbook/${localStorage.ShipperHash
    }/${-1}/address`;

  const headers = {
    Authorization: `Bearer ${localStorage.id_token}`,
    ShipperHash: localStorage.ShipperHash,
    "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
