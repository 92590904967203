

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import CircularProgress from "@mui/material/CircularProgress";
import { Card } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "examples/Tables/Table";
import "./style.css";
import { connect } from "react-redux";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  ActPostTopic,
  ActPostJob,
  PostPackageCheckOut,
  setJobPostSuccess,
  PostRecipient,
  setActPostPackageCheckOutSuccess,
  setActRecipientSuccess,
  setActTopicSuccess,
  setActSeletedTopicData,
} from "redux/Actions/wizard";
import moment from "moment";
import Breadcrumbs from "examples/Breadcrumbs";
function specialService(data) {
  let total = 0;
  _.forEach(data, (service) => {
    total += service?.Price;
  });
  return total;
}
const dateFormat = "MM/DD/YYYY";
var antd_default_date = new Date();
antd_default_date.setDate(antd_default_date.getDate() + 5);

function getDate() {
  var date = new Date();
  date.setDate(date.getDate() + 5);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

function Summary(props) {
  const { formField, values, errors } = props.formData;
  console.log("values > ", values);
  const { shipDate } = formField;
  const {
    packageType,
    SelectedPackagePricingData,
    CertificateOfMailing,
    CertificateOfDelivery,
    RecordManagement,
    SelectedTopic,
  } = props;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    var rows = [];

    if (!CertificateOfMailing && !CertificateOfDelivery) {
      rows = [
        {
          Item: SelectedPackagePricingData?.DisplayName,
          Amount: `$ ${parsetFloat(SelectedPackagePricingData?.Total).toFixed(2)}`,
        },
        {
          Item: `${RecordManagement?.Name}`,
          Amount: `$ ${parseFloat(RecordManagement?.Price).toFixed(2)}`,
        },
        {
          Item: "Total",
          Amount: `$ ${parseFloat(
            SelectedPackagePricingData?.Total + RecordManagement?.Price
          ).toFixed(2)}`,
        },
      ];
    } else if (!CertificateOfMailing) {
      rows = [
        {
          Item: SelectedPackagePricingData?.DisplayName,
          Amount: `$ ${parseFloat(SelectedPackagePricingData?.StandardCost).toFixed(2)}`,
        },
        {
          Item: `Production Cost`,
          Amount: `$ ${parseFloat(SelectedPackagePricingData?.ProductionCost).toFixed(2)}`,
        },
        {
          Item: `${CertificateOfDelivery?.Name}`,
          Amount: `$ ${parseFloat(CertificateOfDelivery?.Price).toFixed(2)}`,
        },
        {
          Item: `${RecordManagement?.Name}`,
          Amount: `$ ${parseFloat(RecordManagement?.Price).toFixed(2)}`,
        },
        {
          Item: "Total",
          Amount: `$ ${parseFloat(
            SelectedPackagePricingData?.Total +
            CertificateOfDelivery?.Price +
            RecordManagement?.Price
          ).toFixed(2)}`,
        },
      ];
    } else if (!CertificateOfDelivery) {
      rows = [];
      let total = SelectedPackagePricingData?.Total + RecordManagement?.Price;

      rows.push({
        Item: SelectedPackagePricingData?.DisplayName,
        Amount: `$ ${parseFloat(SelectedPackagePricingData?.Total).toFixed(2)}`,
      });
      if (CertificateOfMailing?.Code != 'No') {
        rows.push({
          Item: `${CertificateOfMailing?.Name}`,
          Amount: `$ ${parseFloat(CertificateOfMailing?.Price).toFixed(2)}`,
        });
        total = total + CertificateOfMailing?.Price;
      }
      rows.push({
        Item: `${RecordManagement?.Name}`,
        Amount: `$ ${parseFloat(RecordManagement?.Price).toFixed(2)}`,
      });
      rows.push({
        Item: "Total",
        Amount: `$ ${parseFloat(total).toFixed(2)}`,
      });

    } else {
      rows = [];
      let total = SelectedPackagePricingData?.Total +

        CertificateOfDelivery?.Price +
        RecordManagement?.Price;

      rows.push({
        Item: SelectedPackagePricingData?.DisplayName,
        Amount: `$ ${parseFloat(SelectedPackagePricingData?.Total).toFixed(2)}`,
      });
      if (CertificateOfMailing?.Code != 'No') {
        rows.push({
          Item: `${CertificateOfMailing?.Name}`,
          Amount: `$ ${parseFloat(CertificateOfMailing?.Price).toFixed(2)}`,
        });
        total = total + CertificateOfMailing?.Price;
      }
      rows.push({
        Item: `${CertificateOfDelivery?.Name}`,
        Amount: `$ ${parseFloat(CertificateOfDelivery?.Price).toFixed(2)}`,
      });
      rows.push({
        Item: `${RecordManagement?.Name}`,
        Amount: `$ ${parseFloat(RecordManagement?.Price).toFixed(2)}`,
      });
      rows.push({
        Item: "Total",
        Amount: `$ ${parseFloat(total).toFixed(2)}`,
      });
    }
    setRows(rows);
  }, [SelectedPackagePricingData, CertificateOfMailing, CertificateOfDelivery, RecordManagement]);

  const serviceRows = [
    {
      Service: `Color`,
      Opted: values.colorType == "BW" ? "Black & White" : "Color",
    },
    {
      Service: `Print Side`,
      Opted: values.printType == "OS" ? `Single Sided` : "Double Sided",
    },
    {
      Service: `Return Envelope`,
      Opted: values.returnEnvelope ? "Yes" : "No",
    },
    {
      Service: `Page Count`,
      Opted: props.pageCount,
    },
  ];

  return (
    <VuiBox>
      {/* <Breadcrumbs
        icon="home"
        title="Summary"
        route={[
          "dashboard",
          "General",
          "Sender Info",
          "Recipient Info",
          "Package Type",
          "Postage Type",
          "Certificates",
          "Summary",
        ]}
      /> */}
      {/* <VuiBox width="100%" textAlign="left" mx="auto" mb={0}>

        <VuiTypography variant="lg" fontWeight="bold" color="dark">
          Case Name: {values?.caseName}
        </VuiTypography>
        <br />
        <VuiTypography variant="lg" fontWeight="bold" color="dark">
          Mail Name: {values?.mailingName}
        </VuiTypography>

      </VuiBox> */}
      <br />
      <Paper style={{ padding: 10, display: 'flex', flexDirection: 'column', marginBottom: 5 }}>
        <VuiTypography variant="md" fontWeight="bold" color="dark">
          Case Name: {values?.caseName}
        </VuiTypography>
        <VuiTypography variant="sm" fontWeight="bold" color="dark">
          Mail Name: {values?.mailingName}
        </VuiTypography>
      </Paper>

      <div style={{ marginBottom: 5 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <VuiTypography variant="sm" fontWeight="bold" color="dark" style={{ textTransform: 'capitalize' }}>
              To, {values?.recipientName}
            </VuiTypography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingBottom: 5 }}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={6}>
                <Paper elevation={0} style={{ minHeight: 128 }}>
                  <VuiTypography variant="lg" fontWeight="bold" color="dark">
                    From
                  </VuiTypography>

                  {values?.companyName ? <VuiBox>
                    <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                      {values?.companyName}
                    </VuiTypography>
                  </VuiBox> : null}

                  <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                    {values?.senderName}
                  </VuiTypography>

                  <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                    {values?.address1}
                  </VuiTypography>
                  <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                    {values.address2}
                  </VuiTypography>

                  <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                    {(values?.city, values?.state, values?.zip)}
                  </VuiTypography>
                </Paper>
              </Grid>
              <Grid item xs={6} md={6}>
                <Paper elevation={0} style={{ minHeight: 128 }}>
                  <VuiTypography variant="lg" fontWeight="bold" color="dark">
                    To
                  </VuiTypography>

                  {values?.recCompanyName ? <VuiBox>
                    <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                      {values?.recCompanyName}
                    </VuiTypography>
                  </VuiBox> : null}
                  <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                    {values?.recipientName}
                  </VuiTypography>
                  <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                    {values?.recAddress1}
                  </VuiTypography>
                  <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                    {values.recAddress2}
                  </VuiTypography>
                  <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                    {(values?.recCity, values?.recState, values?.recZip)}
                  </VuiTypography>
                </Paper>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <VuiBox mt={0}>
            <Paper style={{ padding: 10, minHeight: 180 }}>
              <table style={{ width: '100%' }}>
                <tr>
                  <td>ITEM</td>
                  <td style={{ width: '15%', textAlign: 'right' }}>AMOUNT</td>
                </tr>
                {
                  rows?.map((row, index) => (
                    <tr key={'a' + index}>
                      <td>{row.Item}</td>
                      <td style={{ textAlign: 'right' }}>{row.Amount}</td>
                    </tr>
                  ))
                }
              </table>
            </Paper>
            {/* <Table
              style={{ padding: 0 }}
              columns={[
                { name: "Item", align: "left" },
                { name: "Amount", align: "right" },
              ]}
              rows={rows}
            /> */}
          </VuiBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <VuiBox mt={0}>
            <Paper style={{ padding: 10, minHeight: 180 }}>
              <table style={{ width: '100%' }}>
                <tr>
                  <td>SERVICE</td>
                  <td style={{ width: '60%' }}>OPTED</td>
                </tr>
                {
                  serviceRows?.map((row, index) => (
                    <tr key={'b' + index}>
                      <td>{row.Service}</td>
                      <td>{row.Opted}</td>
                    </tr>
                  ))
                }
              </table>
            </Paper>
            {/* <Table
              columns={[
                { name: "Service", align: "left" },
                { name: "Opted", align: "left" },
              ]}
              rows={serviceRows}
            /> */}
          </VuiBox>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        {props.checkoutResponse.open ? (
          <Paper style={{ padding: 10 }}>
            <VuiBox>
              <div
                className="checkoutProgress"
                style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}
              >
                <VuiTypography fontSize={14} fontWeight="bold" color="dark">
                  Creating Topic :&nbsp;
                  {props.checkoutResponse.topicSuccess ? (
                    <CheckCircleIcon style={{ color: '#02b574' }} fontSize={'medium'} />
                  ) : props.checkoutResponse.TopicError ? (
                    <span style={{ color: "red" }}>{props.checkoutResponse.TopicError}</span>
                  ) : props.checkoutResponse.loader ? (
                    <CircularProgress size={15} />
                  ) : (
                    "Waiting..."
                  )}
                </VuiTypography>

                <VuiTypography fontSize={12} fontWeight="bold" color="dark">
                  Creating Job :&nbsp;
                  {props.checkoutResponse.jobSuccess ? (
                    <CheckCircleIcon style={{ color: '#02b574' }} fontSize={'medium'} />
                  ) : props.checkoutResponse.jobErorr ? (
                    <span style={{ color: "red" }}>{props.checkoutResponse.JobResponseError}</span>
                  ) : props.checkoutResponse.loader ? (
                    <CircularProgress size={15} />
                  ) : (
                    "Waiting..."
                  )}
                </VuiTypography>
                <VuiTypography fontSize={12} fontWeight="bold" color="dark">
                  Submitting Job :&nbsp;
                  {props.checkoutResponse.recipientSuccess ? (
                    <CheckCircleIcon style={{ color: '#02b574' }} fontSize={'medium'} />
                  ) : props.checkoutResponse.recipientError ? (
                    <span style={{ color: "red" }}>{props.checkoutResponse.RecipientError}</span>
                  ) : props.checkoutResponse.loader ? (
                    <CircularProgress size={15} />
                  ) : (
                    "Waiting..."
                  )}
                </VuiTypography>

                <VuiTypography fontSize={12} fontWeight="bold" color="dark">
                  Checkout Job :&nbsp;
                  {props.checkoutResponse.checkoutSuccess ? (
                    <CheckCircleIcon style={{ color: '#02b574' }} fontSize={'medium'} />
                  ) : props.checkoutResponse.checkoutError ? (
                    <span style={{ color: "red" }}>{props.checkoutResponse.checkoutError}</span>
                  ) : props.checkoutResponse.loader ? (
                    <CircularProgress size={15} />
                  ) : (
                    "Waiting..."
                  )}
                </VuiTypography>
              </div>
            </VuiBox>
          </Paper>
        ) : null}
      </Grid>
    </VuiBox>
  );
}

export default connect(
  (state) => ({
    senderInfoData: state.WizardStore.sender_info,
    RecipientInfo: state.WizardStore.recipient_info,
    packageType: state.WizardStore.packageType,
    packageData: state.WizardStore.packageData,
    SelectedPackagePricingData: state.WizardStore.SelectedPackagePricingData,
    CertificateOfMailing: state.WizardStore.CertificateOfMailing,
    CertificateOfDelivery: state.WizardStore.CertificateOfDelivery,
    RecordManagement: state.WizardStore.RecordManagement,
    SelectedTopic: state.WizardStore.SelectedTopic,
    TopicRes: state.WizardStore.TopicRes,
    JobResponse: state.WizardStore.JobResponse,
    PackageCheckOutRes: state.WizardStore.PackageCheckOutRes,
    RecipientData: state.WizardStore.RecipientData,
  }),
  {
    ActPostTopic,
    ActPostJob,
    PostPackageCheckOut,
    setJobPostSuccess,
    PostRecipient,
    setActTopicSuccess,
    setActRecipientSuccess,
    setActPostPackageCheckOutSuccess,
    setActSeletedTopicData,
  }
)(Summary);
