import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/background-cover-auth-signin.png";

// NEW CODE
import CognitoLogin from "../../../../components/CognitoUI/login";

// DPS UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";



function SignIn() {
  return (
    <CoverLayout
      title="Nice to see you!"
      color="dark"
      description="Enter your email and password to sign in"
      // description=""
      image={bgImage}
      premotto={""}
      motto={"Digital Postal Service"}
      header={<span></span>}
      top={10}
    >
      {/* <VuiBox component="form" role="form">
        <VuiBox mt={2} mb={1}>
          <VuiButton
            color="info"
            onClick={() => {
              const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
              const redirect_uri = `${location.origin}/prepare-session`;
              const REACT_APP_COGNITO_URL = process.env.REACT_APP_COGNITO_URL;

              window.location.href = `${REACT_APP_COGNITO_URL}/logout?client_id=${CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${redirect_uri}`;
            }}
            fullWidth
          >
            SIGN IN
          </VuiButton>

        </VuiBox>
      </VuiBox> */}
      <div>
        <CognitoLogin />
      </div>

    </CoverLayout>
  );
}

export default SignIn;
