

// react-routers components
import { Link, useHistory } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// DPS UI  React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import VuiButton from "components/VuiButton";
import EditIcon from "@mui/icons-material/Edit";
import VerifyButton from "../../../../components/Stripe/Varification";

function ProfileInfoCard({ title, description, data, social, action }) {
  const history = useHistory();
  const { socialMediaColors } = colors;
  const { size } = typography;

  // Render the card info items
  const renderItems = (
    // info &&
    // info.map((data, i) => (
    <div>
      <VuiBox display="flex" py={0} pr={2}>
        <VuiTypography
          variant="button"
          color="dark"
          fontWeight="regular"
          textTransform="capitalize"
        >
          {"Shipper ID:"}
        </VuiTypography>
        <VuiTypography variant="button" fontWeight="regular" color="dark">
          &nbsp;{data?.ShipperHash}
        </VuiTypography>
      </VuiBox>
      <VuiBox display="flex" py={0} pr={2}>
        <VuiTypography variant="button" fontWeight="regular" color="dark">
          {data?.PersonName}
        </VuiTypography>
      </VuiBox>
      <VuiBox display="flex" py={0} pr={2}>
        <VuiTypography variant="button" fontWeight="regular" color="dark">
          {data?.CompanyName}
        </VuiTypography>
      </VuiBox>
      <VuiBox display="flex" py={0} pr={2}>
        <VuiTypography variant="button" fontWeight="regular" color="dark">
          {data?.AddressLine1}
        </VuiTypography>
      </VuiBox>

      <VuiBox display="flex" py={0} pr={2}>
        <VuiTypography variant="button" fontWeight="regular" color="dark">
          {data?.AddressLine2}
        </VuiTypography>
      </VuiBox>
      <VuiBox display="flex" py={0} pr={2}>
        <VuiTypography variant="button" fontWeight="regular" color="dark">
          {`${data?.City}, ${data?.State} - ${data?.Zip}`},
        </VuiTypography>
      </VuiBox>
      <VuiBox display="flex" py={0} pr={2}>
        <VuiTypography variant="button" fontWeight="regular" color="dark">
          {data?.Phone}
        </VuiTypography>
      </VuiBox>
      <VuiBox display="flex" py={1} pr={2}>
        <VuiTypography variant="button" fontWeight="regular" color="dark">
          {data?.Email}
        </VuiTypography>
      </VuiBox>

      <VuiBox mb={1} mt={1} lineHeight={1}>
        {data?.IdentityVerified == true ? (
          <VuiButton variant="contained" fullWidth color="success">
            Identity Verified
          </VuiButton>
        ) : (
          <VerifyButton type="button" />
        )}
      </VuiBox>
    </div>
  );

  return (
    <Card
      sx={({ breakpoints }) => ({
        height: "100%",
      })}
    >
      <VuiBox display="flex" mb="14px" justifyContent="space-between" alignItems="center">
        <VuiTypography
          variant="lg"
          fontWeight="bold"
          color="dark"
          textTransform="capitalize"
          style={{ display: "contents" }}
        >
          {title}{" "}
          <VuiButton
            onClick={() => {
              history.push("profile/edit");
            }}
            varient="outlined"
            color="secondary"
          >
            <EditIcon />
          </VuiButton>
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <VuiBox mb={2} lineHeight={1}>
          <VuiTypography variant="button" color="text" fontWeight="regular">
            {description}
          </VuiTypography>
        </VuiBox>
        <VuiBox opacity={0.3}>
          <Divider />
        </VuiBox>
        <VuiBox>{renderItems}</VuiBox>
      </VuiBox>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
// ProfileInfoCard.propTypes = {
//   title: PropTypes.string.isRequired,
//   description: PropTypes.string.isRequired,
//   info: PropTypes.arrayOf(PropTypes.object).isRequired,
// };

export default ProfileInfoCard;
