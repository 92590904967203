import React, { useState } from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from "moment";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";


function Row(props) {
    const { row } = props;
    return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>{row?.TransactionId}</TableCell>
            <TableCell>{moment(row?.TransactionDate).format("MMM DD, YYYY h:mm A")}</TableCell>
            <TableCell>{row?.TransactionStatus}</TableCell>
            <TableCell align="right">${row?.DepositAmount}</TableCell>
            <TableCell>
                <a href={row?.ReceiptUrl} target={"_blank"}>
                    <InsertDriveFileIcon style={{ width: "2rem", height: "2rem" }} />
                </a>
            </TableCell>
        </TableRow>
    )
}

export default Row;