

// DPS UI  React Base Styles
// import bgAdmin from "assets/images/body-background.png";
import colors from "assets/theme/base/colors";

const { info, dark } = colors;

export default {
  html: {
    scrollBehavior: "smooth",
  },

  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  ".dps-loader": {
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    display: "flex",
    zIndex: "1000000000",
    backgroundColor: "rgba(255,255,255,0.1)",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  ".dps-loader h5": {
    color: "#344767",
    marginTop: 10
  }

};
