import { useEffect, useState } from "react";
import DataTable from "examples/Tables/DataTable";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import moment from "moment";
import axios from "axios";
import Spin from "components/Spin";
import Notification from "components/Notification";
export default function AccountTable(props) {
  const { setFieldValue } = props.formData;
  const { getTopicFromTable, handleClose } = props;
  const [previousMailing, setPreviousMailings] = useState();
  const [loadAccountDialog, setLoadAccountDialog] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    setLoadAccountDialog(true);
    var url = `${process.env.REACT_APP_API_BASE_URL}/jobs/${localStorage.ShipperHash}`;
    const headers = {
      Authorization: `Bearer ${localStorage.id_token}`,
      ShipperHash: localStorage.ShipperHash,
      "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
    };
    axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.status === 200) {
          setLoadAccountDialog(false);
          if (res.data.length <= 0) {
            setMessageOpen(true);
            setMessage("No data found!");
            setMessageType("warning");
          }
          setPreviousMailings(res.data);
        }
      })
      .catch((err) => {
        setLoadAccountDialog(false);
        setMessageOpen(true);
        setMessage(err.message);
        setMessageType("error");
      });
  }, []);

  const handleChange = (data, sendData) => {
    getTopicFromTable(data);
    setFieldValue("caseName", data.Name);
    handleClose(false);
  };
  const TopicSelection = (data, sendData) => {
    return (
      <VuiBox display="flex" alignItems="center">
        <Checkbox
          onChange={() => handleChange(data, sendData)}
          sx={({ palette: { info } }) => ({
            "&.Mui-checked": {
              color: info.main,
            },
          })}
        />
        <VuiBox ml={1}>
          <VuiTypography variant="caption" color="text">
            {data.Name}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    );
  };

  const columns = [
    {
      Header: "Order Name",
      accessor: "Name",
      width: "20%",
      Cell: ({ value, row }) => {
        return TopicSelection(row.values, row.original);
      },
    },
    {
      Header: "Shipment Name",
      accessor: "Subject",
      width: "20%"
    },

    {
      Header: "Mail Date",
      accessor: "CreatedDate",
      width: "20%",
      Cell: ({ value }) => {
        return moment(value).format("MMM DD, YYYY");
      },
    },
    { Header: "Mail Type", accessor: "PostageType", width: "20%" },
    {
      Header: "Status", accessor: "LastStatus", width: "20%",
      Cell: ({ value }) => {
        return <div title={value} style={{ textTransform: 'capitalize', paddingLeft: 5, maxWidth: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {value}
        </div>
      }
    },
  ];

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMessageOpen(false);
  };

  setTimeout(() => {
    if (messageOpen) {
      setMessageOpen(false);
    }
  }, 6000);

  return (
    <Spin tip="Loading..." spinning={loadAccountDialog}>
      <Notification
        open={messageOpen}
        type={messageType}
        message={message}
        handleClose={handleMessageClose}
      />
      <DataTable table={{ columns: columns, rows: previousMailing || [] }} canSearch={true} />
    </Spin>
  );
}
