

// @mui material components
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import VuiBox from "components/VuiBox";
import Globe from "examples/Globe";
import { useHistory } from "react-router-dom";
import "./style.css";

function Default() {
  const { values } = breakpoints;
  const history = useHistory();

  const handleSend = () => {
    history.push("/applications/send");
  };
  const handleTrack = () => {
    history.push("/track/all-mails");
  };
  const handleVerify = () => {
    history.push("/verify/blockchain");
  };
  return (
    // <VuiBox py={3} style={{ margin: "auto", display: "flex", alignItems: "center"}}>
    <VuiBox>
      <Grid container justifyContent="center" style={{ height: '80vh', alignItems: 'center' }}>
        <Grid item xs={12} lg={12}>
          <Grid container style={{ position: 'absolute', top: '55%', left: '50%', width: '80%', transform: "translate(-50%, -50%)" }}>
            <Grid item xs={4}>
              <VuiBox mb={3} p={1}>
                <Button
                  variant="outlined"
                  fullWidth
                  size="large"
                  onClick={handleSend}
                  style={{ color: "#344747", fontSize: 24, fontWeight: 500 }}
                >
                  SEND
                </Button>
              </VuiBox>
            </Grid>
            <Grid item xs={4}>
              <VuiBox mb={3} p={1}>
                <Button
                  variant="outlined"
                  fullWidth
                  size="large"
                  onClick={handleTrack}
                  style={{ color: "#344747", fontSize: 24, fontWeight: 500 }}
                >
                  TRACK
                </Button>
              </VuiBox>
            </Grid>
            <Grid item xs={4}>
              <VuiBox mb={3} p={1}>
                <Button
                  variant="outlined"
                  fullWidth
                  size="large"
                  onClick={handleVerify}
                  style={{ color: "#344747", fontSize: 24, fontWeight: 500 }}
                >
                  VERIFY
                </Button>
              </VuiBox>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={6} md={6} display={{ xs: "none", md: "flex" }}></Grid>
            <Grid item lg={6} md={6} xs={12} justifyContent="end">
              <Grid>
                <Globe
                  display={{ xs: "none", md: "flex" }}
                  // position="relative"
                  // height="100vh"
                  // alignItems="center"
                  // justifyContent="end"
                  right={0}
                  zIndex={-1}
                  // mt={{ xs: -12, lg: 1 }}
                  // mr={{ xs: 0, md: -10, lg: 10 }}
                  canvasStyle={{ marginTop: "0rem", }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </VuiBox>
  );
}

export default Default;
