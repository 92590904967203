

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import validations from "layouts/applications/verify-wizard/schemas/validations";
import form from "layouts/applications/verify-wizard/schemas/form";
import initialValues from "layouts/applications/verify-wizard/schemas/initialValues";
import Card from "@mui/material/Card";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import { setLoaderStatus } from "redux/Actions/common";
import { getVerificationApi } from "redux/Actions/verify";
import { useHistory } from "react-router-dom";

function Verify(props) {
  const history = useHistory();
  const { loader } = props;
  const { formId, formField } = form;
  const { verifyName, nftId, verifyType } = formField;
  const currentValidation = validations[0];

  return (
    <VuiBox
      pt={5}
      pb={5}
      style={{
        width: "80%",
        margin: "auto",
        flexDirection: "column",
        // height: "80vh",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VuiTypography variant="h3" color="dark" textAlign="center" fontWeight="bold">
              ENTER YOUR VERIFICATION INFORMATION
            </VuiTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="center" mt={0} pb={0}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={(values, actions) => {
              let data = {
                NftToken: values.nftId,
              };

              history.push("/verified/blockchain?vf=true", { Id: values.nftId });

              // props.getVerificationApi(data).then((res) => {
              //   if (res.success) {
              //     history.push("/send/verified?vf=true");
              //   }
              // });
            }}
          >
            {({ values, errors, touched }) => (
              <Form id={formId} autoComplete="off">
                <Grid container spacing={0} justifyContent="center" mt={3} pb={2}>
                  {/* <Grid item xs={12} md={8}>
                    <FormField
                      label={verifyName.label}
                      name={verifyName.name}
                      type={verifyName.type}
                      value={values.verifyName}
                      placeholder={verifyName.placeholder}
                      error={errors.verifyName && touched.verifyName}
                      success={values.verifyName.length > 0 && !errors.verifyName}
                      inputProps={{ autoComplete: "" }}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={8}>
                    <FormField
                      style={{ minWidth: 250 }}
                      label={"Document/NFT ID"}
                      name={nftId.name}
                      type={nftId.type}
                      value={values.nftId}
                      placeholder={nftId.placeholder}
                      error={errors.nftId && touched.nftId}
                      success={values.nftId.length > 0 && !errors.nftId}
                      inputProps={{ autoComplete: "" }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={8}>
                    <FormField
                      label={verifyType.label}
                      name={verifyType.name}
                      type={verifyType.type}
                      value={values.verifyType}
                      placeholder={verifyType.placeholder}
                      error={errors.verifyType && touched.verifyType}
                      success={values.verifyType.length > 0 && !errors.verifyType}
                      inputProps={{ autoComplete: "" }}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={8}>
                    <VuiButton variant="contained" color="info" type="submit" disabled={loader}>
                      Verify
                      {loader ? <CircularProgress color={"error"} size={25} /> : ""}
                    </VuiButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Card>
    </VuiBox>
  );
}

export default connect(
  (state) => ({
    loader: state.CommonStore.loader,
  }),
  {
    setLoaderStatus,
    getVerificationApi,
  }
)(Verify);
