

// @mui material components
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

import Welcome from "layouts/pages/profile/profile-overview/components/Welcome/index";
import { useEffect, useState } from "react";
import axios from "axios";
import Globe from "examples/Globe";
import Spin from "components/Spin";
import { connect } from "react-redux";
import { setProfileStatusCode } from "redux/Actions/common";

function Overview(props) {
  const { Profile } = props;
  const [loadProfile, setLoadProfile] = useState(false);

  return (
    <Spin tip="Loading..." spinning={loadProfile}>
      <VuiBox mt={10} pb={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={5} xxl={5} className="SUMANPROFILE">
            <ProfileInfoCard
              title="profile information"
              description={`Hi, I’m ${Profile?.PersonName ? Profile.PersonName : ""}`}
              data={Profile}
            />

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Globe
                  display={{ xs: "none", md: "block" }}
                  position="absolute"
                  top="10%"
                  right={0}
                  mt={{ xs: -12, lg: 1 }}
                  mr={{ xs: 0, md: -10, lg: 10 }}
                  canvasStyle={{ marginTop: "5rem" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </VuiBox>
    </Spin>
  );
}

export default connect(
  (state) => ({
    Profile: state.CommonStore.Profile,
  }),
  { setProfileStatusCode }
)(Overview);
