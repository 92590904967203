
// @flow
import React, { useEffect } from "react";
import Spin from "components/Spin";
import { useHistory } from "react-router-dom";
import PageLayout from "examples/LayoutContainers/PageLayout";
import moment from "moment";
import { setShiper } from "redux/Actions/profile";
import { setLogin } from "redux/Actions/common";
import { connect } from "react-redux";
import getAccessToken from "api/getCognitoAccessToken";
import { setProfileStatusCode, GetProfile, setProfileDataRes } from "redux/Actions/common";
// import dpslogo from "../../../assets/images/dps-logo.png";
import CostEstimate from "../../components/cost-estimate";

const CostEstimatePage = (props) => {
    return (
        <PageLayout>
            <CostEstimate />
        </PageLayout>
    );
}
export default CostEstimatePage;