

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// DPS UI Dashboard React components
import VuiButton from "components/VuiButton";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import GridDiologBox from "./AccountDiologBox";
import VuiSwitch from "components/VuiSwitch";
import { connect } from "react-redux";
import "./style.css";
import {
  getTopic,
  setActSeletedTopicData,
  GetActSenderInfo,
  UpdateLoadSenderInfo,
  setActTopicPostResEmpty,
} from "redux/Actions/wizard";
import { setLoaderStatus } from "redux/Actions/common";
import Breadcrumbs from "examples/Breadcrumbs";
import FormField from "layouts/pages/users/new-user/components/FormField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import Spin from "components/Spin";
import SenderInfo from "../SenderInfo";
import ShowPdfPopup from "../../../track-wizard/Component/ShowPdfPopup";
import axios from "axios";

import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Typography,
} from "@mui/material";
import Card from '@mui/material/Card';
import HazardousPopUp from './hazardius-pop-up';
import ProhibatedItemsPopUp from './prohabited-pop-up';
import VuiSelect from "components/VuiSelect";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Account(props) {
  const {
    formField,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = props.formData;
  const { mailingName, caseName } = formField;
  const [expanded, setExpanded] = useState(true);
  const [checkView, setCheckView] = useState(false);
  const {
    mailingName: mailingNameV,
    caseName: caseNameV,
    PackageContent: PackageContentV,
    HazardousMaterial: HazardousMaterialV,
    IsHazardous,
  } = values;
  const {
    senderInfoData,
    ShipperInfo,
    ShipperInfoSuccess,
    LoadSenderInfo,
    handleOnEditMailing,
    sendType,
  } = props;

  const { loader, SelectedTopic, TopicRes } = props;
  const [openGrid, setOpenGrid] = useState(false);
  const [disableSubject, setDisableSubject] = useState("");
  const [previousMailings, setPreviousMailings] = useState([]);
  const [PackageContent, setPackageContent] = useState("Standard");

  useEffect(async () => {
    if (senderInfoData === undefined || senderInfoData === null) {
      if (!LoadSenderInfo) {
        props.GetActSenderInfo();
        props.UpdateLoadSenderInfo(!LoadSenderInfo);
      }
    }
  }, [LoadSenderInfo]);

  useEffect(() => {
    if (senderInfoData === undefined || senderInfoData === null) {
      stateUpdate(ShipperInfo);
    } else {
      stateUpdate(senderInfoData);
    }
  }, [ShipperInfoSuccess, ShipperInfo, senderInfoData]);

  const stateUpdate = (data) => {
    setFieldValue("address1", data?.AddressLine1 || "");
    setFieldValue("address2", data?.AddressLine2 || "");
    setFieldValue("city", data?.City || "");
    setFieldValue("state", data?.State || "");
    setFieldValue("zip", data?.Zip || "");
    setFieldValue("senderName", data?.PersonName || "");
    setFieldValue("companyName", data?.CompanyName || "");
    setFieldValue("phone", data?.Phone || "");
    setFieldValue("country", data?.Country || "");
    setFieldTouched("state", false);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseGrid = (open) => {
    setOpenGrid(open);
  };

  const handleTopic = (e) => {
    let data = SelectedTopic ? SelectedTopic : {};
    data["Name"] = e.target.value;
    props.setActSeletedTopicData(data);
    props.setActTopicPostResEmpty();
    setFieldValue("caseName", e.target.value);
    setFieldValue("topicHash", "");
    if (handleOnEditMailing != null && handleOnEditMailing != undefined) {
      handleOnEditMailing();
    }
  };

  const handleOrderChange = (e) => {
    setFieldValue("orderChange", e.target.checked);
    props.setActTopicPostResEmpty();
    if (!e.target.value) {
      setFieldValue("topicHash", "");
    } else {
      setFieldValue("caseName", "");
      setDisableSubject("");
    }
    if (handleOnEditMailing != null && handleOnEditMailing != undefined) {
      handleOnEditMailing();
    }
  };

  const handleTopicVarification = async (e) => {
    if (e.target.value) {
      props.setLoaderStatus(true);
      props.getTopic(e.target.value).then((res) => {
        if (res.status) {
          props.setLoaderStatus(false);
          setDisableSubject("Topic already exist");
        } else {
          setDisableSubject("");
          props.setLoaderStatus(false);
        }
      });
    }
  };

  const getTopicFromTable = (selectedTopicData) => {
    setFieldValue("caseName", selectedTopicData.Name);

    if (values?.mailingName != null && values?.mailingName != '') {
      handleSubjectVarification(selectedTopicData.Name, values?.mailingName);
    }

  };

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubjectVarification = (caseName, Subject) => {
    setErrorMessage("");
    if (caseName == null || caseName == undefined || caseName == '') {
      return;
    }

    if (Subject) {
      props.setLoaderStatus(true);
      props.getTopic(caseName, Subject).then((res) => {
        if (res.status) {
          props.setLoaderStatus(false);
          setErrorMessage("Shipment already exist");
        } else {
          setErrorMessage("");
          props.setLoaderStatus(false);
        }
      });
    }
  };



  const onCheckView = (value) => {
    setCheckView(value);
  };

  const handleSubjectChange = (e) => {
    props.setActTopicPostResEmpty();
    setFieldValue("mailingName", e.target.value);
    if (handleOnEditMailing != null && handleOnEditMailing != undefined) {
      handleOnEditMailing();
    }
  };

  const handleChange = (e) => {
    setFieldValue("PackageContent", e.target.value);
    if (e.target.value === "Hazardous") {
      setFieldValue("IsHazardous", true);
    } else {
      setFieldValue("IsHazardous", false);
    }
  };

  const controlProps = (item) => ({
    checked: PackageContentV === item,
    onChange: handleChange,
    value: item,
    name: "PackageContent",
    inputProps: { "aria-label": item },
  });

  const handleDocumentClick = () => {
    props.setLoaderStatus(true);
    const headers = {
      Authorization: `Bearer ${localStorage.id_token}`,
      ShipperHash: localStorage.ShipperHash,
      "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
    };
    const data = {
      Key: props?.documentKey,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/downloads`, data, {
        headers: headers,
      })
      .then((res) => {
        setDocumentPreviewDialogOpen(true);
        setDocumentUrl(res.data.Url);
      }).finally(() => {
        props.setLoaderStatus(false);
      });
  };
  const [documentPreviewDialogOpen, setDocumentPreviewDialogOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');


  return (
    <Spin tip={"Loading..."} spinning={loader}>
      <VuiBox>
        <ShowPdfPopup open={documentPreviewDialogOpen} url={documentUrl} SetOpen={setDocumentPreviewDialogOpen} />
        <VuiBox>
          <Grid container spacing={0} justifyContent="center">
            <Grid item>
              <VuiBox mb={1}>
                <VuiTypography variant="lg" fontWeight="bold" color="dark">
                  MAIL INFORMATION
                </VuiTypography>
              </VuiBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <VuiTypography variant="h6" color="dark">
                <VuiSwitch color="info" checked={values.orderChange} onChange={handleOrderChange} />
                <span style={{ marginLeft: 10 }}>CHAIN TO PREVIOUS ORDER</span>
              </VuiTypography>
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox>
          <Grid container>
            <Grid item xs={10}>
              <FormField
                label={caseName.label}
                name={caseName.name}
                type={caseName.type}
                value={caseNameV}
                disabled={values.orderChange}
                onChange={handleTopic}
                onBlur={handleTopicVarification}
                placeholder={caseName.placeholder}
                error={errors.caseName && touched.caseName && disableSubject}
                success={caseNameV.length > 0 && !errors.caseName && !disableSubject.length > 0}
                inputProps={{ autoComplete: "" }}
              />
              <VuiBox mt={0.75}>
                <VuiTypography component="div" variant="caption" color="error">
                  {disableSubject.length > 0 && (
                    <div className="input-feedback">{disableSubject}</div>
                  )}
                </VuiTypography>
              </VuiBox>
            </Grid>
            <Grid item xs={2} mt={4} pl={2}>
              {values.orderChange ? (
                <VuiButton
                  variant="outlined"
                  onClick={handleCloseGrid}
                  disabled={!values.orderChange}
                  color="info"
                >
                  Search
                </VuiButton>
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={10}>
              <FormField
                label={mailingName.label}
                name={mailingName.name}
                type={mailingName.type}
                value={mailingNameV}
                onChange={handleSubjectChange}
                onBlur={(e) => {
                  handleSubjectVarification(values?.caseName, e.target.value)
                }}
                placeholder={mailingName.placeholder}
                error={errors.mailingName && touched.mailingName}
                success={mailingNameV.length > 0 && !errors.mailingName}
                inputProps={{ autoComplete: "" }}
              />
              <VuiBox mt={0.75}>
                <VuiTypography component="div" variant="caption" color="error">
                  {errorMessage.length > 0 && (
                    <div className="input-feedback">{errorMessage}</div>
                  )}
                </VuiTypography>

              </VuiBox>
            </Grid>
            {sendType && sendType === "ePostage" ? (
              <Grid item xs={10}>
                <Typography variant="caption">
                  <b>Package Content</b>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <FormControlLabel
                      value="Standard"
                      control={<Radio {...controlProps("Standard")} size="small" color="primary" />}
                      label="Standard"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FormControlLabel
                      value="Hazardous"
                      control={<Radio {...controlProps("Hazardous")} size="small" />}
                      label="Hazardous Material"
                      labelPlacement="end"
                    />
                  </Grid>
                  {IsHazardous ? (
                    <Grid item md={12}>


                      <VuiBox mt={0}>
                        <Grid container pl={1}>
                          <Checkbox checked={true} disabled={true} /><VuiTypography variant="body2">Perfume, nail polish, hair spray, dry ice, lithium batteries, lighters, fuels, etc.</VuiTypography>
                        </Grid>
                        <Card style={{ borderRadius: 4, marginTop: 10, backgroundColor: "#ebcfce", padding: 10 }}>
                          <VuiTypography variant="body2" style={{ color: '#b3625a', fontWeight: 500 }}>
                            By using Digital Postal Service, you certify that your shipment does not contain any undeclared <HazardousPopUp /> or any matter <ProhibatedItemsPopUp />
                          </VuiTypography>
                        </Card>
                      </VuiBox>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {props?.documentKey ? (
                <VuiButton variant="outlined" color="dark" onClick={handleDocumentClick}>
                  View Document
                </VuiButton>
              ) : null}
              <br />
              <VuiTypography variant="span" color="dark" fontWeight="medium">
                {" "}
                Sender Info
              </VuiTypography>{" "}
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Grid>

            <Grid item xs={6}>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <SenderInfo formData={props.formData} checkView={checkView} />
              </Collapse>
            </Grid>
          </Grid>
        </VuiBox>
        <GridDiologBox
          openGrid={openGrid}
          previousMailings={previousMailings}
          getTopicFromTable={getTopicFromTable}
          handleCloseGrid={handleCloseGrid}
          formData={props.formData}
        />
      </VuiBox>
    </Spin>
  );
}

export default connect(
  (state) => ({
    topicDataGet: state.WizardStore.topicData,
    TopicRes: state.WizardStore.TopicRes,
    loader: state.CommonStore.loader,
    SelectedTopic: state.WizardStore.SelectedTopic,
    senderInfoData: state.WizardStore.sender_info,
    ShipperInfo: state.WizardStore.ShipperInfo,
    ShipperInfoSuccess: state.WizardStore.ShipperInfoSuccess,
    LoadSenderInfo: state.WizardStore.loadSenderInfo,
  }),
  {
    getTopic,
    setActSeletedTopicData,
    GetActSenderInfo,
    UpdateLoadSenderInfo,
    setLoaderStatus,
    setActTopicPostResEmpty,
  }
)(Account);
