import { SET_SHIPPER_HASH } from "../Type";
import { PURGE } from "redux-persist";
const initialState = {
  ShipperHash: "",
};

const ProfileStore = (state = initialState, { type, args }) => {
  switch (type) {
    case PURGE:
      return initialState;
    case SET_SHIPPER_HASH:
      return { ...state, ShipperHash: args.ShipperHash };
    default:
      return state;
  }
};

export default ProfileStore;
