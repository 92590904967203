

// DPS UI  React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// DPS UI  React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderWidth } = borders;
const { grey, transparent } = colors;

export default {
  styleOverrides: {
    // root: {
    //   backgroundColor: `${transparent.main} !important`,
    //   padding: `${pxToRem(12)} ${pxToRem(16)}`,
    //   "& .MuiBox-root": {
    //     pl: "0px !important",
    //   },
    //   borderBottom: `${borderWidth[1]} solid ${grey[600]}`,
    // },
  },
};
