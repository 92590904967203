

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
// DPS UI  React icons
import HomeDecor from 'assets/images/home-decor-1.jpg';
import Card from "@mui/material/Card";
import { useHistory } from "react-router-dom";
import VuiDropzone from "components/VuiDropzone";

function SendEpostage() {
  const history = useHistory();
  return (
    <VuiBox pt={20} pb={20} style={{ width: "80%", margin: 'auto', }}>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VuiTypography variant="h3" color="dark" textAlign="center" fontWeight="bold">
              DOCUMENT INFORMATION
            </VuiTypography>
          </Grid>
          <Grid item xs={12} md={3} textAlign="center">
            <Card sx={{ overflow: "visible", height: "100%" }}>
              <img src={HomeDecor} />
            </Card>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card>
              <VuiBox textAlign="center">
                <Grid container spacing={3} pl={10}>
                  <Grid item xs={12} md={8}>
                    <VuiTypography textAlign="left">DOCUMENT ID: JRFH6464</VuiTypography>
                    <VuiTypography textAlign="left">NfT ID: JH6464</VuiTypography>
                    <VuiTypography textAlign="left">MIDNIGHT OCEAN,</VuiTypography>
                    <VuiTypography textAlign="left">DESCRIPTION "PAINTING BY JoL"</VuiTypography>
                    <VuiTypography textAlign="left">DESCRIPTION "PAINTING BY JoL"</VuiTypography>
                  </Grid>
                </Grid>
                <Grid container pl={10} mt={2}>
                  <VuiButton variant="contained" color="primary"
                    onClick={() => {
                      history.push(`/nft/list`);
                    }}
                  >SAVES TO NFT LIST</VuiButton>
                  <VuiButton variant="contained" color="primary" style={{ marginLeft: 15 }}>STARTS EPOSTAGE WIZARD</VuiButton>
                </Grid>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </VuiBox>

  );
}

export default SendEpostage;
