import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { FaBoxOpen, FaPrint, FaRegFilePdf } from "react-icons/fa";
import { SiChainlink } from "react-icons/si";


import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import moment from "moment";

import polygon_logo from "../../../assets/images/logos/polygon.png";

function Row(props) {
    const { row } = props;
    const [tableRowOpen, setTableRowOpen] = useState(false);
    const [actionButtonOpen, setActionButtonOpen] = useState(false);
    const actionRef = React.useRef(null);

    const handleActionToggle = () => {
        setActionButtonOpen((prevOpen) => !prevOpen);
    };

    const handleActionClose = (event) => {
        if (actionRef.current && actionRef.current.contains(event.target)) {
            return;
        }

        setActionButtonOpen(false);
    };

    return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>{row?.ToName}</TableCell>
            <TableCell>{row?.JobHash}</TableCell>
            <TableCell>{row?.RetryCount}</TableCell>
            <TableCell>{row?.LastAction}</TableCell>
            <TableCell>
                {
                    row?.CreatedDate != null && row?.CreatedDate != "" && row?.CreatedDate != "None" ? moment(row?.CreatedDate).format("MMM DD, YYYY hh:mm A") : null
                }
            </TableCell>
            <TableCell>
                {
                    row?.DispatchDate != null && row?.DispatchDate != "" && row?.DispatchDate != "None" ? moment(row?.DispatchDate).format("MMM DD, YYYY hh:mm A") : null
                }
            </TableCell>
        </TableRow>
    )
}

export default Row;