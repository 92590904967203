import VuiBox from "components/VuiBox";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import VuiTypography from "components/VuiTypography";
import { RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import TokenIcon from "@mui/icons-material/Token";
import dps_logo from "../../../../../../assets/images/dps.png";

const CodRecordManagement = (props) => {
  const { prices } = props;
  const [CertificateOfDelivery, setCertificateOfDelivery] = useState(
    props.CertificateOfDelivery?.Code || "COD"
  );

  useEffect(() => {
    if (props.CertificateOfDelivery?.Code) {
      setCertificateOfDelivery(props.CertificateOfDelivery?.Code);
    } else {
      var data = _.find(prices || [], { Code: "COD" });
      props.setActCertificateOfDelivery(data);
    }
  }, [props.CertificateOfDelivery, prices]);

  const handleChange = (e) => {
    setCertificateOfDelivery(e.target.value);
  };

  const HandleRadioClick = (data) => {
    props.setActCertificateOfDelivery(data);
  };

  return (
    <VuiBox
      mt={1}
      color="white"
      bgColor="white"
      shadow="lg"
      opacity={1}
      p={2}
      style={{ borderRadius: "10px", height: "260px" }}
    >
      <RadioGroup
        name="CertificateOfDelivery"
        value={CertificateOfDelivery}
        onChange={handleChange}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <img src={dps_logo} style={{ width: 90 }} />
            {/* <TokenIcon color="dark" style={{ width: "4rem", height: "4rem" }} /> */}
          </Grid>
          <Grid xs={12} pl={3.3}>
            <VuiTypography variant="lg" color="dark">
              Certificate of Delivery (COD)
            </VuiTypography>
          </Grid>
        </Grid>
        {_.map(prices || [], (price, i) => (
          <Grid container>
            <Grid item xs={12} md={12}>
              <Radio
                value={price.Code}
                onClick={() => {
                  HandleRadioClick(price);
                }}
              />
              <span style={{ color: "#344747", fontSize: 15 }}>
                {price?.Name}
                &nbsp;&nbsp;&nbsp;
                <span>
                  {
                    price?.Price <= 0 ? "Included" : `$${price?.Price}`
                  }
                </span>
              </span>
            </Grid>
          </Grid>
        ))}
      </RadioGroup>
    </VuiBox>
  );
};
export default CodRecordManagement;
