import * as React from "react";
import PropTypes from "prop-types";
// import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import Table from "examples/Tables/Table";
// DPS UI Dashboard React components
import VuiBox from "components/VuiBox";
import Grid from "@mui/material/Grid";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import ReactToPrint from 'react-to-print';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ViewReceipt(props) {
  const { open, data, handleReceiptClose } = props;
  const [_open, setOpen] = React.useState(props.open);

  React.useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    handleReceiptClose();
  };
  const handlePrint = () => {
    var printContents = document.getElementById('modal_container').innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  const componentRef = React.useRef();

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={_open}
        maxWidth="lg"
        fullWidth={true}
        scroll="paper"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          CERTIFIED RECORDED RECEIPT
        </BootstrapDialogTitle>
        <DialogContent dividers ref={componentRef}>
          <VuiTypography variant="h6" textAlign="center" gutterBottom>
            Job ID: {`${data?.JobHash}`}
          </VuiTypography>
          <VuiTypography variant="h6" textAlign="center" gutterBottom>
            DOCUMENT ID: {data?.DocumentHash || "--"}
          </VuiTypography>
          <VuiTypography variant="h6" textAlign="center" gutterBottom>
            Total Price: {`$${data?.CustomerChargedPrice}`}
          </VuiTypography>
          <VuiTypography variant="h6">POSTAGE RECORD:</VuiTypography>
          <Grid xs={12} className="removeBorder">
            <Table
              columns={[
                { name: "particulars", align: "left" },
                { name: "details", align: "left" },
              ]}
              rows={[
                {
                  particulars: `${data?.PostageType}`,
                  details: `$${data?.PostagePrice}`,
                },
                {
                  particulars: "Weight(oz):",
                  details: data?.Weight,
                },
                {
                  particulars: "No of Pages:",
                  details: data?.PageCount,
                },
                {
                  particulars: "Ordered Date:",
                  details: data?.OrderedDate
                    ? moment(data?.OrderedDate).format("MMM DD, YYYY")
                    : "--",
                },
                {
                  particulars: "Ship Date:",
                  details:
                    data?.ShipDate !== "None"
                      ? moment(data?.OrderedDate).format("MMM DD, YYYY")
                      : "--",
                },
                {
                  particulars: "Mailed Date:",
                  details:
                    data?.MailDate !== "None"
                      ? moment(data?.MailDate).format("MMM DD, YYYY")
                      : "--",
                },
                {
                  particulars: "Delivered Date:",
                  details:
                    data?.DeliveryDate !== "None"
                      ? moment(data?.DeliveryDate).format("MMM DD, YYYY")
                      : "--",
                },
              ]}
            />
          </Grid>
          <VuiTypography gutterBottom variant="h6">
            MAILING INFO RECORD:
          </VuiTypography>
          <Grid item xs={12} className="removeBorder">
            <Table
              columns={[
                { name: "particulars", align: "left" },
                { name: "details", align: "left" },
              ]}
              rows={[
                {
                  particulars: "CASE NAME:",
                  details: data?.PersonName,
                },
                {
                  particulars: "SHIPPER ID:",
                  details: data?.ShipperHash,
                },
                {
                  particulars: "SENDER:",
                  details: `${data?.FromName} ${data?.FromCompany ? data?.FromCompany : ""} ${data?.FromAddressLine1
                    } ${data?.FromCity}, ${data?.FromState} ${data?.FromZiP}`,
                },
                {
                  particulars: "RECEPIENT:",
                  details: `${data?.ToName} ${data?.ToCompany ? data?.ToCompany : ""} ${data?.ToAddressLine1
                    } ${data?.ToCity}, ${data?.ToState} ${data?.ToZiP}`,
                },
              ]}
            />
          </Grid>
          <VuiTypography variant="h6" gutterBottom>
            DOCUMENT RECORD:
          </VuiTypography>
          <Grid item xs={12} className="removeBorder">
            <Table
              columns={[
                { name: "particulars", align: "left" },
                { name: "details", align: "left" },
              ]}
              rows={[
                {
                  particulars: "CMDI:",
                  details: data?.DocumentHash ? data.DocumentHash : "--",
                },
                {
                  particulars: "Document Name:",
                  details: data?.DocumentFileName ? data.DocumentFileName : "--",
                },
              ]}
            />
          </Grid>
          <VuiTypography variant="h6" gutterBottom>
            CERTIFICATES RECORD:
          </VuiTypography>
          <Grid item xs={12} className="removeBorder">
            <Table
              columns={[
                { name: "particulars", align: "left" },
                { name: "details", align: "left" },
              ]}
              rows={[
                {
                  particulars: "JOB ID :",
                  details: data?.JobHash,
                },
                {
                  particulars: "CASEMAIL Document ID:",
                  details: data?.DocumentHash ? data.DocumentHash : "--",
                },
                {
                  particulars: "CASEMAIL Certificate of Mailing",
                  details: "",
                },
                {
                  particulars: "CASEMAIL Certificate of Delivery",
                  details: "",
                },
              ]}
            />
          </Grid>
          <VuiTypography gutterBottom variant="h6">
            RECORDS STORAGE:
          </VuiTypography>
          <Grid item xs={12} className="removeBorder">
            <Table
              columns={[
                { name: "particulars", align: "left" },
                { name: "details", align: "left" },
              ]}
              rows={[
                {
                  particulars: "Storage Cost:",
                  details: data?.StorageCost,
                },
                {
                  particulars: "Storage Years(s):",
                  details: data?.StorageYears,
                },
              ]}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <ReactToPrint
            trigger={() => <VuiButton autoFocus>
              <PrintIcon style={{ marginRight: 5 }} />
              Print
            </VuiButton>}
            content={() => componentRef.current}
          />
          <VuiButton autoFocus>
            <CloseIcon style={{ marginRight: 5 }} /> Close
          </VuiButton>
          <VuiButton autoFocus onClick={handleClose}>
            <CheckIcon style={{ marginRight: 5 }} /> Ok
          </VuiButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
