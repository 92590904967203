import { useEffect, useState } from "react";
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
// import { useLocation } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setActiveStep } from "redux/Actions/wizard";
import { CircularProgress } from "@mui/material";
import {
  PostVerifyAddress,
  ResetWizard,
  ActPostTopic,
  ActPostJob,
  PostPackageCheckOut,
  PostRecipient,
  PostVerifyAddressSuccess,
} from "redux/Actions/wizard";
import { setLoaderStatus } from "redux/Actions/common";
import validations from "layouts/applications/wizard/schemas/e-notraize-validations";
import form from "layouts/applications/wizard/schemas/e-notraize-form";
import initialValues from "layouts/applications/wizard/schemas/initialValues";
import SelectDocument from "./select_document";
import axios from "axios";
import DocumentDetail from "./document_details";
import { IoMailOpen } from "react-icons/io5";
import { IoMail } from "react-icons/io5";
import { IoPrint } from "react-icons/io5";
import { RefreshProfile } from "redux/Actions/common";


function getStepContent(
  stepIndex,
  documentKey,
  documentHash,
  notraizedDocumentKey,
  notraizedDocumentHash,
  pageCount,
  onPrint2MailAcceptable,
  onTermsAccepted,
  formData
) {
  switch (stepIndex) {
    case 0:
      return <SelectDocument formData={formData} />;
    case 1:
      return <DocumentDetail
        documentKey={documentKey}
        formData={formData}
        documentHash={documentHash}
        pageCount={pageCount}
        notraized={false}
        result={onPrint2MailAcceptable}
        termsAccepted={onTermsAccepted}
      />
    case 2:
      return (
        <DocumentDetail
          documentKey={notraizedDocumentKey}
          formData={formData}
          documentHash={notraizedDocumentHash}
          pageCount={pageCount}
          notraized={true}
          result={onPrint2MailAcceptable}
        />
      );
    default:
      return null;
  }
}

function eNotraizedDocument(props) {
  const { active, loader } = props;
  const { formId, formField } = form;
  const currentValidation = validations[active];
  const [topicHash, setTopicHash] = useState("");
  const [documentKey, setDocumentKey] = useState(null);
  const [documentHash, setDocumentHash] = useState("");
  const search = useLocation().search;

  const [notraizedDocumentKey, setNotraizedDocumentKey] = useState("");
  const [notraizedDocumentHash, setNotraizedDocumentHash] = useState("");
  const [print2MailAcceptable, setPrint2MailAcceptable] = useState(false);


  const [pageCount, setPageCount] = useState(0);

  const [isWizard, setIsWizard] = useState(false);
  const history = useHistory();

  useEffect(() => {
    props.setLoaderStatus(false);
    props.ResetWizard();

    const screen = new URLSearchParams(search).get("k");
    if (screen == 'w') {
      setIsWizard(true);
    }
  }, []);

  const handleBack = () => {
    props.setActiveStep(active - 1);
  };

  const notrizedDocument = () => {
    if (termsAndCondition == false) {
      return;
    }
    props.setLoaderStatus(true);
    var url =
      `${process.env.REACT_APP_API_BASE_URL}/documents/notaries`;
    let payload = {
      ShipperHash: localStorage.ShipperHash,
      DocumentHashList: [documentHash],
    };
    var headers = {
      Authorization: `Bearer ${localStorage.id_token}`,
      ShipperHash: localStorage.ShipperHash,
      "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
    };

    axios
      .post(url, payload, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setNotraizedDocumentHash(res.data.DocumentHash);
          setNotraizedDocumentKey(res.data.Key);
          setPageCount(res.data.PageCount);
          props.setActiveStep(active + 1);
          props?.RefreshProfile();
        }
      })
      .finally(() => {
        props.setLoaderStatus(false);
      });
  };
  const uploadMedia = (documentMedia, data) => {
    var xhr = new XMLHttpRequest();
    xhr.open("PUT", data.Url, true);
    xhr.setRequestHeader("Content-Type", documentMedia.type);
    xhr.onload = () => {
      props.setLoaderStatus(false);
      props.setActiveStep(active + 1);
    };
    xhr.onerror = () => {
      props.setLoaderStatus(false);
      // error...
    };
    xhr.send(documentMedia);
  };

  const createUploadUrl = (values) => {
    props.setLoaderStatus(true);
    var data = JSON.stringify({
      ShipperHash: localStorage.ShipperHash,
      FileName: values.documentFileName,
      DocumentName: values.documentName,
      Tags: values.documentTag,
      Description: values.documentDescription,
      Category: values?.documentCategory
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/documents`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.id_token}`,
        ShipperHash: localStorage.ShipperHash,
        "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setDocumentKey(response.data.Key);
          setDocumentHash(response.data.DocumentHash);
          uploadMedia(values.documentMedia, response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        props.setLoaderStatus(false);
      });
  };

  const handleSubmit = (values, actions) => {
    if (active === 0) {
      createUploadUrl(values);
    } else if (active === 1 && notraizedDocumentHash != null) {
      notrizedDocument();
    } else {
      // props.setActiveStep(active + 1);
      // actions.setTouched({});
      // actions.setSubmitting(false);
      history.push('/documents/list');
    }
  };

  const onPrint2MailAcceptable = (flag) => {
    setPrint2MailAcceptable(flag);
  }
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const onTermsAccepted = (value) => {
    setTermsAndCondition(value);
  }

  return (
    <>
      <VuiBox pt={3} pb={10} style={{ width: "80%", margin: "0px auto" }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12} lg={12}>
            <VuiBox mt={1} mb={4} textAlign="center">
              <VuiBox mb={1}>
                <VuiTypography variant="h3" fontWeight="bold" color="dark">
                  Timestamp Your Document
                </VuiTypography>
              </VuiBox>
            </VuiBox>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={(values, actions) => {
                setTopicHash(values?.topicHash || topicHash);
                handleSubmit(values, actions);
              }}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError,
              }) => (
                <Form id={formId} autoComplete="off" onKeyDown={(keyEvent) => {
                  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    keyEvent.preventDefault();
                  }
                }}>
                  <Card>
                    <VuiBox p={2}>
                      <VuiBox>
                        {getStepContent(
                          active,
                          documentKey,
                          documentHash,
                          notraizedDocumentKey,
                          notraizedDocumentHash,
                          pageCount,
                          onPrint2MailAcceptable,
                          onTermsAccepted,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                            setFieldTouched,
                            setFieldError,
                          }
                        )}
                        {
                          notraizedDocumentHash == "" ?
                            <VuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                              {active === 0 ? (
                                <VuiBox />
                              ) : (
                                <VuiButton variant="gradient" color="light" onClick={handleBack}>
                                  Back
                                </VuiButton>
                              )}
                              <div style={{ display: "flex" }}>
                                {active == 2 ? (
                                  <div>
                                  </div>
                                ) : null}

                                {/* {
                                  props?.Profile?.AvailableBalance < 5 ?
                                    <span style={{ color: 'red' }}> Your wallet balance is LOW. Please add credit to continue. </span> : null
                                } */}

                                <VuiButton
                                  variant="contained"
                                  color="info"
                                  type="submit"
                                  style={{ marginLeft: 8 }}
                                  disabled={loader}
                                >
                                  {active == 0 ? "Next" : active == 1 ? "TIMESTAMP NOW" : "Close"}
                                  {loader ? (
                                    <CircularProgress
                                      color={"error"}
                                      size={16}
                                      style={{ marginLeft: 5 }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </VuiButton>
                              </div>
                            </VuiBox> :
                            isWizard == true ?
                              <VuiBox mt={5} width="100%" display="flex" justifyContent="space-between" className="Suman">

                                <Grid container spacing={2} justifyContent="center">
                                  <Grid item xs={12}>
                                    <VuiTypography variant="h3" color="dark" textAlign="center" fontWeight="bold">
                                      CHOOSE DELIVERY METHOD
                                    </VuiTypography>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <Card
                                      sx={{
                                        overflow: "visible",
                                        height: "200px",
                                        width: "200px",
                                        backgroundColor: print2MailAcceptable ? "#2157a4" : "#444",
                                        cursor: print2MailAcceptable ? 'pointer' : 'not-allowed',
                                        margin: '0px auto'
                                      }} onClick={() => {
                                        if (print2MailAcceptable) {
                                          history.push(`/send/print-to-mail?k=${notraizedDocumentKey}&h=${notraizedDocumentHash}&p=${pageCount}`);
                                        }
                                      }}>
                                      <VuiBox textAlign="center" mt={4.3}>
                                        <IoMailOpen size="54px" color="white" />
                                        <VuiTypography variant="h6" color="light" textAlign="center">
                                          MAIL ONLINE
                                        </VuiTypography>
                                      </VuiBox>
                                    </Card>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <Card
                                      style={{
                                        backgroundColor: "#2157a4",
                                        height: "200px",
                                        width: "200px",
                                        cursor: "pointer",
                                        margin: '0px auto'
                                      }}
                                      onClick={() => {
                                        history.push("/send/e-postage", {
                                          step: 0,
                                          Id: notraizedDocumentHash,
                                          pageCount: pageCount
                                        });
                                        // history.push(`/send/document?k=${notraizedDocumentKey}&h=${notraizedDocumentHash}&p=${pageCount}`);
                                        // props.onSelectPackageType("mail_online");
                                      }}
                                    >
                                      <VuiBox textAlign="center" mt={4.3}>
                                        <IoPrint size="54px" color="white" />
                                        <VuiTypography variant="h6" color="light">
                                          PRINT ePOSTAGE
                                        </VuiTypography>
                                      </VuiBox>
                                    </Card>
                                  </Grid>
                                  {/* <Grid item xs={12} md={4}>
                                    <Card style={{ backgroundColor: "#2157a4", height: "200px", width: "200px", margin: '0px auto' }} onClick={() => {
                                      history.push('/send/print-to-mail?k=1&h=2&p3');
                                    }}>
                                      <VuiBox textAlign="center" mt={4.3}>
                                        <IoMail size="54px" color="white" />
                                        <VuiTypography variant="h6" color="light">
                                          EMAIL
                                        </VuiTypography>
                                      </VuiBox>
                                    </Card>
                                  </Grid> */}
                                </Grid>
                              </VuiBox> :
                              <VuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                <span></span>
                                <div style={{ display: "flex" }}><VuiButton
                                  variant="contained"
                                  color="info"
                                  type="submit"
                                  style={{ marginLeft: 8 }}
                                  disabled={loader}
                                >Close</VuiButton></div></VuiBox>
                        }
                      </VuiBox>
                    </VuiBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </VuiBox>
    </>
  );
}

export default connect(
  (state) => ({
    Profile: state.CommonStore.Profile,
    active: state.WizardStore.activeStep,
    senderInfoData: state.WizardStore.sender_info,
    isAddressVerify: state.WizardStore.is_address_verify,
    AddressVerifyRes: state.WizardStore.address_verify_res,
    loader: state.CommonStore.loader,
    recipientInfo: state.WizardStore.recipient_info,
    packageType: state.WizardStore.packageType,
    SelectedTopic: state.WizardStore.SelectedTopic,
    TopicSuccess: state.WizardStore.TopicSuccess,
    JobResponseSuccess: state.WizardStore.JobResponseSuccess,
    PackageCheckOutRes: state.WizardStore.PackageCheckOutRes,
    PackageCheckOutSuccess: state.WizardStore.PackageCheckOutSuccess,
    RecipientSuccess: state.WizardStore.RecipientSuccess,
    SelectedPackagePricingData: state.WizardStore.SelectedPackagePricingData,
    RecordManagement: state.WizardStore.RecordManagement,
    CertificateOfMailing: state.WizardStore.CertificateOfMailing,

    TopicError: state.WizardStore.TopicError,
    RecipientError: state.WizardStore.RecipientError,
    JobResponseError: state.WizardStore.JobResponseError,
    PackageCheckOutError: state.WizardStore.PackageCheckOutError,
    JobResponse: state.WizardStore.JobResponse,
    packageData: state.WizardStore.packageData,
  }),
  {
    setActiveStep,
    PostVerifyAddress,
    ResetWizard,
    ActPostTopic,
    ActPostJob,
    PostPackageCheckOut,
    PostRecipient,
    setLoaderStatus,
    PostVerifyAddressSuccess,
    RefreshProfile
  }
)(eNotraizedDocument);
