import { CognitoUser, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { useState } from 'react';
import UserPool from '../../userPool';
import SignUpForm from './sign-up-form';
import SignUpVerifyForm from './verify-email-form';
import { useHistory } from "react-router-dom";
import { GetProfile, setProfileDataRes, setLoaderStatus } from "redux/Actions/common";
import { connect } from "react-redux";

function RegisterCognito(props) {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [cognitoError, setCognitoError] = useState('');


    const [verifyProcess, setVerifyProcess] = useState(false);
    const history = useHistory();

    const onSubmit = (email, phone, _password) => {
        if (loading) {
            return;
        }

        const attributeList = [];
        attributeList.push(
            new CognitoUserAttribute({
                Name: 'email',
                Value: email,
            })
        );
        attributeList.push(
            new CognitoUserAttribute({
                Name: 'phone_number',
                Value: phone,
            })
        );
        setUsername(email);
        setPassword(_password)
        setLoading(true);
        setCognitoError('');
        UserPool.signUp(email, _password, attributeList, null, (err, data) => {
            setLoading(false);
            if (err) {
                setCognitoError(err.message);
                console.log(err);
            } else {
                console.log(data);
                setVerifyProcess(true);
            }
        });
    };

    const onResendCode = (e) => {
        if (loading) {
            return;
        }
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });
        setLoading(true);
        user.resendConfirmationCode(function (err, result) {
            setLoading(false);
            if (err) {
                setCognitoError(err.message);
            }
            console.log('call result: ' + result);
        });
    }

    const verifyAccount = (OTP) => {
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });
        setLoading(true);
        setCognitoError('');
        user.confirmRegistration(OTP, true, (err, data) => {
            setLoading(false);
            if (err) {
                console.log(err);
                setCognitoError(err.message);
            } else {
                authenticateUser();
            }
        });
    };


    const authenticateUser = () => {
        setLoading(true);
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        });

        user.authenticateUser(authDetails, {
            onSuccess: (result) => {
                localStorage.setItem("username", username);
                localStorage.setItem("id_token", result.idToken.jwtToken);
                localStorage.setItem("access_token", result.accessToken.jwtToken);
                localStorage.setItem("token_type", 'Bearer');
                localStorage.setItem("token_exp", result.accessToken.payload.exp);
                console.log('login success', result);
                // setLoading(false);

                user.getUserAttributes(function (err, result) {
                    if (result != null && result != undefined) {
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() == 'phone_number') {
                                localStorage.setItem('phone_number', result[i].getValue());
                            }
                        }
                    }
                    props?.GetProfile().then(res => {
                        setLoading(false);
                        if (res.status == 200) {
                            props?.setProfileDataRes(res.data[0]);
                            if (res.data[0]?.PersonName != null && res.data[0]?.PersonName != undefined) {
                                localStorage.setItem("PersonName", res.data[0]?.PersonName);
                            }
                            if (res.data[0]?.ShipperHash != null && res.data[0]?.ShipperHash != undefined) {
                                localStorage.setItem("ShipperHash", res.data[0].ShipperHash);
                            }
                            history.push('prepare-session?code=login');
                        }
                        else {
                            history.push('prepare-session?code=login');
                        }
                    });

                    // history.push('prepare-session?code=login');
                });

            },
            onFailure: (err) => {
                setLoading(false);
                if (err.code == 'UserNotConfirmedException') {
                    setVerifyProcess(true);
                    onResendCode(null);
                }
            },
            newPasswordRequired: (data) => {
                setLoading(false);
                console.log('new password required', data);
            },
        });
    };

    return (
        <div>
            {verifyProcess == false ? (
                <SignUpForm onSubmit={onSubmit} loading={loading} cognitoError={cognitoError} />
            ) : (
                <SignUpVerifyForm email={username} onSubmit={verifyAccount} onResendCode={onResendCode} loading={loading} cognitoError={cognitoError} />
            )}
        </div>
    );
}

// export default RegisterCognito;
export default connect(
    (state) => ({
        Profile: state.CommonStore.Profile,
    }),
    {
        GetProfile,
        setProfileDataRes,
        setLoaderStatus
    }
)(RegisterCognito);