import { SET_POST_TRACK, SET_POST_TRACK_ERROR, SET_POST_TRACK_SUCCESS } from "../Type";
import { PURGE } from "redux-persist";
const initialState = {
  trackingRes: [],
  TrackingSuccess: true,
  TrackingError: false,
};

const TrackingWizardStore = (state = initialState, { type, data, status }) => {
  switch (type) {
    case PURGE:
      return {
        ...state,
        trackingRes: [],
        TrackingSuccess: true,
        TrackingError: false,
      };
    case SET_POST_TRACK:
      return {
        trackingRes: data,
      };
    case SET_POST_TRACK_SUCCESS:
      return {
        TrackingSuccess: status,
      };
    case SET_POST_TRACK_ERROR:
      return {
        TrackingError: status,
      };
    default:
      return state;
  }
};

export default TrackingWizardStore;
