import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import PaletteIcon from "@mui/icons-material/Palette";
import PrintIcon from "@mui/icons-material/Print";
import DraftsIcon from "@mui/icons-material/Drafts";


function PostageSettings(props) {
  const {
    formField,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = props.formData;

  const [returnEnvelope, setReturnEnvelope] = useState(false);
  const [colorType, setColorType] = useState("BW");
  const [printType, setPrintType] = useState("OS");

  useEffect(() => {
    if (
      values["colorType"] != null &&
      values["colorType"] != "" &&
      values["colorType"] != undefined
    ) {
      setReturnEnvelope(values["returnEnvelope"]);
      setColorType(values["colorType"]);
      setPrintType(values["printType"]);
    } else {
      setFieldValue("returnEnvelope", false);
      setFieldValue("printType", "OS");
      setFieldValue("colorType", "BW");
    }
  }, []);

  const handleReturnEnvelopeChange = (e) => {
    setReturnEnvelope(e.target.checked);
    setFieldValue("returnEnvelope", e.target.checked);
  };

  const handlePrintTypeChange = (e) => {
    setPrintType(e.target.value);
    setFieldValue("printType", e.target.value);
  };

  const handleColorTypeChange = (e) => {
    setColorType(e.target.value);
    setFieldValue("colorType", e.target.value);
  };

  const customButtonStyles = ({
    functions: { pxToRem },
    borders: { borderWidth },
    palette: { transparent, info },
  }) => ({
    width: "100%",
    height: "250px",
    alignItems: "center",
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${info.main}`,
    },

    "&:hover": {
      // backgroundColor: `${transparent.main} !important`,
      border: `${borderWidth[2]} solid ${info.main} !important`,
    },
  });

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} md={4}>
        <Card sx={customButtonStyles}>
          <PaletteIcon color="info" style={{ width: "4rem", height: "4rem" }} />
          <FormControl>
            <FormLabel style={{ fontWeight: 600 }} color="info">
              Color Type
            </FormLabel>
            <RadioGroup value={colorType} onChange={handleColorTypeChange}>
              <FormControlLabel value="BW" control={<Radio />} label="Black & White" />
              <FormControlLabel value="C" control={<Radio />} label="Color" />
            </RadioGroup>
          </FormControl>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sx={customButtonStyles} color="info">
          <FormControl>
            <PrintIcon color="info" style={{ width: "4rem", height: "4rem", margin: "0 auto" }} />
            <FormLabel style={{ fontWeight: 600 }} color="info">
              Print Type
            </FormLabel>
            <RadioGroup value={printType} onChange={handlePrintTypeChange}>
              <FormControlLabel value="OS" control={<Radio />} label="One Sided" />
              <FormControlLabel value="TS" control={<Radio />} label="Two Sided" />
            </RadioGroup>
          </FormControl>
        </Card>
      </Grid>
      {/* <Grid item xs={12} md={4} pl={3}>
        <Card sx={customButtonStyles}>
          <DraftsIcon color="info" style={{ width: "4rem", height: "4rem" }} />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={returnEnvelope} onChange={handleReturnEnvelopeChange} />}
              label="Attach Return Envelope"
            />
          </FormGroup>
        </Card>
      </Grid> */}
    </Grid>
  );
}

export default PostageSettings;
