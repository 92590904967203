// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background-cover-auth-signin.png";
import RegisterCognito from "../../../../components/CognitoUI/sign-up";

function SignIn() {
  return (
    <CoverLayout
      title="Nice to see you!"
      color="dark"
      // description="Enter your email and password to sign in"
      description=""
      image={bgImage}
      premotto={""}
      motto={"Digital Postal Service"}
      header={<span></span>}
      top={15}
    >
      <div>
        <RegisterCognito />
      </div>
    </CoverLayout>
  );
}

export default SignIn;
