

export default {
  formId: "contact-form",
  formField: {
    companyName: {
      name: "companyName",
      label: "Company Name",
      type: "text",
      placeholder: "Company Name",
    },
    address1: {
      name: "address1",
      label: "Address 1",
      type: "text",
      placeholder: "Address 1",
      errorMsg: "Address is required.",
    },
    address2: {
      name: "address2",
      label: "Address 2",
      type: "text",
      placeholder: "Address 2",
    },
    city: {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "City",
      errorMsg: "City is required.",
    },
    state: {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "State",
      errorMsg: "State is required.",
    },
    zip: {
      name: "zip",
      label: "Zip",
      type: "text",
      placeholder: "zip",
      errorMsg: "Zip is required.",
      invalidMsg: "Invalid Zipcode",
    },
    recipientName: {
      name: "recipientName",
      label: "Recipient Name",
      type: "text",
      placeholder: "Recipient Name",
      errorMsg: "Name is required.",
    },
    recWallet: {
      name: "recWallet",
      label: "Blockchain Wallet Address",
      type: "text",
      placeholder: "Blockchain Wallet Address",
      errorMsg: "Blockchain Wallet Address is required.",
    },
  },
};
