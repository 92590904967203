

// @mui material components
import Grid from "@mui/material/Grid";
import { Field } from "formik";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Wizard application components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useEffect, useState } from "react";

// @mui material components
import { connect } from "react-redux";
import {
  setSenderInfo,
  setIsAddressVerify,
  GetActSenderInfo,
  PostVerifyAddressSuccess,
  UpdateLoadSenderInfo
} from "redux/Actions/wizard";
import { GetState } from "api/StateGet";
import VuiSelect from "components/VuiSelect";
import ContactMailIcon from '@mui/icons-material/ContactMail';

function SenderInfo(props) {
  const { formField, values, errors, touched } = props.formData;
  const { senderName, companyName, address1, address2, city, state, zip } = formField;
  const {
    senderName: senderNameV,
    companyName: companyNameV,
    address1: addressV,
    address2: address2V,
    city: cityV,
    state: stateV,
    zip: zipV
  } = values;

  const Options = GetState();
  return (
    <VuiBox mt={2}>
      {!props.checkView ?
        <VuiBox mt={0} p={2} shadow="lg" bgColor="white" style={{ border: '2px dashed dodgerblue' }}>

          <Grid container spacing={2} >
            <Grid item xs={12} md={3}>
              <ContactMailIcon style={{ width: '3em', height: '3em' }} />
            </Grid>
            <Grid item xs={12} md={9}>
              <VuiBox>
                <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                  {values?.senderName}
                </VuiTypography>
              </VuiBox>
              <VuiBox>
                <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                  {values?.companyName}
                </VuiTypography>
              </VuiBox>
              <VuiBox>
                <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                  {values?.address1}
                </VuiTypography>
              </VuiBox>
              <VuiBox>
                <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                  {values.address2}
                </VuiTypography>
              </VuiBox>
              <VuiBox>
                <VuiTypography fontSize={15} fontWeight="regular" color="dark">
                  {(values?.city, values?.state, values?.zip)}
                </VuiTypography>
              </VuiBox>
            </Grid>
          </Grid>
        </VuiBox>
        :
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormField
              label={senderName.label}
              name={senderName.name}
              type={senderName.type}
              value={senderNameV}
              placeholder={senderName.placeholder}
              error={errors.senderName && touched.senderName}
              success={senderNameV.length > 0 && !errors.senderName}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              label={companyName.label}
              name={companyName.name}
              type={companyName.type}
              value={companyNameV}
              placeholder={companyName.placeholder}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label={address1.label}
              name={address1.name}
              type={address1.type}
              value={addressV}
              placeholder={address1.placeholder}
              error={errors.address1 && touched.address1}
              success={addressV.length > 0 && !errors.address1}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label={address2.label}
              name={address2.name}
              type={address2.type}
              value={address2V}
              placeholder={address2.placeholder}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label={city.label}
              name={city.name}
              type={city.type}
              value={cityV}
              placeholder={city.placeholder}
              error={errors.city && touched.city}
              success={cityV.length > 0 && !errors.city}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <VuiBox mb={1.5}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography
                  component="label"
                  variant="caption"
                  color="white"
                  fontWeight="bold"
                  textTransform="capitalize"
                  style={{ color: 'black' }}
                >
                  {state.label}
                </VuiTypography>
              </VuiBox>
              <Field name={state.name}>
                {({ field, form }) => (
                  <VuiSelect
                    menuPlacement="top"
                    type="text"
                    name={state.name}
                    value={Options.find((obj) => obj.value === stateV)}
                    label={state.label}
                    options={Options}
                    onChange={(option) => {
                      form.setFieldValue(
                        'state',
                        option.value,
                      )
                    }}
                    placeholder="Select State"
                    success={stateV.length > 0 && !errors.state}
                  />
                )}
              </Field>
              <VuiBox mt={0.75}>
                <VuiTypography component="div" variant="caption" color="error">
                  {touched.state && <div className="input-feedback">{errors.state}</div>}
                </VuiTypography>
              </VuiBox>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label={zip.label}
              name={zip.name}
              type={zip.type}
              value={zipV}
              placeholder={zip.placeholder}
              error={errors.zip && touched.zip}
              success={zipV.length > 0 && !errors.zip}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
        </Grid>
      }
    </VuiBox>
  );
}

export default connect(
  (state) => ({
    senderInfoData: state.WizardStore.sender_info,
    isAddressVerify: state.WizardStore.is_address_verify,
    ShipperInfo: state.WizardStore.ShipperInfo,
    ShipperInfoSuccess: state.WizardStore.ShipperInfoSuccess,
    AddressVerifySuccess: state.WizardStore.address_verify_success,
    LoadSenderInfo: state.WizardStore.loadSenderInfo
  }),
  {
    setIsAddressVerify,
    setSenderInfo,
    GetActSenderInfo,
    PostVerifyAddressSuccess,
    UpdateLoadSenderInfo
  }
)(SenderInfo);
