import { CognitoUserPool } from 'amazon-cognito-identity-js';
// const dotenv = require("dotenv");

// dotenv.config({ path: ".env" });
// console.log('process.env.REACT_APP_API_KEY > ', process.env.REACT_APP_API_KEY);

const poolData = {
    UserPoolId: process.env.REACT_APP_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID,
};

console.log('poolData > ', poolData);


export default new CognitoUserPool(poolData);
