

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useHistory } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";


import VuiBox from "components/VuiBox";
import Box from '@mui/material/Box';
import VuiTypography from "components/VuiTypography";


import Breadcrumbs from "examples/Breadcrumbs";
import DefaultItem from "examples/Items/DefaultItem";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';


// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// DPS UI  React context
import {
  useVisionUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images

import { IoPerson, IoLogOutOutline, IoDocumentSharp, IoLogoUsd } from "react-icons/io5";

function DashboardNavbar({ absolute, light, isMini, Profile }) {
  const history = useHistory();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleSignOut = () => {
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("persist:root");
    localStorage.clear();
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_COGNITO_URL}/logout?client_id=${process.env.REACT_APP_CLIENT_ID}&logout_uri=https://www.dps.io`;
    }, 500);
    
    // const logout_uri = "https://www.dps.io/";
    // history.push('logout_uri');
    // setTimeout(() => {
    //   window.location.href = logout_uri;
    // }, 1000);
  };
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <DefaultItem
        icon={<IoPerson size="18px" color="dark" />}
        title={"Profile"}
        onClick={() => {
          history.push("/profile");
        }}
      />
      <DefaultItem
        icon={<AccessTimeFilledIcon size="18px" />}
        title={"Manage Your Subscription"}
        onClick={() => {
          history.push("/manage-subscription");
        }}
      />
      <DefaultItem
        icon={<IoLogoUsd size="18px" color="dark" />}
        title={"Transaction History"}
        onClick={() => {
          history.push("/transaction-history");
        }}
      />

      <DefaultItem
        icon={<IoLogoUsd size="18px" color="dark" />}
        title={"Credits History"}
        onClick={() => {
          history.push("/credits-history");
        }}
      />

      <DefaultItem
        icon={<IoLogOutOutline size="18px" color="dark" />}
        title={"Logout"}
        onClick={handleSignOut}
      />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <VuiBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </VuiBox>

        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <VuiBox
              color={light ? "white" : "inherit"}
              style={{ display: "flex", alignItems: "center" }}
            >
              {
                Profile?.TotalQueuedJobs > 0 ? (


                  <Box display={{ xs: "none", xl: "block", md: "block" }}>
                    <Link to="/dispatch-queue">
                      <VuiTypography
                        variant="button"
                        fontWeight="medium"
                        color="dark"
                        style={{
                          textTransform: "capitalize",
                          background: "#2c56a5",
                          display: "flex",
                          alignItems: "center",
                          color: "#fff",
                          padding: "8px 10px",
                          borderRadius: 12,
                          marginRight: 5,
                        }}
                      >
                        <ScheduleSendIcon style={{ width: "1.2rem", height: "1.2rem" }} />
                        &nbsp;&nbsp; Dispatch Queue
                      </VuiTypography>
                    </Link>
                  </Box>
                ) : null
              }
              <Box display={{ xs: "block", xl: "block", md: "block" }}>
                <Link to="/credits-history">
                  <VuiTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    style={{
                      textTransform: "capitalize",
                      background:
                        Profile?.AvailableBalance < 5
                          ? "red"
                          : Profile?.AvailableBalance < 30
                            ? "orange"
                            : "#02b574",
                      display: "flex",
                      alignItems: "center",
                      color: "#fff",
                      padding: "8px 10px",
                      borderRadius: 12,
                      marginRight: 5,
                    }}
                  >
                    <AccountBalanceWalletIcon style={{ width: "1.2rem", height: "1.2rem" }} />
                    &nbsp;&nbsp; Available Credits: $
                    {Profile?.AvailableBalance ? Profile?.AvailableBalance : 0}
                  </VuiTypography>
                </Link>
              </Box>
              <IconButton
                size="small"
                color="dark"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon>account_circle</Icon>
                <VuiTypography
                  variant="button"
                  fontWeight="medium"
                  color="dark"
                  style={{ textTransform: "capitalize" }}
                >
                  {
                    Profile?.PersonName
                  }
                  {/* {localStorage.PersonName
                    ? localStorage.PersonName.split(" ")[0]
                    : localStorage.username
                      ? `${localStorage.username.substring(0, 6)}`
                      : "Sign in"} */}
                </VuiTypography>
              </IconButton>
              {
                renderMenu()
              }

              <IconButton
                size="small"
                color="dark"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon color="white">{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>
            </VuiBox>
          </VuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
