import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import dpslogo from "../../assets/images/dps-logo.png";
import { useHistory } from "react-router-dom";
import ProfileEdit from "layouts/pages/profile/profile-edit";
import ManageSubscription from "./manage-subscription";
import VerifyButton from "../../components/Stripe/Varification";
import { connect } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { FaStripe } from 'react-icons/fa';
import { GetProfile, setProfileData, setProfileDataRes, setLoaderStatus } from "redux/Actions/common";


function OnBoarding(props) {
  const history = useHistory();
  const { GetProfile, setProfileData, setProfileDataRes, setLoaderStatus } = props;
  const [Profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);
  const [onboardStatus, setOnboardStatus] = useState({
    profile_completed: false,
    subscription_completed: false,
    identity_verified: false,
    status: false
  });

  const [checkingProfile, setCheckingProfile] = useState(false);
  const [checkingSubscription, setCheckingSubscription] = useState(false);

  useEffect(async () => {
    setCheckingProfile(true);
    setCheckingSubscription(true);
    recallAPI(setCheckingProfile);

    if (window.location.search == '?status=success') {
      setLoading(true);
      const timer = setInterval(() => {
        if (props?.Profile?.SubscriptionInfo == null || props?.Profile?.SubscriptionInfo?.SubscriptionId == null) {
          recallAPI(setLoading);
        }
        else {
          if (timer) {
            clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
          }
          setLoading(false);
        }
      }, 2000);
      return () => {
        if (timer) {
          clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
        }
      };
    }

  }, [])

  const onBoardingCompleted = (profileData) => {
    let data = {
      profile_completed: false,
      subscription_completed: false,
      identity_verified: false,
      status: false
    };

    if (profileData != null && profileData != undefined) {
      data.profile_completed = true;
      data.identity_verified = profileData?.IdentityVerified;
    }

    if (profileData?.SubscriptionInfo?.IsActive == true) {
      data.subscription_completed = true;
    }
    if (data.profile_completed == true && data.subscription_completed == true && data.identity_verified == true) {
      data.status = true;
    }
    setOnboardStatus(data);
    // if (data.status == true) {
    //   history.push("/dashboard");
    // }
  }
  // 

  const recallAPI = (state) => {
    state(true);

    GetProfile().then((res) => {
      if (res.status === 200) {
        setProfile(res.data[0]);
        onBoardingCompleted(res.data[0]);
        setProfileData(res.data[0]);
        setProfileDataRes(res.data[0]);
      }
    }).catch((err) => {
      setProfile(null);
      setTimeout(() => {
        // window.location.reload();
        onBoardingCompleted(null);
      }, 1000);
    }).finally(() => {
      setLoaderStatus(false)
      state(false);
      setCheckingSubscription(false);
    });
  }

  const handleProfileDialogClose = () => {
    setOpenProfileDialog(false);
    setCheckingProfile(true);
    recallAPI(setCheckingProfile);
  };

  const handleSubscriptionDialogClose = () => {
    setOpenSubscriptionDialog(false);
    setCheckingSubscription(true);
    setTimeout(() => {
      recallAPI(setCheckingSubscription);
    }, 2000);
  };


  return (
    <Box sx={{ flexGrow: 1 }} pt={8} pb={10} width="100%" style={{ margin: '0 auto', maxWidth: '650px' }}>
      {
        loading ? (
          <div className='dps-loader'>
            <CircularProgress />
            <h5>Loading...</h5>
          </div>
        ) : null
      }
      <Dialog
        open={openProfileDialog}
        maxWidth={"md"}
        onClose={handleProfileDialogClose}
        aria-labelledby="profile-dialog"
        aria-describedby="profile-dialog"
      >
        <DialogTitle id="alert-dialog-title">
          {"Complete Your Profile"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="profile-dialog">
            <ProfileEdit isPopup={true} />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSubscriptionDialog}
        fullScreen
        onClose={handleSubscriptionDialogClose}
        aria-labelledby="subscription-dialog"
        aria-describedby="subscription-dialog"
      >
        {/* <DialogTitle id="subscription-dialog-title" style={{ textAlign: 'center' }}>
          {"Setup Your Subscription"}
        </DialogTitle> */}
        <DialogContent>
          <VuiButton variant="contained" style={{ position: 'absolute', top: 50, right: 50, zIndex: 10000 }} color="error" onClick={handleSubscriptionDialogClose}>Close</VuiButton>
          {/* <DialogContentText id="subscription-dialog-title_text">
            
          </DialogContentText> */}
          <ManageSubscription isPopup={false} onClose={handleSubscriptionDialogClose} Profile={Profile}
            successUrl={`${window.location.origin}/onboarding?status=success`}
            cancelUrl={`${window.location.origin}/onboarding?status=failed`}
          />
        </DialogContent>
      </Dialog>

      <Card>
        <Grid justifyContent="center" container>
          <img src={dpslogo} alt="dps logo" style={{ width: '150px', height: '130px' }} />
        </Grid>

        <Grid container spacing={1} alignItems="center" justifyContent="space-between">

          <Grid item md={9}>
            <VuiTypography variant="body2">Step 1</VuiTypography>
            <VuiTypography variant="h5">Complete Your profile</VuiTypography>
          </Grid>
          <Grid item style={{ marginRight: 10 }}>
            {
              onboardStatus?.profile_completed ? (
                <VuiButton variant="contained" color="success">Completed</VuiButton>
              ) : (
                <VuiButton variant="contained" color="primary" onClick={() => {
                  setOpenProfileDialog(true);
                }}>
                  {
                    checkingProfile ? <CircularProgress size={16} color="error" /> : "Start"
                  }
                </VuiButton>
              )
            }


          </Grid>
        </Grid>

        <Grid container spacing={1} mt={2} alignItems="center" justifyContent="space-between">
          <Grid item md={9}>
            <VuiTypography variant="body2">Step 2</VuiTypography>
            <VuiTypography variant="h5">Complete Your Subscription</VuiTypography>
          </Grid>
          <Grid item style={{ marginRight: 10 }}>
            {
              onboardStatus?.subscription_completed ? (
                <VuiButton variant="contained" color="success">Completed</VuiButton>
              ) : (<VuiButton variant="contained" color="primary" onClick={() => {
                setOpenSubscriptionDialog(true);
              }}
                disabled={onboardStatus?.profile_completed == false}
              >

                {
                  checkingSubscription ? <CircularProgress size={16} color="error" /> : "Start"
                }
              </VuiButton>)
            }

          </Grid>
        </Grid>


        <Grid container spacing={1} mt={2} alignItems="center" justifyContent="space-between">
          <Grid item md={9}>
            <VuiTypography variant="body2">Step 3</VuiTypography>
            <VuiTypography variant="h5">Verify Your Identity</VuiTypography>
            <VuiTypography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
              Powered by &nbsp;<FaStripe size={40} color={"#635bff"} />.com&nbsp;&nbsp;<a href="https://stripe.com/en-in/legal/identity" target={"_blank"} style={{ color: "#635bff", fontWeight: 500 }}>Privacy Policy</a>
            </VuiTypography>
          </Grid>
          <Grid item style={{ marginRight: 10 }}>
            {
              onboardStatus?.identity_verified ? (
                <VuiButton variant="contained" color="success">Completed</VuiButton>
              ) : (
                <VerifyButton type="button" color={"primary"} title="Start" loading={checkingProfile} disabled={onboardStatus?.profile_completed == false || onboardStatus?.subscription_completed == false} />
              )
            }
          </Grid>
        </Grid>

        <Grid container spacing={1} justifyContent="center" mt={5}>
          <Grid>
            <VuiButton variant="contained" color="dark" disabled={onboardStatus.status == false}
              onClick={() => {
                history.push("/dashboard");
              }}
            >Get Started</VuiButton>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

// export default OnBoarding;

export default connect(
  (state) => ({
    Profile: state.CommonStore.Profile,
  }),
  {
    GetProfile,
    setProfileData,
    setProfileDataRes,
    setLoaderStatus
  }
)(OnBoarding);
