import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import VuiButton from "components/VuiButton";
import DataTable from "examples/Tables/DataTable";
import axios from "axios";
import moment from "moment";
import Spin from "components/Spin";

import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useHistory } from "react-router-dom";
import Row from './row';
import RefreshIcon from '@mui/icons-material/Refresh';
import Pagination from '@mui/material/Pagination';

function DispatchQueue(props) {
    const [value, setValue] = React.useState(0);
    const [dispatchQueue, setDispatchQueue] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getDispatchQueue();

        // const timer = setInterval(() => {
        //     getDispatchQueue();
        // }, 5000);
        // return () => {
        //     clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
        // };

    }, []);

    const getDispatchQueue = () => {
        setLoading(true);
        let url = `${process.env.REACT_APP_API_BASE_URL}/customers/queued-jobs?ShipperHash=${localStorage.ShipperHash}`;
        var headers = {
            Authorization: `Bearer ${localStorage.id_token}`,
            ShipperHash: localStorage.ShipperHash,
            "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
        };

        axios
            .get(url, {
                headers: headers,
            })
            .then((res) => {
                if (res.status === 200) {
                    res?.data?.map(item => {
                        item.CreatedDate = moment(item.CreatedDate).format("MMM DD, YYYY hh:mm A");
                    })

                    setDispatchQueue(res.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Spin tip="loading..." spinning={loading}>
            <Paper>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Dispatch Queue
                                <IconButton color="primary" aria-label="refresh page" component="label" onClick={getDispatchQueue}>
                                    <RefreshIcon />
                                </IconButton>
                            </Typography>

                        </Toolbar>
                    </AppBar>
                </Box>

                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>To Person</TableCell>
                                <TableCell>Job Hash</TableCell>
                                <TableCell>Retry Count</TableCell>
                                <TableCell>Last Action</TableCell>
                                <TableCell>Order Time</TableCell>
                                <TableCell>Dispatch Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dispatchQueue.map((row, index) => (
                                <Row key={index} row={row} index={index} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Spin>
    );
}

export default DispatchQueue;
