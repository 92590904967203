import { useEffect, useState } from "react";
import VuiButton from "components/VuiButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ProgressLoader from "./Progressloader";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import moment from "moment";
import Loading from "components/Loading/loading";
import Spin from "components/Spin";
import { setActiveStep } from "redux/Actions/wizard";
import { connect } from "react-redux";
function PreviewDailogBox(props) {
  const history = useHistory();
  const {
    topicHash,
    url,
    SelectedTopic,
    TopicSuccess,
    JobResponseSuccess,
    PackageCheckOutSuccess,
    RecipientSuccess,
    TopicError,
    RecipientError,
    JobResponseError,
    PackageCheckOutError,
    loader,
  } = props;

  const [TopicDone, setTopicDone] = useState(false);
  const [JobDone, setJobDone] = useState(false);
  const [RecipientDone, setRecipientDone] = useState(false);
  const [CheckoutDone, setCheckoutDone] = useState(false);
  const [Process, setProcess] = useState(true);
  const [TopicUndone, setTopicUndone] = useState(false);
  const [JobUndone, setJobUndone] = useState(false);
  const [RecipientUndone, setRecipientUndone] = useState(false);
  const [CheckoutUndone, setCheckoutUndone] = useState(false);

  const redirectToSend = () => {
    history.push("/dashboard");
  };

  useEffect(() => {
    if (TopicSuccess) {
      setTopicDone(true);
    } else {
      setTopicDone(false);
    }
    if (JobResponseSuccess) {
      setJobDone(true);
    } else {
      setJobDone(false);
    }
    if (PackageCheckOutSuccess) {
      setCheckoutDone(true);
      setProcess(false);
    } else {
      setCheckoutDone(false);
      setProcess(true);
    }
    if (RecipientSuccess) {
      setRecipientDone(true);
    }
    if (TopicError) {
      setTopicUndone(true);
    }
    if (JobResponseError) {
      setJobUndone(true);
    }
    if (PackageCheckOutError) {
      setCheckoutUndone(true);
      setProcess(false);
    }
    if (RecipientError) {
      setRecipientUndone(true);
    }
  }, [
    TopicSuccess,
    JobResponseSuccess,
    PackageCheckOutSuccess,
    RecipientSuccess,
    TopicError,
    RecipientError,
    JobResponseError,
    PackageCheckOutError,
    topicHash,
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  const cancelAndEdit = () => {
    setTopicDone(false);
    setJobDone(false);
    setRecipientDone(false);
    setCheckoutDone(false);
    props.ResetCheckOut();
    props.toggle();
    if (props.sendType === "document") {
      props.setActiveStep(3);
    } else if (props.sendType === "nft") {
      props.setActiveStep(3);
    } else {
      props.setActiveStep(1);
    }
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.toggle} maxWidth="lg" fullWidth={true}>
        <DialogTitle>{props.modeType === "Live" ? "Live Label Preview" : "Preview"}</DialogTitle>
        <Spin tip="Loading..." spinning={props.loading_page}>
          <DialogContent>
            {Process ? (
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignSelf: "center", alignItems: "center" }}
                >
                  <Typography fontSize="15px" pr={2} color="#344747">
                    Creating Topic
                  </Typography>

                  {TopicDone ? (
                    "Done"
                  ) : TopicUndone ? (
                    <span style={{ color: "red" }}>{TopicError}</span>
                  ) : loader ? (
                    <ProgressLoader />
                  ) : (
                    "Waiting..."
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignSelf: "center", alignItems: "center" }}
                >
                  <Typography fontSize="15px" pr={2} color="#344747">
                    Creating Job
                  </Typography>{" "}
                  {JobDone ? (
                    "Done"
                  ) : JobUndone ? (
                    <span style={{ color: "red" }}>{JobResponseError}</span>
                  ) : loader ? (
                    <ProgressLoader />
                  ) : (
                    "Waiting..."
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignSelf: "center", alignItems: "center" }}
                >
                  <Typography fontSize="15px" pr={2} color="#344747">
                    Submitting Job
                  </Typography>
                  {RecipientDone ? (
                    "Done"
                  ) : RecipientUndone ? (
                    <span style={{ color: "red" }}>{RecipientError}</span>
                  ) : loader ? (
                    <ProgressLoader />
                  ) : (
                    "Waiting..."
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignSelf: "center", alignItems: "center" }}
                >
                  <Typography fontSize="15px" pr={2} color="#344747">
                    Waiting for label Creation{" "}
                  </Typography>
                  {CheckoutDone ? (
                    "Done"
                  ) : CheckoutUndone ? (
                    <span style={{ color: "red" }}>{PackageCheckOutError}</span>
                  ) : loader ? (
                    <ProgressLoader />
                  ) : (
                    "Waiting..."
                  )}
                </Grid>
              </Grid>
            ) : (
              ""
            )}

            {CheckoutDone ? (
              <div className="App">
                <iframe src={url} height="800" width={"100%"} />
              </div>
            ) : (
              ""
            )}
          </DialogContent>
        </Spin>
        <DialogActions>
          {props.modeType === "Live" ? (
            <>
              <VuiButton variant="contained" href={url} target="blank">
                Download
              </VuiButton>
              <VuiButton onClick={redirectToSend} variant="contained" color="info">
                Close
              </VuiButton>
            </>
          ) : (
            <>
              <VuiButton
                variant="contained"
                color="success"
                disabled={!CheckoutDone || props.loading_page}
                onClick={() => props.handlePackageCheckout(props.values, props.jobHash, "Live")}
              >
                Approve & Checkout
              </VuiButton>
              <VuiButton
                onClick={cancelAndEdit}
                disabled={!CheckoutDone || props.loading_page}
                variant="contained"
                color="error"
              >
                Cancel & Edit
              </VuiButton>
              <VuiButton onClick={props.toggle} variant="contained" color="info">
                Close
              </VuiButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(
  (state) => ({
    active: state.WizardStore.activeStep,
  }),
  {
    setActiveStep,
  }
)(PreviewDailogBox);
