

import * as Yup from "yup";
import checkout from "layouts/applications/wizard/schemas/print-to-mail-form";

const {
  formField: {
    documentMedia,
    documentName,
    caseName,
    mailingName,
    city,
    state,
    zip,
    address1,
    senderName,
    recipientName,
    recAddress1,
    recCity,
    recZip,
    recState,
    flatRate,
    packageWeight,
    unit,
    shipDate,
    customLength,
    customWidth,
    customHeight,
  },
} = checkout;

export default [
  Yup.object().shape({
    [caseName.name]: Yup.string().required(caseName.errorMsg),
    [mailingName.name]: Yup.string().required(mailingName.errorMsg),
    [senderName.name]: Yup.string().required(senderName.errorMsg),
    [city.name]: Yup.string().required(city.errorMsg),
    [state.name]: Yup.string().required(state.errorMsg),
    [zip.name]: Yup.string().required(zip.errorMsg).min(5, zip.invalidMsg),
    [address1.name]: Yup.string().required(address1.errorMsg),
  }),
  Yup.object().shape({
    [recipientName.name]: Yup.string().required(recipientName.errorMsg),
    [recCity.name]: Yup.string().required(recCity.errorMsg),
    [recState.name]: Yup.string().required(recState.errorMsg),
    [recZip.name]: Yup.string().required(recZip.errorMsg).min(5, recZip.invalidMsg),
    [recAddress1.name]: Yup.string().required(recAddress1.errorMsg),
  }),
  Yup.object().shape({}),
];
