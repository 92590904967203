import axios from "axios";
import { SUCCESS } from "dropzone";
import jwt_decode from "jwt-decode";

const qs = require("qs");

const GetShipperHash = async (
  // id_token,
  // email,
  Redirect,
  setShiper,
  setProfileStatusCode,
  GetProfile,
  setProfileDataRes,
  callback
) => {

  await GetProfile()
    .then((res) => {
      if (res.status === 200) {
        setProfileDataRes(res.data[0]);
        localStorage.setItem("ShipperHash", res.data[0]?.ShipperHash);
        localStorage.setItem("PersonName", res.data[0]?.PersonName);
        if (
          res.data[0]?.SubscriptionInfo?.IsActive == true
        ) {
          localStorage.setItem("SubscriptionId", res.data[0]?.SubscriptionInfo?.SubscriptionId);
        }

        setShiper({ ShipperHash: res.data[0]?.ShipperHash });
        setProfileStatusCode(res.status);
        if (callback != null) {
          callback();
        }
        if (
          res.data[0]?.SubscriptionInfo?.IsActive == false ||
          res.data[0]?.SubscriptionInfo?.IsActive == undefined
        ) {
          Redirect("/onboarding");
        } else {
          Redirect("/dashboard");
        }

      }
      else {
        if (callback != null) {
          callback();
        }
      }
    })
    .catch((error) => {
      setProfileStatusCode(error.response.status);
      Redirect("/profile/edit");
      console.error(error.message);
    });
};

const getAccessToken = async (
  Redirect,
  setShiper,
  setLogin,
  setProfileStatusCode,
  GetProfile,
  setProfileDataRes,
  callback
) => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const redirect_uri = `${location.origin}/prepare-session`;
  const REACT_APP_COGNITO_URL = process.env.REACT_APP_COGNITO_URL;

  const token_url = `${REACT_APP_COGNITO_URL}/oauth2/token`;

  const data = {
    grant_type: "authorization_code",
    client_id: CLIENT_ID,
    code: code,
    redirect_uri: redirect_uri,
  };
  await axios.post(token_url, qs.stringify(data)).then((response) => {
    if (response.status === 200) {
      const data = response.data;
      Object.keys(data).map((key) => {
        localStorage.setItem(`${key}`, data[key]);
      });
      var decoded = jwt_decode(data.id_token);
      localStorage.setItem("phone_number", decoded.phone_number);
      localStorage.setItem("token_exp", decoded.exp);
      localStorage.setItem("username", decoded.email);
      setLogin(true);
      GetShipperHash(
        // data.id_token,
        // decoded.email,
        Redirect,
        setShiper,
        setProfileStatusCode,
        GetProfile,
        setProfileDataRes,
        callback
      );
    }
  });
};

export default getAccessToken;
