

import checkout from "./form";

const {
  formField: { companyName, address1, address2, city, state, zip, recipientName, recWallet },
} = checkout;

const InitValue = (data) => {
  return {
    [companyName.name]: data?.companyName || "",
    [address1.name]: data?.address1 || "",
    [address2.name]: data?.address2 || "",
    [city.name]: data?.city || "",
    [state.name]: data?.state || "",
    [zip.name]: data?.zip || "",
    [recipientName.name]: data?.recipientName || "",
    [recWallet.name]: data?.recWallet || "",
  };
};

export default InitValue;
