import { PostAddressBookApi } from "redux/Api/common";
import { GetProfileApi } from "redux/Api/common";
import {
  SET_LOADER,
  LOGIN_SUCCESS,
  SET_PROFILE_DATA_RES,
  SET_PROFILE_DATA_SUCCESS,
  SET_PROFILE_STATUS_CODE,
  SET_USER_FULL_NAME,
  SET_CREATE_ADDRESS_BOOK_RES,
} from "../Type";
export const setLoaderStatus = (status) => {
  return {
    type: SET_LOADER,
    status,
  };
};

export const setLogin = (status) => {
  return {
    type: LOGIN_SUCCESS,
    status,
  };
};

export const setProfileData = (data) => (dispatch) => {
  dispatch(setProfileDataRes(data));
}

export const setProfileDataRes = (data) => {
  return {
    type: SET_PROFILE_DATA_RES,
    data,
  };
};

export const setProfileDataSuccess = (status) => {
  return {
    type: SET_PROFILE_DATA_SUCCESS,
    status,
  };
};

export const setProfileStatusCode = (status_code) => {
  return {
    type: SET_PROFILE_STATUS_CODE,
    status_code,
  };
};

export const setUserFullName = (data) => {
  return {
    type: SET_USER_FULL_NAME,
    data,
  };
};

export const SetLoader = (data) => (dispatch) => {
  dispatch(setLoaderStatus(data));
}


export const RefreshProfile = (data) => (dispatch) => {
  GetProfileApi().then((res) => {
    if (res.status === 200) {
      dispatch(setProfileDataRes(res.data[0]));
    }
  });
};



export const GetProfile = (data) => (dispatch) => {
  // dispatch(setLoaderStatus(true));
  return GetProfileApi();
  //   .then((res) => {
  //   debugger;
  //   if (res.status === 200) {
  //     dispatch(setProfileStatusCode(res.status));
  //     dispatch(setLoaderStatus(false));
  //     dispatch(setProfileDataRes(res.data));
  //     dispatch(setProfileDataSuccess(true));
  //     return true;
  //   } else {
  //     dispatch(setLoaderStatus(false));
  //     dispatch(setProfileStatusCode(res.status));
  //     try {
  //       dispatch(setProfileDataSuccess(false));
  //     } catch {
  //       return "";
  //     }
  //   }
  // });
};

export const setActPostAddressBookRes = (data) => {
  return {
    type: SET_CREATE_ADDRESS_BOOK_RES,
    data,
  };
};

export const setActPostAddressBookSuccess = (status) => {
  return {
    type: SET_CREATE_ADDRESS_BOOK_SUCCESS,
    status,
  };
};

export const PostAddressBook = (data) => (dispatch) => {
  // dispatch(setLoaderStatus(true));
  return PostAddressBookApi(data);
};
