import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import Spin from "components/Spin";



import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import Row from './row';

import CircularProgress from '@mui/material/CircularProgress';
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';


import { GetProfile, setProfileDataRes, setLoaderStatus } from "redux/Actions/common";

function CreditHistory(props) {
    const { GetProfile, setProfileDataRes, setLoaderStatus } = props;
    const [creditHostory, setCreditHostory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [amount, setAmount] = useState('');
    const [amountError, setAmountError] = useState('');

    useEffect(() => {
        // refreshProfileData();

        setTimeout(() => {
            getCreditHistory();
        }, 3000);
        let index = 0;

        let timer = setInterval(() => {
            refreshProfileData();
            index = index + 1;
            if (index >= 3 && timer != null) {
                clearInterval(timer);
            }
        }, 3000);

        return function cleanup() {
            if (timer != null) {
                clearInterval(timer);
            }
        };

    }, []);

    const refreshProfileData = () => {
        GetProfile().then(res => {
            if (res.status == 200) {
                setProfileDataRes(res.data[0]);
            }
        }).finally(() => {
            setLoaderStatus(false);
        });
    }



    const getCreditHistory = () => {
        setLoading(true);
        let url = `${process.env.REACT_APP_API_BASE_URL}/accounts/credits-history/${localStorage.ShipperHash}`;
        var headers = {
            Authorization: `Bearer ${localStorage.id_token}`,
            ShipperHash: localStorage.ShipperHash,
            "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
        };

        axios
            .get(url, {
                headers: headers,
            })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res);
                    setCreditHostory(res.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onRowClick = (row, event) => {

    };

    const handleAmountChange = (e) => {
        const re = /^[+-]?\d*(?:[.,]\d{0,2})?$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            setAmount(e.target.value);
        }
        if (e.target.value == '') {
            setAmount('');
        }
        if (e.target.value == '' || parseFloat(e.target.value) >= 10) {
            setAmountError('');
        } else {
            setAmountError('Amount should be greater than $10')
        }

    }

    const createPaymentIntent = async (e) => {
        setAmountError('');
        if (amount == "" || amount == null || amount < 10) {
            setAmountError('Amount should be greater than $10!')
            return;
        }
        e.preventDefault();
        setLoading(true);
        let url = `${process.env.REACT_APP_API_BASE_URL}/stripe/create-session`;
        var headers = {
            Authorization: `Bearer ${localStorage.id_token}`,
            ShipperHash: localStorage.ShipperHash,
            "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
        };
        var payload = {
            Amount: parseFloat(amount),
            Mode: "charge",
            CancelUrl: window.location.href,
            SuccessUrl: window.location.href,
            ShipperHash: localStorage.ShipperHash
        }

        axios
            .post(url, payload, {
                headers: headers,
            })
            .then((res) => {
                if (res.status === 200) {
                    window.location.href = res.data.SessionUrl;
                }
            })
            .finally(() => {
                setLoading(false);
            });


    };

    return (
        <Spin tip="Loading..." spinning={loading}>
            <Paper>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" style={{ minHeight: 85 }}>
                        <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Credits History

                                <IconButton color="primary" aria-label="refresh page" component="label" onClick={() => {
                                    getCreditHistory();
                                    refreshProfileData();
                                }}>
                                    <RefreshIcon />
                                </IconButton>
                            </Typography>

                            <div style={{ position: 'relative' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                    <span style={{ marginRight: 3 }}>$</span>
                                    <VuiInput size="small" type="text" value={amount} placeholder="100" onChange={handleAmountChange} />
                                    <VuiButton size="small" variant="contained" color="success" onClick={createPaymentIntent} style={{ minWidth: 115, marginLeft: 3, paddingLeft: 5, paddingRight: 5 }}>
                                        {
                                            loading ? <CircularProgress size={16} /> : "Buy Credits"
                                        }
                                    </VuiButton>
                                </div>
                                <span style={{ fontSize: 13, color: 'red', bottom: -20, left: 0, position: 'absolute' }}>{amountError}</span>
                            </div>
                        </Toolbar>
                    </AppBar>
                </Box>
                <TableContainer component={"div"} sx={{ maxHeight: 440 }}>
                    <Table aria-label="collapsible table" stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Transaction Ref</TableCell>
                                <TableCell>Transaction Date</TableCell>
                                <TableCell>Transaction Status</TableCell>
                                <TableCell align="right">Amount Added</TableCell>
                                <TableCell>Receipt</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {creditHostory?.map((row, index) => (
                                <Row key={index} row={row} onRowClick={onRowClick} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Spin>
    );
}

// export default CreditHistory;

export default connect(
    (state) => ({
        Profile: state.CommonStore.Profile,
    }),
    {
        GetProfile,
        setProfileDataRes,
        setLoaderStatus
    }
)(CreditHistory);