

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import { connect } from "react-redux";
import { setActPackageType, setActPackageData } from "redux/Actions/wizard";
import FormField from "layouts/pages/users/new-user/components/FormField";


function ePostagePackageType(props) {
    const {
        packageList,
        getPacksgeBtnState,
        packageData,
        senderInfo,
        recipientInfo
    } = props;
    const { setFieldValue, values, formField, errors, touched, } = props.formData;
    const { customLength, customWidth, customHeight, packageWeight } = formField;
    const {
        flatRate: flatRateV,
        packageWeight: packageWeightV,
        unit: unitV,
        customLength: customLengthV,
        customWidth: customWidthV,
        customHeight: customHeightV,
        softPackLength: softPackLengthV,
        softPackWidth: softPackWidthV,
        softPackHeigh: softPackHeightV,
    } = values;

    const [selectedsWizard, setWizardSelection] = useState(values.packageType);
    const handleWizardSelect = (value) => {
        if (value === selectedsWizard) {
            setWizardSelection("");
            setFieldValue("packageType", "");
        } else {
            setWizardSelection(value);
            setFieldValue("packageType", value);
        }

        if (value == "USPS FLAT RATE BOX") {
            setFieldValue("packageWeight", 1);
        }
        else {
            setFieldValue("packageWeight", 0);
        }
    };

    useEffect(() => {
        var data = packageData ? packageData : {};
        data["PackageType"] = selectedsWizard;
        data["PriceType"] = "retail";
        data["OriginZip"] = senderInfo?.Zip;
        data["DestinationZip"] = recipientInfo?.Zip;
        setActPackageData(data);
    }, [selectedsWizard])

    const customButtonStyles = ({
        functions: { pxToRem },
        borders: { borderWidth },
        palette: { transparent, info },
    }) => ({
        width: pxToRem(150),
        height: pxToRem(150),
        borderWidth: borderWidth[2],
        mb: 1,
        ml: 0.5,

        "&.MuiButton-contained, &.MuiButton-contained:hover": {
            boxShadow: "none",
            border: `${borderWidth[2]} solid ${transparent.main}`,
        },

        "&:hover": {
            border: `${borderWidth[2]} solid ${info.main} !important`,
        },
    });

    useEffect(() => {
        if (selectedsWizard) {
            getPacksgeBtnState(false);
        } else {
            getPacksgeBtnState(true);
        }
    }, [selectedsWizard]);

    const [pound, setPound] = useState(0);
    const [ounce, setOunce] = useState(0);

    const handleOunceChange = (e) => {
        let o = 0;
        if (e.target.value != "") {
            o = parseFloat(e.target.value);
        }
        setOunce(o);
        let weight = pound * 16;
        weight = weight + o;
        setFieldValue("packageWeight", weight);
    }
    const handlePoundChange = (e) => {
        let p = 0;
        if (e.target.value != "") {
            p = parseFloat(e.target.value);
        }
        setPound(p);
        let weight = p * 16;
        weight = weight + ounce;
        setFieldValue("packageWeight", weight);
    }

    return (
        <VuiBox>
            <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
                <VuiBox mb={1}>
                    <VuiTypography variant="lg" fontWeight="bold" color="dark">
                        SELECT PACKAGE TYPE
                    </VuiTypography>
                </VuiBox>
            </VuiBox>
            <VuiBox mt={2}>
                <Grid container spacing={2} justifyContent="center">
                    {packageList &&
                        packageList.map((packData, i) => {
                            return (
                                <Grid key={i} item xs={12} md={2.5}>
                                    <VuiBox textAlign="center">
                                        <VuiButton
                                            color="info"
                                            variant={selectedsWizard === packData.name ? "contained" : "outlined"}
                                            onClick={() => {
                                                handleWizardSelect(packData.name);
                                            }}
                                            sx={customButtonStyles}
                                        >
                                            {packData.icon}
                                        </VuiButton>
                                        <VuiTypography variant="h6" color="dark">
                                            {packData.displayName.toUpperCase()}
                                        </VuiTypography>
                                    </VuiBox>
                                </Grid>
                            );
                        })}
                    {/* <Grid item xs={12} md={12} lg={12}>
                        <div style={{ textAlign: 'center' }}>
                            {
                                touched.packageType ? <span style={{ color: 'red' }}>{errors?.packageType}</span> : null
                            }
                        </div>
                    </Grid> */}
                </Grid>
                {
                    selectedsWizard && selectedsWizard != 'USPS FLAT RATE BOX' ? (
                        <Grid container spacing={1} justifyContent="center">
                            <Grid item xs={12} md={2} lg={2}>
                                <FormField
                                    label={customLength.label}
                                    name={customLength.name}
                                    type={customLength.type}
                                    value={customLengthV}
                                    placeholder={customLength.placeholder}
                                    error={errors.customLength && touched.customLength}
                                    inputProps={{ autoComplete: "" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} lg={2}>
                                <FormField
                                    label={customWidth.label}
                                    name={customWidth.name}
                                    type={customWidth.type}
                                    value={customWidthV}
                                    placeholder={customWidth.placeholder}
                                    error={errors.customWidth && touched.customWidth}
                                    inputProps={{ autoComplete: "" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} lg={2}>
                                <FormField
                                    label={customHeight.label}
                                    name={customHeight.name}
                                    type={customHeight.type}
                                    value={customHeightV}
                                    placeholder={customHeight.placeholder}
                                    error={errors.customHeight && touched.customHeight}
                                    inputProps={{ autoComplete: "" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <VuiTypography style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: 8 }}>Weight (LB, OZ)</VuiTypography>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <VuiInput type="number" placeholder="Pounds" onChange={handlePoundChange} min={0} />
                                    <div style={{ fontWeight: 'bold', marginLeft: 5, marginRight: 5 }}>+</div>
                                    <VuiInput type="number" placeholder="Ounces" onChange={handleOunceChange} min={0} />
                                </div>
                                <div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                                <div style={{ textAlign: 'center' }}>
                                    {
                                        touched.packageWeight ? <span style={{ color: 'red' }}>{errors?.packageWeight}</span> : null
                                    }

                                </div>
                            </Grid>
                        </Grid>
                    ) : null
                }
            </VuiBox>



        </VuiBox>
    );
}

export default connect(
    (state) => ({
        packageType: state.WizardStore.packageType,
        packageData: state.WizardStore.packageData,
        senderInfo: state.WizardStore.sender_info,
        recipientInfo: state.WizardStore.recipient_info,
    }),
    {
        setActPackageType,
        setActPackageData,
    }
)(ePostagePackageType);
