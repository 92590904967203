import { TEST, TEST1 } from "../Type";
import { PURGE } from "redux-persist";
const initialState = {
  test: "This is for test",
  test1: "This is for test1",
};

const TestStore = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;
    case TEST:
      return {
        ...state,
        test: "This is for test",
      };
    case TEST1:
      return {
        ...state,
        test1: "This is for test1",
      };
    default:
      return state;
  }
};
export default TestStore;
