import { Button, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import { Formik, Form, Field } from "formik";
import form from "./form";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useEffect, useState } from "react";
import initialValues from "./initialValues";
import validations from "./validations";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import { GetState } from "api/StateGet";
import { connect } from "react-redux";
import Spin from "components/Spin";
import { PostAddressBook } from "redux/Actions/common";
import { PostVerifyAddress } from "redux/Actions/wizard";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { setLoaderStatus } from "redux/Actions/common";
import Notification from "components/Notification";
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

const AddressBookCreate = (props) => {
  const { PostAddressBook, PostVerifyAddress } = props;
  const Options = GetState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const { formId, formField } = form;
  const { recipientName, companyName, address1, address2, city, state, zip, recWallet } = formField;
  const location = useLocation();
  const [verified, SetVerified] = useState(false);
  var loc_state = location?.state;
  const data = {
    companyName: loc_state?.CompanyName || "",
    address1: loc_state?.AddressLine1 || "",
    address2: loc_state?.AddressLine2 || "",
    city: loc_state?.City || "",
    state: loc_state?.State || "",
    zip: loc_state?.Zip || "",
    recipientName: loc_state?.PersonName || "",
    recWallet: loc_state?.NftWalletAddress || "",
  };
  const [initialData, SetInitialData] = useState(data);
  const [loading, SetLoading] = useState(false);
  const history = useHistory();
  const { AddressBookId, AddressId } = useParams();

  const handleSubmit = async (values, actions) => {
    SetLoading(true);
    var addressVerifyPayload = {
      Address1: values.address1,
      Address2: values.address2,
      City: values.city,
      CompanyName: values.companyName,
      PersonName: values.recipientName,
      //   Phone: "12125551234",
      State: values.state,
      Zip: values.zip,
      Provider: "usps",
    };

    let is_address_verify = false;

    await PostVerifyAddress(addressVerifyPayload).then((res) => {
      props.setLoaderStatus(false);
      if (res.status === 200) {
        setOpen(true);
        setMessage("Address verified!");
        setMessageType("success");
        SetVerified(true);
        is_address_verify = true;
        actions.setFieldValue("address1", res.data?.AddressLine1 || values.address1);
        actions.setFieldValue("address2", res.data?.AddressLine2 || values.address2);
        actions.setFieldValue("companyName", res.data?.FirmName || values.companyName);
        actions.setFieldValue("city", res.data?.City || values.city);
        actions.setFieldValue("state", res.data?.State || values.state);
        actions.setFieldValue("zip", res.data?.Zip5 || values.zip);
      } else {
        try {
          var error = `${res.response.data.error}`;
          setOpen(true);
          setMessage(error);
          setMessageType("error");
          return false;
        } catch {
          setOpen(true);
          setMessage("network error!");
          setMessageType("error");
          return false;
        } finally {
          props.setLoaderStatus(false);
        }
      }
    });

    if (is_address_verify) {
      var payload = {
        IsVerified: is_address_verify,
        //   CompanyName: "DPS", // optional by default it's empty
        //   Phone: "7985157568", // optional by default it's empty
        //   Email: "vijay@gmail.co", // optional by default it's empty
        //   PersonName: "vijay Kumar", // optional by default it's empty
        AddressLine1: values.address1,
        AddressLine2: values.address2,
        City: values.city,
        CompanyName: values.companyName,
        PersonName: values.recipientName,
        State: values.state,
        Zip: values.zip,
        NftWalletAddress: values.recWallet,
      };
      if (AddressId) {
        var url = `${process.env.REACT_APP_API_BASE_URL}/addressbook/${localStorage.ShipperHash}/${AddressBookId}/address/${AddressId}`;

        const headers = {
          Authorization: `Bearer ${localStorage.id_token}`,
          ShipperHash: localStorage.ShipperHash,
          "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
        };

        axios
          .put(url, payload, {
            headers: headers,
          })
          .then((res) => {
            if (res.status === 200) {
              setOpen(true);
              setMessage("Address Updated!");
              setMessageType("success");
              setTimeout(() => {
                history.push("/contacts/list");
              }, 1000);
            }
          })
          .catch((error) => {
            setOpen(true);
            setMessage(error.message);
            setMessageType("error");
          })
          .finally(() => {
            SetLoading(false);
          });
      } else {
        await PostAddressBook(payload).then((res) => {
          if (res.status === 200) {
            SetLoading(false);
            setOpen(true);
            setMessage("Address Created!");
            setMessageType("success");
            setTimeout(() => {
              history.push("/contacts/list");
            }, 1000);
          } else {
            SetLoading(false);
            try {
              setOpen(true);
              setMessage(error.message);
              setMessageType("error");
            } catch {
              setOpen(true);
              setMessage("network error!");
              setMessageType("error");
            } finally {
              SetLoading(false);
            }
          }
        });
      }
    } else {
      SetLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  setTimeout(() => {
    if (open) {
      setOpen(false);
    }
  }, 6000);

  return (
    <Paper style={{ maxWidth: '80%', margin: '0px auto' }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {AddressBookId ? "Edit Contact" : "Create Contact"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Spin tip="Loading..." spinning={loading}>
        <Notification open={open} type={messageType} message={message} handleClose={handleClose} />
        <VuiBox pt={0} pb={5}>
          <Formik
            initialValues={initialValues(initialData)}
            validationSchema={validations[0]}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions);
            }}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              setFieldError,
            }) => (
              <Form id={formId} autoComplete="off">
                <Grid container justifyContent="center">
                  <Grid item md={11}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormField
                          label={"Name"}
                          name={recipientName.name}
                          type={recipientName.type}
                          value={values.recipientName}
                          placeholder={recipientName.placeholder}
                          error={errors.recipientName && touched.recipientName}
                          success={values.recipientName.length > 0 && !errors.recipientName}
                          inputProps={{ autoComplete: "" }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          label={companyName.label}
                          name={companyName.name}
                          type={companyName.type}
                          value={values.companyName}
                          placeholder={companyName.placeholder}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                        <FormField
                          label={address1.label}
                          name={address1.name}
                          type={address1.type}
                          value={values.address1}
                          placeholder={address1.placeholder}
                          error={errors.address1 && touched.address1}
                          success={values.address1?.length > 0 && !errors.address1}
                          inputProps={{ autoComplete: "" }}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ paddingTop: 0 }}>
                        <FormField
                          label={address2.label}
                          name={address2.name}
                          type={address2.type}
                          value={values.address2}
                          placeholder={address2.placeholder}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                        <FormField
                          label={city.label}
                          name={city.name}
                          type={city.type}
                          value={values.city}
                          placeholder={city.placeholder}
                          error={errors.city && touched.city}
                          success={values.city.length > 0 && !errors.city}
                          inputProps={{ autoComplete: "" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} style={{ paddingTop: 0 }}>
                        <VuiBox mb={1.5}>
                          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <VuiTypography
                              component="label"
                              variant="caption"
                              color="white"
                              fontWeight="bold"
                              textTransform="capitalize"
                              style={{ color: "black" }}
                            >
                              {state.label}
                            </VuiTypography>
                          </VuiBox>
                          <Field name="recState">
                            {({ field, form }) => (
                              <VuiSelect
                                menuPlacement="top"
                                type="text"
                                name={state.name}
                                value={Options.find((obj) => obj.value === values.state)}
                                label={state.label}
                                options={Options}
                                onChange={(option) => {
                                  form.setFieldValue("state", option.value);
                                }}
                                placeholder="Select State"
                                success={values.state.length > 0 && !errors.state}
                              />
                            )}
                          </Field>
                          <VuiBox mt={0.75}>
                            <VuiTypography component="div" variant="caption" color="error">
                              {touched.state && <div className="input-feedback">{errors.state}</div>}
                            </VuiTypography>
                          </VuiBox>
                        </VuiBox>
                      </Grid>
                      <Grid item xs={12} md={3} style={{ paddingTop: 0 }}>
                        <FormField
                          label={zip.label}
                          name={zip.name}
                          type={zip.type}
                          value={values.zip}
                          placeholder={zip.placeholder}
                          error={errors.zip && touched.zip}
                          success={values.zip.length > 0 && !errors.zip}
                          inputProps={{ autoComplete: "" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                        <FormField
                          label={recWallet.label}
                          name={recWallet.name}
                          type={recWallet.type}
                          placeholder={recWallet.placeholder}
                          value={values.recWallet}
                          inputProps={{ autoComplete: "" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                          <Button
                            color="primary"
                            type="button"
                            disabled={loading}
                            variant="contained"
                            // style={{ color: "#fff" }}
                            onClick={() => {
                              history.push("/contacts/list");
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button
                            type="submit"
                            disabled={loading}
                            variant="contained"
                            style={{ color: "#fff" }}
                          >
                            Submit
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </VuiBox>
      </Spin>
    </Paper>
  );
};

export default connect((state) => ({}), { PostVerifyAddress, PostAddressBook, setLoaderStatus })(
  AddressBookCreate
);
