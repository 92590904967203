

// @mui material components
import Grid from "@mui/material/Grid";
import { Field } from "formik";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { connect } from "react-redux";

// Wizard application components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useEffect, useState } from "react";
import VuiButton from "components/VuiButton";
import {
  setActRecipientInfo,
  setIsAddressVerify,
  PostVerifyAddressSuccess,
} from "redux/Actions/wizard";
import { GetState } from "api/StateGet";
import VuiSelect from "components/VuiSelect";
import Spin from "components/Spin";
import AddressBook from "./address-book";

function RecipientInfo(props) {
  const { formField, values, errors, touched, setFieldValue } = props.formData;
  const {
    recipientName,
    recCompanyName,
    recAddress1,
    recAddress2,
    recCity,
    recState,
    recZip,
    recWallet,
  } = formField;
  const {
    recipientName: recipientNameV,
    recCompanyName: recCompanyNameV,
    recAddress1: recAddress1V,
    recAddress2: recAddress2V,
    recCity: recCityV,
    recState: recStateV,
    recZip: recZipV,
    recWallet: recWalletV,
  } = values;

  const {
    addressBookData,
    RecipientInfo,
    isAddressVerify,
    AddressVerifyRes,
    AddressVerifySuccess,
    loader,
  } = props;
  const Options = GetState();
  const [openGrid, setOpenGrid] = useState(false);
  const [PersonName, setPersonName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [Address1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Zip, setZip] = useState("");
  const [Wallet, setWallet] = useState("");

  useEffect(() => {
    setPersonName(RecipientInfo?.PersonName || "");
    setCompanyName(RecipientInfo?.CompanyName || "");
    setAddress1(RecipientInfo?.AddressLine1 || "");
    setAddress2(RecipientInfo?.AddressLine2 || "");
    setCity(RecipientInfo?.City || "");
    setState(RecipientInfo?.State || "");
    setZip(RecipientInfo?.Zip || "");
    if (props.formData?.setTouched) {
      props.formData?.setTouched({});
    }
  }, []);

  useEffect(() => {
    if (AddressVerifySuccess) {
      var data = RecipientInfo || {};

      stateUpdate(AddressVerifyRes);
    }
  }, [AddressVerifySuccess]);

  const getDataFromTable = (Data) => {
    var data = {};
    stateUpdate(Data);
  };

  const stateUpdate = (data) => {
    setFieldValue("recAddress1", data?.AddressLine1 || "");
    setFieldValue("recAddress2", data?.AddressLine2 || "");
    setFieldValue("recCity", data?.City || "");
    setFieldValue("recState", data?.State || "");
    setFieldValue("recZip", data?.Zip || "");
    setFieldValue("recipientName", data?.PersonName || "");
    setFieldValue("recCompanyName", data?.CompanyName || "");
    setFieldValue("recWallet", data?.NftWalletAddress || recWalletV);
    if (props.formData?.setTouched) {
      props.formData?.setTouched({});
    }
  };

  const handleCloseGrid = (open) => {
    setOpenGrid(prevCheck => !prevCheck);
  };

  const onAddressSelect = (address) => {
    getDataFromTable(address);
    handleCloseGrid(true);
  }

  return (
    <Spin tip={"Loading..."} spinning={loader}>
      <VuiBox>
        <VuiBox width="80%" textAlign="center" mx="auto">
          <VuiBox mb={1}>
            <VuiTypography variant="lg" fontWeight="bold" color="dark">
              RECIPIENT INFO
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <VuiBox mt={2}>
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item xs={12} md={3} mt={3} style={{ display: "flex", justifyContent: "end" }}>
              <VuiButton variant="outlined" onClick={handleCloseGrid} color="info">
                Address Book
              </VuiButton>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormField
                label={recipientName.label}
                name={recipientName.name}
                type={recipientName.type}
                value={recipientNameV}
                placeholder={recipientName.placeholder}
                error={errors.recipientName && touched.recipientName}
                success={recipientNameV.length > 0 && !errors.recipientName}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                label={recCompanyName.label}
                name={recCompanyName.name}
                type={recCompanyName.type}
                value={recCompanyNameV}
                placeholder={recCompanyName.placeholder}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
              <FormField
                label={recAddress1.label}
                name={recAddress1.name}
                type={recAddress1.type}
                value={recAddress1V}
                placeholder={recAddress1.placeholder}
                error={errors.recAddress1 && touched.recAddress1}
                success={recAddress1V.length > 0 && !errors.recAddress1}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
              <FormField
                label={recAddress2.label}
                name={recAddress2.name}
                type={recAddress2.type}
                value={recAddress2V}
                placeholder={recAddress2.placeholder}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
              <FormField
                label={recCity.label}
                name={recCity.name}
                type={recCity.type}
                value={recCityV}
                placeholder={recCity.placeholder}
                error={errors.recCity && touched.recCity}
                success={recCityV.length > 0 && !errors.recCity}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ paddingTop: 0 }}>
              <VuiBox mb={1.5}>
                <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <VuiTypography
                    component="label"
                    variant="caption"
                    color="white"
                    fontWeight="bold"
                    textTransform="capitalize"
                    style={{ color: "black" }}
                  >
                    <span>{recState.label}</span>
                  </VuiTypography>
                </VuiBox>
                <Field name="recState">
                  {({ field, form }) => (
                    <VuiSelect
                      menuPlacement="top"
                      type="text"
                      name={recState.name}
                      value={Options.find((obj) => obj.value === recStateV)}
                      label={recState.label}
                      options={Options}
                      onChange={(option) => {
                        form.setFieldValue("recState", option.value);
                      }}
                      placeholder="Select State"
                      success={recStateV.length > 0 && !errors.recState}
                    />
                  )}
                </Field>
                <VuiBox mt={0.75}>
                  <VuiTypography component="div" variant="caption" color="error">
                    <span></span>
                    {touched.recState && <div className="input-feedback">{errors.recState}</div>}
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
            </Grid>
            <Grid item xs={12} md={3} style={{ paddingTop: 0 }}>
              <FormField
                label={recZip.label}
                name={recZip.name}
                type={recZip.type}
                value={recZipV}
                placeholder={recZip.placeholder}
                error={errors.recZip && touched.recZip}
                success={recZipV.length > 0 && !errors.recZip}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            {
              values?.IsNFT ? (
                <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                  <FormField
                    label={recWallet.label}
                    name={recWallet.name}
                    type={recWallet.type}
                    placeholder={recWallet.placeholder}
                    value={recWalletV}
                    inputProps={{ autoComplete: "" }}
                  />
                </Grid>
              ) : null
            }
          </Grid>
          <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
            <span style={{ color: 'red', fontSize: 12 }}>{props?.addressVerificationError}</span>
          </Grid>
        </VuiBox>
        {
          openGrid ? <AddressBook
            openGrid={openGrid}
            handleCloseGrid={handleCloseGrid}
            onSelect={onAddressSelect}
          /> : null
        }
      </VuiBox>
    </Spin>
  );
}

export default connect(
  (state) => ({
    addressBookData: state.WizardStore.addressBookData,
    RecipientInfo: state.WizardStore.recipient_info,
    AddressVerifyRes: state.WizardStore.address_verify_res,
    AddressVerifySuccess: state.WizardStore.address_verify_success,
    isAddressVerify: state.WizardStore.is_address_verify,
    loader: state.CommonStore.loader,
  }),
  {
    setActRecipientInfo,
    setIsAddressVerify,
    PostVerifyAddressSuccess,
  }
)(RecipientInfo);
