import VerifyButton from "components/Stripe/Varification";
import React from "react";

const StripeDocumentVarification = () => {
  return (
    <div>
      <VerifyButton />
    </div>
  );
};

export default StripeDocumentVarification;
