

export default {
  formId: "verify-form",
  formField: {
    verifyName: {
      name: "verifyName",
      label: "Name",
      type: "text",
      placeholder: "Enter Name"
    },
    nftId: {
      name: "nftId",
      label: "Document ID",
      type: "text",
      placeholder: "Document ID",
      errorMsg: "Document ID is required.",
    },
    verifyType: {
      name: "verifyType",
      label: "Type",
      type: "text",
      placeholder: "Video/Pdf"
    }
  }
};
