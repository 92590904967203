

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { city, state, zip, address1, recipientName },
} = checkout;

export default [
  Yup.object().shape({
    [city.name]: Yup.string().required(city.errorMsg),
    [state.name]: Yup.string().required(state.errorMsg),
    [zip.name]: Yup.string().required(zip.errorMsg).min(5, zip.invalidMsg),
    [address1.name]: Yup.string().required(address1.errorMsg),
    [recipientName.name]: Yup.string().required(recipientName.errorMsg),
  }),
];
