import React, { useState } from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from "moment";


import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';


function Row(props) {
    const { row } = props;

    const [actionButtonOpen, setActionButtonOpen] = useState(false);
    const actionRef = React.useRef(null);

    const handleActionToggle = () => {
        setActionButtonOpen((prevOpen) => !prevOpen);
    };

    const handleActionClose = (event) => {
        if (actionRef.current && actionRef.current.contains(event.target)) {
            return;
        }

        setActionButtonOpen(false);
    };

    return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>{moment(row?.TransactionDate).format("MMM DD, YYYY h:mm A")}</TableCell>
            <TableCell>{row?.JobHash ? row?.JobHash : row?.DocumentHash}</TableCell>
            <TableCell>


                {row?.TransactionType == "epostage-nft" ? "NFT" :
                    row?.TransactionType == "epostage-package" ? "Package" :
                        row?.TransactionType == "epostage-doc" ? "ePostage" : "Print To Mail"}


            </TableCell>
            <TableCell>
                <div title={row?.TransactionDescription} style={{ textTransform: 'capitalize', maxWidth: 230, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {row?.TransactionDescription}
                </div>
            </TableCell>
            <TableCell align="right">
                {row?.ChargedAmount ? `$${row?.ChargedAmount}` : ''}
            </TableCell>
            <TableCell align="right">
                {row?.RefundedAmount ? `$${row?.RefundedAmount}` : ''}
            </TableCell>
        </TableRow>
    )
}

export default Row;