

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";
import { CircularProgress } from "@mui/material";
import EastIcon from '@mui/icons-material/East';


// React-icons
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

function DefaultPricingCard({ badge, price, specifications, action }) {
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <VuiBox key={label} display="flex" alignItems="center" p={1}>
      <VuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        borderRadius="50%"
        shadow="md"
        mr={2}
      >
        <VuiTypography variant="button" color="dark" sx={{ lineHeight: 0 }}>
          {includes ? (
            <AiFillCheckCircle color="#17ad37" size="22px" />
          ) : (
            <AiFillCloseCircle color="#f44336" size="22px" />
          )}
        </VuiTypography>
      </VuiBox>
      <VuiTypography variant="body2" color="dark">
        {label}
      </VuiTypography>
    </VuiBox>
  ));

  return (
    <Card elevation={5}>
      <VuiBox textAlign="center">
        <VuiBadge
          // variant="h2"
          color={badge.color}
          size="md"
          badgeContent={badge.label}
          circular
          container
        />
        <VuiBox my={1}>
          <VuiTypography display="inline" variant="h1" color="dark">
            {price.currency} {price.value}
          </VuiTypography>
          {/* <span>usd / month</span> */}
        </VuiBox>
      </VuiBox>
      <VuiBox>
        <div style={{ minHeight: 305 }}>
          <div style={{ textAlign: 'center', paddingBottom: 20, fontWeight: 'bold' }}>
            {action.tagLine}
          </div>
          {renderSpecifications}
        </div>
        {action.type === "internal" ? (
          // <VuiBox mt={3}>
          //   <VuiButton component={Link} to={action.route} color={action.color} fullWidth>
          //     {action.label}&nbsp;
          //   </VuiButton>
          // </VuiBox>

          <VuiBox mt={3}>

            <VuiButton color={action.color} variant="gradient" fullWidth onClick={() => {
              action.handleClick(action.name)
            }} disabled={action.loading || action.disabled}>
              {action.loading ? <CircularProgress color={"error"} size={16} /> : action.label}
              &nbsp;&nbsp;
              <EastIcon />
            </VuiButton>
          </VuiBox>

        ) : (
          <VuiBox mt={3}>
            <VuiButton
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              color={action.color}
              fullWidth
            >
              {action.label}&nbsp;
            </VuiButton>
          </VuiBox>
        )}
      </VuiBox>
    </Card>
  );
}

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "text",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
};

export default DefaultPricingCard;
