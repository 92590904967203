import { Paper } from "@mui/material";
import { Link } from "react-router-dom";

function TermsAndCondition(props) {
    return (
        <div style={{ width: '80%', margin: '0px auto', marginBottom: 50 }}>
            <Paper style={{ padding: 20 }}>
                <div style={{ textAlign: 'center' }}>
                    <h4>Terms & Conditions</h4>
                    <span>Effective January, 2022</span>
                    <hr />
                </div>

                <div>
                    <strong>
                        PLEASE NOTE THAT THE FOLLOWING TERMS ARE APPICABLE WHENEVER YOU INITIATE THE USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW). IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES OFFERD BY DIGITAL POSTAL SERVICE IN ANY MANNER.
                    </strong>
                    <br />
                    <p>
                        Welcome to DIGITAL POSTAL SERVICE (DPS). Please continuing reading to learn and understand the rules and restrictions that govern your use of our website(s), products, services, and applications (the “Services”). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at <a href="mailto:support@dps.io">support@dps.io</a>
                    </p>
                    <p>
                        These Terms of Use (the “Terms”) are a binding contract between you and Vertrius Corp. d/b/a DPS. (“DPS,” “we” and “us”).
                        You must agree to and accept all of the Terms. Failure to do so will mean that you don’t have the right to use the Services.
                        Your use of the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect for the duration of your use of the Services.
                        These Terms include the provisions in this document, as well as those in the <Link to="/privacy">Privacy Policy</Link>
                    </p>

                    <div>
                        <u><b>Will these Terms ever change?</b></u>
                        <p>
                            We are always trying to improve our Services, so these Terms may need to change along with the enhanced versions of our Services. We reserve the right to change the Terms at any time. If the changes materially impact the Terms, we will use reasonable efforts to bring it to your attention by placing a notice on the DPS website, by sending you an email, and/or by another means of communication.
                        </p>
                        <p>
                            If you don’t agree with the new Terms, you are free to reject them. Please note that the rejection of the Terms at any time means you will no longer be able to use the Services. Using the Services in any capacity after a change to the Terms goes into effect will indicate that you agree to all of the changes.
                        </p>
                        <p>
                            Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and an approved DPS representative.
                        </p>
                    </div>

                    <div>
                        <u><b>What about my privacy?</b></u>
                        <p>
                            The topic of user privacy is a serious one for DPS. For the current DPS Privacy Policy, please <Link to="/privacy">click here</Link>
                        </p>
                        <p>
                            The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 13. We do not knowingly collect or solicit personally identifiable information from children under 13; if you are a child under 13, please do not attempt to register for the Services or send any personal information about yourself to us. If we learn we have collected personal information from a child under 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us personal information, please contact us at <a href="mailto:support@dps.io">support@dps.io</a>.
                        </p>
                    </div>

                    <div>
                        <u><b>What are the basics of using DPS?</b></u>
                        <p>
                            DPS’s Services allow you to find, compare, select, and buy courier services for shipping documents, parcels, and goods both nationally and internationally. You may use the Services to purchase postage from a third-party mail service or courier (“Courier”) and DPS will provide you with the appropriate evidence of postage, for example a shipping label, to use with your shipments.
                            <b>DPS serves as an intermediary between you and your selected Courier. DPS does not transport or deliver shipments and DPS is not a party to any contract you form with a Courier to pick up, transport, or deliver your shipments.</b>
                        </p>
                        <p>
                            You may be required to sign up for an account, and select a password and user name (“DPS User ID”). You promise to provide us with accurate, complete, and updated registration information about yourself. You may not select as your DPS User ID a name that you don’t have the right to use, or another person’s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission.
                        </p>
                        <p>
                            You represent and warrant that you are of legal age to form a binding contract. If you are not of legal age to form a binding contract, you’ve received a parent’s or guardian’s permission to use the Services and that person agrees to these Terms on your behalf. If you’re agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on that organization or entity’s behalf and bind them to these Terms (in which case, the references to “you” and “your” in these Terms, except for in this sentence, refer to that organization or entity).
                        </p>
                        <p>
                            You will only use the Services for your own internal, personal, use, and not on behalf of or for the benefit of any third party, and only in a manner that complies with all laws that apply to you. You will ensure that the information you supply to us in connection with purchasing postage or otherwise ordering Courier services is complete, accurate, and true. If your use of the Services is prohibited by applicable laws, then you are not authorized to use the Services. We can’t and won’t be responsible for your using the Services in any way that breaks the law.
                        </p>
                        <p>
                            You will not share your account or password with anyone, and you must protect the security of your account and your password. You’re responsible for any activity associated with your account.
                        </p>
                        <div>
                            <p>
                                <b>Your use of the Services is subject to the following additional restrictions:</b>
                            </p>
                            <p>
                                You represent, warrant, and agree that you will not use the Services, including without limitation contributing any Content or User Submission (each of those terms is defined below), or otherwise use the Services (or any Courier services) or interact with the Services in a manner that:
                            </p>

                            <p style={{ marginBottom: '5px' }}>(a) Infringes or violates the intellectual property rights or any other rights of anyone else (including DPS or any Courier);</p>
                            <p style={{ marginBottom: '5px' }}>(b) Constitutes a breach of, or otherwise conflicts with, any Courier terms, rules, or policies, or your agreements with any Courier;</p>
                            <p style={{ marginBottom: '5px' }}>(c) Violates any law or regulation, including any applicable export control laws;</p>
                            <p style={{ marginBottom: '5px' }}>(d) Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</p>
                            <p style={{ marginBottom: '5px' }}>
                                (e) Jeopardizes the security of your DPS account or anyone else’s (such as allowing someone else to log in to the Services as you);
                            </p>
                            <p>(f) Attempts, in any manner, to obtain the password, account, or other security information from any other user;</p>


                            <p style={{ marginBottom: '5px' }}>
                                (g) Violates the security of any computer network, or cracks any passwords or security encryption codes;
                            </p>
                            <p style={{ marginBottom: '5px' }}>
                                (h) Runs Mail list, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services’ infrastructure);
                            </p>
                            <p style={{ marginBottom: '5px' }}>
                                (i) “Crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of manual or automated means);
                            </p>
                            <p style={{ marginBottom: '5px' }}>
                                (j) Copies or stores any significant portion of the Content;
                            </p>
                            <p style={{ marginBottom: '5px' }}>
                                (k) Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services.
                            </p>
                            <p style={{ marginBottom: '5px' }}>
                                A violation of any of the foregoing is grounds for termination of your right to use or access the Services.
                            </p>
                        </div>
                        <div>
                            <strong>What are my rights in DPS?</strong>
                            <br />
                            <p>
                                The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, User Submissions, and so forth (all of the foregoing, the “Content”) are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services. You agree that you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including DPS’s) rights.
                            </p>
                            <p>
                                You understand that DPS owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services.
                            </p>
                            <p>
                                The Services may allow you to copy or download certain Content; remember that the aforementioned restrictions still apply to your usage of this function.
                            </p>
                        </div>
                        <div>
                            <strong>Do I have to grant any licenses to DPS or to other users?</strong>
                            <br />
                            <p>
                                It is important to understand that your “User Submission” is anything you post, upload, share, store, or otherwise provide through the Services. Please note that the following license is subject to our <Link to="/privacy">Privacy Policy</Link> to the extent it relates to User Submissions that are also your personally-identifiable information.
                            </p>
                            <p>
                                You hereby grant DPS a license to translate, modify (for technical purposes, such as making sure your content is viewable on an iPhone as well as a computer), reproduce, and otherwise act with respect to such User Submissions, in each case to enable us to provide and operate the Services. You agree that the license you grant is royalty-free, perpetual, irrevocable, and worldwide. This is a license only – your ownership in User Submissions is not affected.
                            </p>
                            <p>
                                Finally, you understand and agree that DPS, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the rights to do so.
                            </p>
                            <p>
                                You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it. You will keep all your registration information accurate and current. You are responsible for all your activity in connection with the Services.
                            </p>

                        </div>
                        <div>
                            <strong>What do I need to know about interacting with Couriers and other third parties through DPS?</strong>
                            <br />
                            <p>
                                Some of the Services offered by DPS may contain links or connections to Couriers or other third-party websites or services that are not owned or controlled by DPS. Our display on or through the Services of delivery service options offered by third parties does not in any way imply, suggest, or constitute any sponsorship or approval of us by any such Courier or any affiliation between any such Courier and DPS. Our display of specific options does not suggest a recommendation by us of the Courier or its delivery options. You agree that we are in no way responsible for the accuracy, timeliness or completeness of information it may obtain from these Couriers. Your interaction with any Courier or other third party found on or through our Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, is solely at your own risk. You agree that DPS will have no liability with respect to the acts, omissions, errors, representations, warranties, breaches or negligence of any Courier or other third party or for any personal injuries, death, property damage, or other damages or expenses resulting in any manner from your interactions with any Courier.
                            </p>
                            <p>
                                You agree to abide by the terms or conditions of purchase imposed by any Courier with whom you elect to deal and patronize. You agree to abide by the rules set forth by the Courier. The use of prohibited practices may result in the termination of your account on the Services, the Courier taking actions including the cancellation of a service order, denied delivery, additional charges to your credit card, additional charges collected at the delivery or collection, or future invoicing.
                            </p>
                            <p>
                                You accept that there are risks in accessing third-party websites and/or services, and that DPS is not responsible for such risks. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third-party website or service that you visit or utilize. DPS has no control over, and assumes no responsibility for the content, accuracy, privacy policies, or practices of or opinions expressed in any third-party websites or by any third party that you interact with through the Services. In addition, DPS will not and cannot monitor, verify, censor, or edit the content of any third-party site or service. By using the Services, you release and hold us unaccountable from any and all liability arising from your use of any third-party website or service.
                            </p>
                            <p>
                                Any information or content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such content originated, and you access all such information and content at your own risk. DPS isn’t liable for any errors or omissions in that information or content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired Content through the Services. We can’t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services.
                            </p>
                            <p>
                                If there is a dispute between participants on this site, or between users and Couriers or any other third party, you agree that DPS is under no obligation to become involved. In the event that you have a dispute with one or more other users, you release DPS, its officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. If you are a California resident, you shall and hereby do waive California Civil Code Section 1542, which says: “A general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which, if known by him or her must have materially affected his or her settlement with the debtor.”
                            </p>

                        </div>
                        <div>
                            <strong>Will DPS ever change the Services?</strong>
                            <br />
                            <p>
                                We’re always trying to improve the Services, so they may change over time. We may suspend or discontinue any part of the Services, or impose limits on certain features or restrict access to parts or all of the Services. We may introduce new features as technology develops and the demand for our Services increases. We’ll try to give you notice when we make a material change to the Services that would adversely affect you, but this isn’t always practical. Similarly, we reserve the right to remove any Content from the Services at any time, for any reason, in our sole discretion, and without notice.
                            </p>
                        </div>
                        <div>
                            <strong>How do I pay for DPS Services?</strong>
                            <br />
                            <p>

                                You agree to pay all fees associated with the Services you use. DPS’s current standard pricing is set forth <Link to="/privacy">here</Link>, which may be updated from time to time. If you purchase a subscription, your payment method automatically will be charged at the start of each subscription period for the fees and taxes applicable to that period. To avoid future charges, you can cancel before the renewal date. Learn how to <Link to="/privacy">cancel or suspend</Link> your subscription services. You may be subject to alternate pricing as set forth on a separate order form (“Order Form”) or in some other written form accepted by DPS. DPS reserves the right to revise your pricing at any time by providing written notice to you. DPS will send you an invoice detailing your orders and the applicable charges at the end of each week in which you place an order. For users with a high volume of orders, DPS may send invoices more frequently. Once the invoice has been sent to you, you agree to pay DPS and you authorize DPS to charge the full invoice amount to the payment method you provide in your account information. If we are unable to charge the payment method you provide, you agree to pay all amounts due on your outstanding invoice(s) upon demand.
                            </p>
                            <p>
                                When you purchase the services on a subscription basis (e.g., monthly or annually), you agree that you are authorizing recurring payments. You understand and agree that payments will be made to DPS by the method and at the recurring intervals you have agreed to, until the subscription for that service is terminated by you or by DPS. By authorizing recurring payments, you are authorizing DPS and/or DPS’s authorized payment processors to store your payment instrument and process such payments. Subscription fees are generally charged in advance of the applicable subscription period. If any payment is returned unpaid or if any credit card or similar transaction is rejected or denied, DPS or its service providers reserve the right to collect any applicable return item, rejection or insufficient funds fee and process any such payment as an electronic payment.
                            </p>
                            <p>

                                You must provide current, complete and accurate payment and billing information for your account. You must properly update all information to keep your account current, complete, and accurate. Failure to keep such payment method details current and accurate may result in DPS’s failure to collect applicable fees, and may result in the suspension or termination of your access to the Services.

                            </p>


                        </div>
                        <div>
                            <strong>Refunds?</strong>
                            <p>
                                You may request a refund of unused postage through DPS, but such refunds are subject to the policies of the applicable Courier. If a Courier refuses to issue a refund, DPS will not be responsible for refunding any postage amounts paid to such Courier. DPS may, at its discretion, issue a refund of any fees paid in addition to the Courier’s postage fees. If a refund is issued, it will be provided no earlier than 7 days after the order date. Any postage or fee refund claims must be filed within 30 days after the postage or order has been created.
                            </p>

                            <p>
                                DPS shall not be responsible for any local customs charges, import taxes or duties, or any similar charge(s) incurred through the carriage and/or delivery of any shipments and you must satisfy yourself as to whether any of these charges will become due, and if so in what amounts, before completing an order with us. If any such charges become due as a result of a carriage and/or delivery of a shipment on your behalf and are charged to us by any competent authority you agree to reimburse us fully in respect of the same within 7 days of our demand.
                            </p>
                        </div>
                        <div>
                            <strong>What if I want to stop using DPS?</strong>
                            <br />
                            <p>
                                You’re free to stop using DPS at any time and can do so by contacting us at <a href="mailto:support@dps.io">support@dps.io</a> ; please refer to our <Link to="privacy">Privacy Policy</Link>, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services.
                            </p>
                            <p>
                                If automatic renewals are allowed in your state, you may choose for services to automatically renew at the end of a fixed service period. We will attempt to remind you by email, or other reasonable manner, before any services renew for a new term. We may automatically renew your services at the end of the current service period and charge you the then current price for the renewal term, unless you have chosen to cancel the services as described above. You must cancel the services before the renewal date to avoid being billed for the renewal.
                            </p>
                            <p>
                                DPS is also allowed to terminate (or suspend access to) your use of the Services or your account, for any reason in our discretion, including your breach of these Terms. Upon deactivation of your account for any reason, you shall immediately pay to us all of your outstanding unpaid invoices. DPS has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms.
                            </p>
                            <p>
                                Prior to deactivating your account, it is important to understand that account termination may result in destruction of any Content associated with your account. We will try to provide advance notice to you prior to our terminating your account so that you are able to retrieve any important User Submissions you may have stored in your account (to the extent allowed by law and these Terms), but we may not do so if we determine it would be impractical, illegal, not in the interest of someone’s safety or security, or otherwise harmful to the rights or property of DPS. If you have deactivated your account by mistake, contact us immediately at <a href="mailto:support@dps.io">support@dps.io</a>– we will try to help. We can’t promise that we can recover or restore anything.
                            </p>
                            <p>
                                <u><i>Trial-Period Offers</i></u> f you are taking part in any trial-period offer, you must cancel the trial service(s) by the end of the trial period to avoid incurring new charges, unless we notify you otherwise. If you do not cancel the trial service(s) by the end of the trial period, we may charge you for the service(s).
                            </p>
                            <p>
                                Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us.
                            </p>
                            <p>
                                <u><i>Warranty Disclaimer.</i></u> Neither DPS nor its licensors or suppliers makes any representations or warranties concerning any content contained in or accessed through the Services, and we will not be responsible or liable for (i) the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services, (ii) any failure or delay in purchasing postage or delivering shipments (including, without limitation, any failure or delay arising from the use of or inability to use any part of the Services), or (iii) the performance or non-performance by a Courier or any other provider of postage or delivery services arranged through the Services. We (and our licensors and suppliers) make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through the Services. Products and services purchased or offered (whether or not following such recommendations and suggestions) through the Services are provided “AS IS” and without any warranty of any kind from DPS or others (unless, with respect to such others only, provided expressly and unambiguously in writing by a designated third party for a specific product). THE SERVICES AND CONTENT ARE PROVIDED BY DPS (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                            </p>
                            <p>
                                <u><i>Limitation of Liability.</i></u> TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL DPS (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO DPS IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, (C) ANY ACTS, OMISSIONS, OR FAILURES OF A COURIER, OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
                            </p>
                            <p>
                                <u><i>Indemnity.</i></u> To the fullest extent allowed by applicable law, you agree to indemnify and hold DPS, its affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any third party claims relating to (a) your use of the Services (including any actions taken by a third party using your account), (b) your violation of these Terms, and (c) your violation of a Courier’s rules, policies, terms of service, or any agreement between you and a Courier. In the event of such a claim, suit, or action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have for your account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification obligations hereunder).
                            </p>
                            <p>
                                <u><i>Assignment.</i></u> You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without DPS’s prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.
                            </p>
                            <p>
                                <u><i>Choice of Law; Arbitration.</i></u>
                                These Terms are governed by and will be construed under the laws of the State of Delaware, without regard to the conflicts of law’s provisions thereof. Any dispute arising from or relating to the subject matter of these Terms shall be finally settled in Sussex County, Delaware, in English, in accordance with the Streamlined Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc. (“JAMS”) then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction. Notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction. For all purposes of this Agreement, the parties consent to exclusive jurisdiction and venue in the state or federal courts located in, respectively, Sussex County, Delaware. Any arbitration under these Terms will take place on an individual basis: class arbitrations and class actions are not permitted. YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS, YOU AND DPS ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.

                            </p>
                            <p>
                                <u><i>Miscellaneous</i></u> You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, provided that the DPS may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable. You and DPS agree that these Terms (including any applicable Order Form(s)) are the complete and exclusive statement of the mutual understanding between you and DPS, and that it supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of DPS, and you do not have any authority of any kind to bind DPS in any respect whatsoever. You and DPS agree there are no third-party beneficiaries intended under these Terms.
                            </p>
                        </div>

                    </div>

                </div>
            </Paper>
        </div>
    );
}
export default TermsAndCondition;
