import { PostTrackApi } from "redux/Api/TrackingWizard";
import { SET_POST_TRACK_ERROR } from "redux/Type";
import { SET_POST_TRACK_SUCCESS } from "redux/Type";
import { SET_POST_TRACK } from "redux/Type";
import { setLoaderStatus } from "./common";

export const setActPostTrackRes = (data) => {
  return {
    type: SET_POST_TRACK,
    data,
  };
};

export const setActPostTrackSuccess = (status) => {
  return {
    type: SET_POST_TRACK_SUCCESS,
    status,
  };
};

export const setActPostTrackError = (status) => {
  return {
    type: SET_POST_TRACK_ERROR,
    status,
  };
};

export const actPostTrack = (data) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  return PostTrackApi(data).then((res) => {
    if (res.status === 200) {
      dispatch(setLoaderStatus(false));
      dispatch(setActPostTrackRes(res.data));
      dispatch(setActPostTrackSuccess(true));
      dispatch(setLoaderStatus(false));
    } else {
      dispatch(setLoaderStatus(false));
      dispatch(setActPostTrackError(true));
    }
  });
};
