import VuiBox from "components/VuiBox";
// @mui material components
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import VuiTypography from "components/VuiTypography";
import { useEffect, useState } from "react";
import { RadioGroup } from "@mui/material";
import TokenIcon from '@mui/icons-material/Token';
import dps_logo from "../../../../../../assets/images/dps.png";


const BlockChainRecordManagement = (props) => {
  const { prices } = props;
  const [RecordManagement, setRecordManagement] = useState(
    props.RecordManagementValue?.Code || "1Year"
  );

  useEffect(() => {
    if (props.RecordManagementValue?.Code) {
      setRecordManagement(props.RecordManagementValue?.Code);
    } else {
      var data = _.find(prices || [], { Code: "1Year" });
      props.setActRecordManagement(data);
    }
  }, [props.RecordManagementValue, prices]);

  const handleChange = (e) => {
    setRecordManagement(e.target.value);
  };

  const HandleRadioClick = (data) => {
    props.setActRecordManagement(data);
  };

  return (
    <VuiBox
      mt={1}
      color="white"
      bgColor="white"
      shadow="lg"
      opacity={1}
      p={2}
      style={{ borderRadius: "10px", height: "260px" }}
    >
      <RadioGroup name="RecordManagement" value={RecordManagement} onChange={handleChange}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <img src={dps_logo} style={{ width: 90 }} />
            {/* <TokenIcon color="dark" style={{width:'4rem', height:'4rem'}}/> */}
          </Grid>
          <Grid xs={12} pl={3.3}>
            <VuiTypography variant="lg" color="dark">
              Record Management
            </VuiTypography>
          </Grid>
        </Grid>
        {_.map(prices || [], (price, i) => (
          <Grid container>
            <Grid item xs={12} md={12}>
              <Radio
                value={price.Code}
                onClick={() => {
                  HandleRadioClick(price);
                }}
              />{" "}
              <span style={{ color: "#344747", fontSize: 15 }}>
                {price?.Name}

                &nbsp;&nbsp;&nbsp;
                <span>
                  {
                    price?.Price <= 0 ? "Included" : `$${price?.Price}`
                  }
                </span>
              </span>
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <span style={{ color: "#344747", fontSize: 15 }}>{`${price?.Price === 0 ? "included" : "$" + price?.Price
                }`}</span>
            </Grid> */}
          </Grid>
        ))}
      </RadioGroup>
    </VuiBox>
  );
};
export default BlockChainRecordManagement;
