

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// DPS UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";

// DPS UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import CircularProgress from '@mui/material/CircularProgress';


function SignUpVerifyForm(props) {
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        if (code == '' || code == null || code == undefined) {
            setCodeError('This field is required!');
            return;
        }
        props.onSubmit(code);
    }

    return (
        <form onSubmit={onSubmit} autoComplete="off" noValidate={true}>
            <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
                <VuiBox
                    component="div"
                    borderRadius="inherit"
                    className="SUMAN"
                    p="45px"
                    style={{ minWidth: 380 }}
                    sx={({ palette: { secondary } }) => ({
                        backgroundColor: secondary.focus,
                    })}
                >
                    <VuiTypography
                        color="white"
                        fontWeight="bold"
                        textAlign="center"
                        mb="24px"
                        sx={({ typography: { size } }) => ({
                            fontSize: size.lg,
                        })}
                    >
                        Confirm your account
                    </VuiTypography>

                    <VuiTypography
                        color="white"
                        textAlign="center"
                        fontWeight="bold"
                        mb="24px">
                        We have sent a code by email to {props?.email}
                        <br />
                        Enter it below to confirm your account.
                    </VuiTypography>

                    <VuiBox mb={2}>
                        <VuiBox mb={1} ml={0.5}>
                            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                                Verification Code
                            </VuiTypography>
                        </VuiBox>
                        <GradientBorder
                            minWidth="100%"
                            borderRadius={borders.borderRadius.lg}
                            padding="1px"
                            backgroundImage={radialGradient(
                                palette.gradients.borderLight.main,
                                palette.gradients.borderLight.state,
                                palette.gradients.borderLight.angle
                            )}
                        >
                            <VuiInput
                                type="text"
                                autoComplete="off"
                                placeholder="Your verification code..."
                                sx={({ typography: { size } }) => ({
                                    fontSize: size.sm,
                                })}
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </GradientBorder>
                        <span style={{ display: 'block', color: 'red', fontSize: 11, fontWeight: 'bold', paddingLeft: 5 }}>{codeError}</span>
                    </VuiBox>

                    <VuiBox mb={2}>
                        <span style={{ display: 'block', color: 'red', fontSize: 11, fontWeight: 'bold', paddingLeft: 5 }}>{props?.cognitoError}</span>
                    </VuiBox>


                    <VuiBox mt={4} mb={1}>
                        <VuiButton color="info" fullWidth type="submit" >
                            VERIFY {props?.loading ? <CircularProgress size={16} style={{ marginLeft: 8 }} color="error" /> : ""}
                        </VuiButton>
                    </VuiBox>

                    <VuiBox mt={3} textAlign="center">
                        <VuiTypography variant="button" color="white" fontWeight="regular">
                            Didn't receive a code?{" "}
                            <VuiTypography
                                variant="button"
                                color="white"
                                fontWeight="medium"
                                style={{ cursor: 'pointer' }}
                                onClick={props?.onResendCode}
                            >
                                Send a new code
                            </VuiTypography>
                        </VuiTypography>
                    </VuiBox>
                    <VuiBox mt={3} textAlign="center">
                        <VuiTypography variant="button" color="white" fontWeight="regular">
                            Aldready verified?{" "}
                            <VuiTypography
                                component={Link}
                                to="/sign-in"
                                variant="button"
                                color="white"
                                fontWeight="medium"
                                style={{ cursor: 'pointer' }}
                                onClick={props?.onResendCode}
                            >
                                login here
                            </VuiTypography>
                        </VuiTypography>
                    </VuiBox>
                </VuiBox>
            </GradientBorder>
        </form>
    )
}

export default SignUpVerifyForm;
