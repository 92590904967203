import { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import VuiBox from "components/VuiBox";
import VuiSelect from "components/VuiSelect";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";
import ReactHtmlParser from "react-html-parser";
import dps_usps_logo from "../../../assets/images/dps_usps_logo.png";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

const containers = [
  { value: "USPS FLAT RATE BOX", label: "USPS FLAT RATE PACKAGING" },
  { value: "CUSTOM PACKAGE", label: "CUSTOM OR VARIABLE PACKAGING" },
  { value: "BOX OR RIGID PACKAGING", label: "BOX OR RIGID PACKAGING" },
];

function RateCalculator() {
  const [rates, setRates] = useState([]);
  const [container, setContainer] = useState();
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  const [weight, setWeight] = useState(0);
  const [from, setFrom] = useState("");
  const [loading, setLoading] = useState(false);
  const [to, setTo] = useState("");

  useEffect(() => {
    setContainer("USPS FLAT RATE BOX");
  }, []);

  const getShippingRates = () => {
    if (validate()) {
      return;
    }

    let payload = null;
    if (container == "USPS FLAT RATE BOX") {
      payload = {
        DestinationZip: to,
        OriginZip: from,
        Weight: "10",
        PriceType: "retail",
        Unit: "oz",
        PackageType: "USPS FLAT RATE BOX",
      };
    } else if (container == "CUSTOM PACKAGE") {
      payload = {
        DestinationZip: to,
        OriginZip: from,
        PriceType: "retail",
        Unit: "oz",
        PackageType: "CUSTOM PACKAGE",
        Weight: weight,
        Width: width,
        Length: length,
        Height: height,
      };
    } else {
      payload = {
        DestinationZip: to,
        OriginZip: from,
        PriceType: "retail",
        Unit: "oz",
        PackageType: "BOX OR RIGID PACKAGING",
        Weight: weight,
        Width: width,
        Length: length,
        Height: height,
      };
    }
    let headers = {
      "Content-Type": "application/json",
    };
    let url = `${process.env.REACT_APP_API_BASE_URL}/postage/latest-rates`;

    setRates([]);
    setLoading(true);
    axios
      .post(url, payload, {
        headers: headers,
      })
      .then((response) => {
        console.log("response.data > ", response.data);
        setRates(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [fromZipError, setFromZipError] = useState('');
  const [toZipError, setToZipError] = useState('');
  const [dimenstionError, setDimenstionError] = useState('');

  const validate = () => {
    let flag = false;
    setFromZipError("");
    setToZipError("");
    setDimenstionError("");

    if (from == "") {
      setFromZipError("This field is required!");
      flag = true;
    }
    if (to == "") {
      setToZipError("This field is required!");
      flag = true;
    }
    if (container != 'USPS FLAT RATE BOX') {
      if (height <= 0 || width <= 0 || length <= 0 || weight <= 0) {
        setDimenstionError("Dimestions and weght are required!");
        flag = true;
      }
    }
    return flag;
  }

  const [pound, setPound] = useState(0);
  const [ounce, setOunce] = useState(0);

  const handleOunceChange = (e) => {
    let o = 0;
    if (e.target.value != "") {
      o = parseFloat(e.target.value);
    }
    setOunce(o);
    let _weight = pound * 16;
    _weight = _weight + o;
    // setFieldValue("packageWeight", weight);
    setWeight(_weight);
  }
  const handlePoundChange = (e) => {
    let p = 0;
    if (e.target.value != "") {
      p = parseFloat(e.target.value);
    }
    setPound(p);
    let _weight = p * 16;
    _weight = _weight + ounce;
    // setFieldValue("packageWeight", weight);
    setWeight(_weight);
  }

  return (
    <VuiBox pt={3} pb={10} style={{ width: "70%", margin: "0px auto" }}>
      <VuiBox pt={3} pb={10} style={{ width: "55%", margin: "0px auto" }}>
        <Grid container justifyContent="space-between" rowSpacing={1} spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <VuiTypography fontSize="small">From (USA Only)</VuiTypography>
            <VuiInput
              pnaceholder="From"
              onChange={(e) => {
                setFrom(e.target.value);
              }}
            />
            <span style={{ color: 'red' }}>{fromZipError}</span>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <VuiTypography fontSize="small">To (USA Only)</VuiTypography>
            <VuiInput
              //   placeholder="To"
              onChange={(e) => {
                setTo(e.target.value);
              }}
            />
            <span style={{ color: 'red' }}>{toZipError}</span>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <VuiTypography fontSize="small">Type of Packaging</VuiTypography>
            <VuiSelect
              defaultValue={{ value: "USPS FLAT RATE BOX", label: "USPS FLAT RATE PACKAGING" }}
              options={containers}
              onChange={(e) => {
                setDimenstionError("");
                setContainer(e.value);
              }}
              size="small"
            />
          </Grid>
          {container != "USPS FLAT RATE BOX" ? (
            <Grid item xs={12} md={2.2} lg={2.2}>
              <VuiTypography fontSize="small">Length (In)</VuiTypography>
              <VuiInput
                pnaceholder="Length"
                type="number"
                onChange={(e) => {
                  setLength(e.target.value);
                }}
              />
            </Grid>
          ) : null}
          {container != "USPS FLAT RATE BOX" ? (
            <Grid item xs={12} md={2.2} lg={2.2}>
              <VuiTypography fontSize="small">Width (In)</VuiTypography>
              <VuiInput
                pnaceholder="Width"
                type="number"
                onChange={(e) => {
                  setWidth(e.target.value);
                }}
              />
            </Grid>
          ) : null}
          {container != "USPS FLAT RATE BOX" ? (
            <Grid item xs={12} md={2.2} lg={2.2}>
              <VuiTypography fontSize="small">Height (In)</VuiTypography>
              <VuiInput
                pnaceholder="Height"
                type="number"
                onChange={(e) => {
                  setHeight(e.target.value);
                }}
              />
            </Grid>
          ) : null}
          {container != "USPS FLAT RATE BOX" ? (
            <Grid item xs={12} md={4.4} lg={4.4}>
              <VuiTypography fontSize="small">Weight (LB, OZ)</VuiTypography>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <VuiInput
                  pnaceholder="Pounds"
                  type="number"
                  onChange={handlePoundChange}
                  min={0}
                // onChange={(e) => {
                //   setWeight(e.target.value);
                // }}
                />
                <div style={{ fontWeight: 'bold', marginLeft: 5, marginRight: 5 }}>+</div>
                <VuiInput
                  pnaceholder="Ounces"
                  type="number"
                  onChange={handleOunceChange}
                  min={0}
                // onChange={(e) => {
                //   setWeight(e.target.value);
                // }}
                />
              </div>

            </Grid>
          ) : null}

          <Grid item xs={12} md={12} lg={12}>
            <span style={{ color: 'red' }}>{dimenstionError}</span>
          </Grid>



          <Grid item xs={12} md={12} lg={12}>
            <VuiButton
              disabled={loading}
              color="primary"
              variant={"gradient"}
              onClick={getShippingRates}
              style={{ float: "right" }}
            >
              {loading ? <CircularProgress color="error" size={16} /> : null}
              &nbsp;&nbsp;See Shipping Rates
            </VuiButton>
          </Grid>
        </Grid>
      </VuiBox>
      <Grid container justifyContent="center" rowSpacing={4} spacing={4}>
        {_.map(rates || [], (rate, index) => (
          <Grid item xs={12} md={6} lg={6}>
            <Card style={{ padding: 5 }}>
              <CardHeader
                title={
                  <strong style={{ fontSize: 16 }}>
                    <img src={dps_usps_logo} alt="dps-logo" style={{ width: 35 }} />
                    {/* {key},{" "} */}
                    {/* {ReactHtmlParser(rate?.MailingService)} */}
                    {ReactHtmlParser(rate?.Title ? rate?.Title : rate?.MailingService)}
                  </strong>
                }
              ></CardHeader>
              <CardContent style={{ fontSize: 15 }}>
                <div style={{ fontSize: "10px", color: "grey", fontWeight: "bold" }}>
                  Zone {rate?.Zone}, Commercial Pricing
                </div>
                {
                  rate?.DeliveryEstimate?.Location?.ScheduleDeliveryDate ? (
                    <h6 style={{ fontSize: 14 }}>Estimated delivery by {new Date(rate?.DeliveryEstimate?.Location?.ScheduleDeliveryDate).toDateString()} if shipped today</h6>
                  ) : rate?.DeliveryEstimate?.ScheduleDeliveryDate ? (
                    <h6 style={{ fontSize: 14 }}>Estimated delivery by {new Date(rate?.DeliveryEstimate?.ScheduleDeliveryDate).toDateString()} if shipped today</h6>
                  ) : null
                }
                <div style={{ fontSize: 12, minHeight: 50 }}>
                  <span>{rate?.DisplayName?.info}</span>
                  <br />
                  <Typography variant="caption">{rate?.SpecialServiceTitle} </Typography>
                </div>
                <div
                  style={{
                    border: "1px solid #1b221b",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "5px",
                    alignItems: "center",
                    backgroundColor: "#3d7c3d5c",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ color: "green", fontWeight: "bold", fontSize: 20 }}>
                      Save{" "}
                      {Math.round(((rate?.RegularPrice - parseFloat(rate?.DPSPrice)) / rate?.RegularPrice) * 100)}%
                    </span>
                    <span style={{ textDecoration: "line-through" }}>
                      ${rate?.RegularPrice}{" "}
                      {parseFloat(rate?.RegularPrice) === parseFloat(rate?.DPSPrice)
                        ? "commercial"
                        : "retail"}
                    </span>
                  </div>
                  <div>
                    <strong style={{ display: "block", textAlign: "right" }}>
                      ${rate?.DPSPrice}
                    </strong>
                  </div>
                </div>
              </CardContent>
              {/* <CardActions style={{ display: 'flex', justifyContent: 'end' }}>
                <VuiButton color="primary">
                  Ship Now
                </VuiButton>
              </CardActions> */}
            </Card>
          </Grid>
        ))}
      </Grid>
    </VuiBox >
  );
}
export default RateCalculator;
