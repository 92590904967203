import React from "react";
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

function Row(props) {
    const { row } = props;
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="left">
                    <Button onClick={() => {
                        props?.onRowClick(row)
                    }} style={{ textTransform: 'capitalize', paddingLeft: 0 }}>{row?.PersonName}</Button>
                </TableCell>
                <TableCell>{row?.CompanyName}</TableCell>
                <TableCell>{row?.AddressLine1}</TableCell>
                <TableCell>{row?.AddressLine2}</TableCell>
                <TableCell>{row?.City}</TableCell>
                <TableCell>{row?.State}</TableCell>
                <TableCell>{row?.Zip}</TableCell>
                <TableCell>
                    <div title={row?.NftWalletAddress} style={{ maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row?.NftWalletAddress}
                    </div>
                </TableCell>
            </TableRow>

        </React.Fragment>
    )
}

export default Row;