import { combineReducers } from "redux";
import TestStore from "./test";
import ProfileStore from "./profile";
import WizardStore from "./wizard";
import CommonStore from "./common";
import TrackingWizardStore from "./TrackingWizard";
import NftStore from "./nft";
import VerifyStore from "./verify"

const rootReducer = combineReducers({
  CommonStore,
  TestStore,
  ProfileStore,
  WizardStore,
  TrackingWizardStore,
  NftStore,
  VerifyStore
});
export default rootReducer;
