

import checkout from "layouts/applications/wizard/schemas/form";
import moment from "moment";
const dateFormat = "MM-DD-YYYY";
var antd_default_date = new Date();
antd_default_date.setDate(antd_default_date.getDate() + 5);

const {
  formField: {
    documentName,
    documentMedia,
    documentDescription,
    documentCategory,
    documentItemId,
    documentTag,
    caseName,
    mailingName,
    senderName,
    companyName,
    address1,
    address2,
    city,
    state,
    zip,
    recipientName,
    recCompanyName,
    recAddress1,
    recAddress2,
    recCity,
    recState,
    recZip,
    recWallet,
    flatRate,
    packageWeight,
    unit,
    customLength,
    customWidth,
    customHeight,
    softPackLength,
    softPackWidth,
    softPackHeight,
    shipDate,
    IsNFT
  },
} = checkout;

export default {
  [caseName.name]: "",
  [mailingName.name]: "",
  [senderName.name]: "",
  [companyName.name]: "",
  [address1.name]: "",
  [address2.name]: "",
  [city.name]: "",
  [state.name]: "",
  [zip.name]: "",
  [recipientName.name]: "",
  [recCompanyName.name]: "",
  [recAddress1.name]: "",
  [recAddress2.name]: "",
  [recCity.name]: "",
  [recState.name]: "",
  [recZip.name]: "",
  [recWallet.name]: "",
  [flatRate.name]: "",
  [packageWeight.name]: 0,
  [unit.name]: "OZ",
  [customHeight.name]: 0,
  [customWidth.name]: 0,
  [customLength.name]: 0,
  [softPackHeight.name]: 0,
  [softPackLength.name]: 0,
  [softPackWidth.name]: 0,
  ["phone"]: "",
  ["country"]: "",
  ["topicHash"]: "",
  ["documentFileName"]: "",
  [documentMedia.name]: "",
  [documentName.name]: "",
  [documentDescription.name]: "",
  [documentCategory.name]: "",
  [documentItemId.name]: "",
  [documentTag.name]: "",
  ["PackageContent"]: "Standard",
  ["IsHazardous"]: false,
  ["HazardousMaterial"]: [],
  [shipDate.name]: "",
  // [shipDate.name]: moment(antd_default_date).format("MM/DD/YYYY"),
  ["orderChange"]: false,
  ["jobHash"]: "",
  ["packageType"]: "",
  ["activeStep"]: "",
  ["IsNFT"]: false,
};
