import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { FaBoxOpen, FaPrint, FaRegFilePdf } from "react-icons/fa";
import { SiChainlink } from "react-icons/si";


import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import moment from "moment";

import polygon_logo from "../../../assets/images/logos/polygon.png";

function Row(props) {
    const { row } = props;
    const [tableRowOpen, setTableRowOpen] = useState(false);
    const [actionButtonOpen, setActionButtonOpen] = useState(false);
    const actionRef = React.useRef(null);

    const handleActionToggle = () => {
        setActionButtonOpen((prevOpen) => !prevOpen);
    };

    const handleActionClose = (event) => {
        if (actionRef.current && actionRef.current.contains(event.target)) {
            return;
        }

        setActionButtonOpen(false);
    };

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell style={{ paddingRight: 0 }}>
                    <IconButton
                        size="small"
                        onClick={() => setTableRowOpen(!tableRowOpen)}
                    >
                        {tableRowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell style={{ paddingLeft: 0 }}>
                    <div style={{ display: 'flex' }}>
                        {row?.JobType == "epostage-nft" ? (<SiChainlink size={25} color="#7b3fe4" />) :
                            row?.JobType == "epostage-package" ? (<FaBoxOpen size={25} color="#FDD835" />) :
                                row?.JobType == "epostage-doc" ? (<FaRegFilePdf size={25} color="#2c56a5" />) : (<FaPrint size={25} color="#E57373" />)}

                        <div title={row?.TopicName} style={{ textTransform: 'capitalize', paddingLeft: 5, maxWidth: 130, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {row.TopicName}
                        </div>
                    </div>

                </TableCell>
                {/* <TableCell>
                    <div title={row?.FromName} style={{ textTransform: 'capitalize', maxWidth: 130, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row?.FromName}
                    </div>
                </TableCell> */}
                <TableCell>
                    <div title={row?.ToName} style={{ textTransform: 'capitalize', maxWidth: 130, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row?.ToName}
                    </div>
                </TableCell>
                <TableCell>
                    {row.PostageType}
                </TableCell>
                <TableCell>
                    <div title={row?.LastStatus} style={{ maxWidth: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row.LastStatus}
                    </div>
                </TableCell>
                <TableCell>
                    {/* {
                        row?.DeliveryDate != null && row?.DeliveryDate != "" && row?.DeliveryDate != "None" ? moment(row?.DeliveryDate).format("MMM DD, YYYY hh:mm A") :
                            row?.MailDate != null && row?.MailDate != "" && row?.MailDate != "None" ? moment(row?.MailDate).format("MMM DD, YYYY hh:mm A") :
                                row?.OrderedDate != null && row?.OrderedDate != "" && row?.OrderedDate != "None" ? moment(row?.OrderedDate).format("MMM DD, YYYY hh:mm A") : null
                    } */}

                    {
                        row?.OrderedDate != null && row?.OrderedDate != "" && row?.OrderedDate != "None" ? moment(row?.OrderedDate).format("MMM DD, YYYY hh:mm A") : ""
                    }
                </TableCell>
                <TableCell>
                    {(row?.Blockchain === "80001" || row?.Blockchain === "137") && !row?.IsTransferred || (!row?.IsCanceled && row?.Mode === "Live") ? (
                        <>
                            <ButtonGroup variant="contained" ref={actionRef} style={{ boxShadow: 'none' }} size="small">
                                <Button size="small" style={{ paddingLeft: 10, paddingRight: 10 }}>Action</Button>
                                <Button
                                    size="small"
                                    aria-controls={actionButtonOpen ? 'split-button-menu' : undefined}
                                    aria-expanded={actionButtonOpen ? 'true' : undefined}
                                    aria-label="select merge strategy"
                                    aria-haspopup="menu"
                                    onClick={handleActionToggle}
                                    style={{ paddingLeft: 5, paddingRight: 5 }}
                                >
                                    <ArrowDropDownIcon />
                                </Button>
                            </ButtonGroup>

                            <Popper
                                sx={{
                                    zIndex: 1,
                                }}
                                open={actionButtonOpen}
                                anchorEl={actionRef.current}
                                role={undefined}
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}
                                    >
                                        <Paper style={{ minHeight: 50 }}>
                                            <ClickAwayListener onClickAway={handleActionClose}>
                                                <MenuList id="split-button-menu" autoFocusItem>
                                                    {(row?.Blockchain === "80001" || row?.Blockchain === "137") && !row?.IsTransferred ? (
                                                        <MenuItem onClick={() => {
                                                            props?.onRowClick(row, 'transfer_nft')
                                                        }}>Transfer</MenuItem>
                                                    ) : null}
                                                    {row?.IsCancellable ? (
                                                        <MenuItem onClick={() => {
                                                            props?.onRowClick(row, 'cancel')
                                                        }}>Cancel</MenuItem>
                                                    ) : null}

                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}

                            </Popper>
                        </>
                    ) : null
                    }

                </TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: 'rgb(204 204 204 / 11%)' }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={tableRowOpen} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Grid container spacing={1}>
                                <Grid item md={4}>
                                    <div style={{ border: '1px solid #ccc', borderRadius: 5, padding: 10, minHeight: 300 }}>
                                        <h6>Sender/Recipient Details</h6>
                                        <div>
                                            <div><b>Sender</b></div>
                                            <div>
                                                <b>Shipper Hash: </b> <span>{row?.ShipperHash}</span>
                                            </div>
                                            {row?.FromName.toUpperCase()} {row?.FromName ? <br /> : ""}
                                            {row?.FromCompany?.toUpperCase()}
                                            {row?.FromCompany ? <br /> : ""}
                                            {row?.FromAddressLine1}
                                            {row?.FromAddressLine1 ? <br /> : ""} {row?.FromAddressLine2}{" "}
                                            {row?.FromAddressLine2 ? <br /> : ""} {row?.FromCity}, {row?.FromState}{" "}
                                            {row?.FromZip}
                                        </div>
                                        <br />
                                        <div>
                                            <div><b>Recipient</b></div>
                                            {row?.ToName.toUpperCase()} {row?.ToName ? <br /> : ""}
                                            {row?.ToCompany?.toUpperCase()}
                                            {row?.ToCompany ? <br /> : ""}
                                            {row?.ToAddressLine1}
                                            {row?.ToAddressLine1 ? <br /> : ""} {row?.ToAddressLine2}{" "}
                                            {row?.ToAddressLine2 ? <br /> : ""} {row?.ToCity}, {row?.ToState} {row?.ToZip}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={4}>
                                    <div style={{ border: '1px solid #ccc', borderRadius: 5, padding: 10, minHeight: 300 }}>
                                        <h6>Postage Details</h6>
                                        <div>
                                            <table className="table-small1 table-bordered1" style={{ width: '100%' }} cellSpacing={1} cellPadding={0}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: 115 }}>Postage Token</td>
                                                        <td style={{ width: 10 }}>:</td>
                                                        <td>{row?.JobHash}</td>
                                                    </tr>
                                                    {row?.JobType == "print2mail" ? (
                                                        <tr>
                                                            <td style={{ width: 95 }}>Job ID</td>
                                                            <td style={{ width: 10 }}>:</td>
                                                            <td>{row?.JobId}</td>
                                                        </tr>) : null
                                                    }

                                                    {
                                                        row?.DocumentHash ? (
                                                            <tr>
                                                                <td style={{ width: 95 }}>Document ID</td>
                                                                <td style={{ width: 10 }}>:</td>
                                                                <td>{row?.DocumentHash}</td>
                                                            </tr>
                                                        ) : null
                                                    }

                                                    <tr><td>Postage</td><td>:</td><td>{row?.PostageType}</td></tr>
                                                    {
                                                        row?.JobType != 'print2mail' ? (
                                                            <tr><td>Container</td><td>:</td><td>{row?.Container}</td></tr>
                                                        ) : null
                                                    }

                                                    <tr>
                                                        <td>Weight</td>
                                                        <td>:</td>
                                                        <td>
                                                            {
                                                                row?.Weight >= 16 ? (
                                                                    `${parseInt(row?.Weight / 16)} lb ${row?.Weight - parseInt(row?.Weight / 16) * 16} oz`
                                                                ) : (
                                                                    `${row?.Weight} oz`
                                                                )
                                                            }

                                                        </td>
                                                    </tr>
                                                    {
                                                        row?.Dimension ? (
                                                            <tr>
                                                                <td>Dimensions</td>
                                                                <td>:</td>
                                                                <td>
                                                                    {row?.Dimension?.Length}in x&nbsp;
                                                                    {row?.Dimension?.Width}in x&nbsp;
                                                                    {row?.Dimension?.Height}in
                                                                </td>
                                                            </tr>
                                                        ) : null
                                                    }

                                                    {
                                                        row?.PageCount ? (
                                                            <tr> <td>Page Count</td><td>:</td><td>{row?.PageCount}</td></tr>
                                                        ) : null
                                                    }



                                                    {
                                                        row?.JobType == 'print2mail' ? (
                                                            <tr>
                                                                <td>Return Envelope</td>
                                                                <td>:</td>
                                                                <td>
                                                                    {
                                                                        row?.ReturnEnvelope ? "Yes" : "No"
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ) : null
                                                    }

                                                    {
                                                        row?.JobType == 'print2mail' ? (
                                                            <tr>
                                                                <td>Color</td>
                                                                <td>:</td>
                                                                <td>
                                                                    {
                                                                        row?.Color ? "Color" : "Black & White"
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ) : null
                                                    }

                                                    {
                                                        row?.JobType == 'print2mail' ? (
                                                            <tr>
                                                                <td>Print Type</td>
                                                                <td>:</td>
                                                                <td>
                                                                    {row?.PrintType}
                                                                </td>
                                                            </tr>
                                                        ) : null
                                                    }

                                                    {
                                                        row?.JobType != 'print2mail' ? (
                                                            <tr>
                                                                <td>Ship Date</td>
                                                                <td>:</td>
                                                                <td>
                                                                    {
                                                                        row?.ShipDate != null && row?.ShipDate != "" && row?.ShipDate != "None" ? moment(row?.ShipDate).format("MMM DD, YYYY") : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ) : null
                                                    }

                                                    <tr>
                                                        <td>Postage Cost</td>
                                                        <td>:</td>
                                                        <td>
                                                            ${row?.CustomerChargedPrice}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan={3}>
                                                            {row?.ApiService !== "Print2Mail" ? (
                                                                <>
                                                                    <Button onClick={() => {
                                                                        props?.onRowClick(row, 'view_label')
                                                                    }}>VIEW LABEL</Button>

                                                                    {/* <Button onClick={() => {
                                                                        props?.onRowClick(row, 'view_receipt')
                                                                    }}>VIEW RECEIPT</Button> */}
                                                                </>
                                                            ) : null}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item md={4}>
                                    <div style={{ border: '1px solid #ccc', borderRadius: 5, padding: 10, minHeight: 300 }}>
                                        <h6>Tracking Details</h6>
                                        <div>
                                            <table className="table-small1 table-bordered1" style={{ width: '100%' }} cellSpacing={1} cellPadding={0}>
                                                <tbody>
                                                    <tr>
                                                        <td>Order Date</td>
                                                        <td>:</td>
                                                        <td>
                                                            {
                                                                row?.OrderedDate != null && row?.OrderedDate != "" && row?.OrderedDate != "None" ? moment(row?.OrderedDate).format("MMM DD, YYYY hh:mm A") : null
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: 95 }}>Mail Date</td>
                                                        <td style={{ width: 10 }}>:</td>
                                                        <td>
                                                            {
                                                                row?.MailDate != null && row?.MailDate != "" && row?.MailDate != "None" ? moment(row?.MailDate).format("MMM DD, YYYY hh:mm A") : null
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Delivery Date</td>
                                                        <td>:</td>
                                                        <td>
                                                            {
                                                                row?.DeliveryDate != null && row?.DeliveryDate != "" && row?.DeliveryDate != "None" ? moment(row?.DeliveryDate).format("MMM DD, YYYY hh:mm A") : null
                                                            }
                                                        </td>
                                                    </tr>
                                                    {
                                                        row?.ArticleNumber && row?.PostageType != 'FC' ? (
                                                            <tr>
                                                                <td colSpan={3}>
                                                                    <span style={{ fontWeight: 'bold', fontSize: 12, display: 'block' }}>Article No. {row?.ArticleNumber}</span>

                                                                    <a href={`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${row?.ArticleNumber}`} target="_blank">
                                                                        Track Live on USPS
                                                                    </a>

                                                                </td>
                                                            </tr>
                                                        ) : null
                                                    }
                                                    {
                                                        row?.IsNft ? (
                                                            <tr>
                                                                <td colSpan={3}>
                                                                    <a href="#" onClick={() => { props?.onRowClick(row, 'verify_package') }}>Verify on Blockchain</a>
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            row?.DocumentPath ? (
                                                                <tr>
                                                                    <td colSpan={3}>
                                                                        <a href="#" onClick={() => { props?.onRowClick(row, 'view_certified_document') }}>View Certified Document</a>
                                                                    </td>
                                                                </tr>
                                                            ) : null
                                                        )
                                                    }
                                                    {row?.ComInfo?.S3Path ? (
                                                        <tr>
                                                            <td colSpan={3}>


                                                                <a href="#" onClick={() => { props?.onRowClick(row, 'certificate_of_mailing') }}>View Certificate of Mailing</a>

                                                            </td>
                                                        </tr>
                                                    ) : null}

                                                    {
                                                        row?.CodInfo?.S3Path ? (
                                                            <tr>
                                                                <td colSpan={3}>


                                                                    <a href="#" onClick={() => { props?.onRowClick(row, 'certificate_of_delivery') }}>View Certificate of Delivery</a>

                                                                </td>
                                                            </tr>
                                                        ) : null
                                                    }


                                                    {row?.DocumentPath ? (
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <a href="#" onClick={() => { props?.onRowClick(row, 'download_all_documents') }}>Download All Documents</a>
                                                            </td>
                                                        </tr>
                                                    ) : null}



                                                    {
                                                        row?.UspsTracking ? (
                                                            <tr>
                                                                <td colSpan={3}>
                                                                    <b>USPS Tracking</b>
                                                                    <div>{row?.UspsTracking}</div>
                                                                </td>
                                                            </tr>
                                                        ) : null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default Row;