import {
  SET_GET_DOCUMENT,
  SET_GET_DOCUMENT_ERROR,
  SET_GET_DOCUMENT_SUCCESS,
  SET_GET_NFT,
  SET_GET_NFT_ERROR,
  SET_GET_NFT_SUCCESS,
  SET_NFT_RESPONSE,
  TEST,
  TEST1,
} from "../Type";
import { PURGE } from "redux-persist";
const initialState = {
  documentRes: [],
  documentResSuccessCall: false,
  documentResFailsCall: false,
  NFTRes: [],
  documentResSuccessCall: false,
  documentResFailsCall: false,
  NFTCreateRes: null,
};

const NftStore = (state = initialState, { type, data, status }) => {
  switch (type) {
    case PURGE:
      return initialState;
    case SET_GET_DOCUMENT:
      return {
        ...state,
        documentRes: data,
      };
    case SET_GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentResSuccessCall: status,
      };
    case SET_GET_DOCUMENT_ERROR:
      return {
        ...state,
        documentResFailsCall: status,
      };
    case SET_GET_NFT:
      return {
        ...state,
        NFTRes: data,
      };
    case SET_GET_NFT_SUCCESS:
      return {
        ...state,
        NFTResSuccessCall: status,
      };
    case SET_GET_NFT_ERROR:
      return {
        ...state,
        NFTResFailsCall: status,
      };
    case SET_NFT_RESPONSE:
      return {
        ...state,
        NFTCreateRes: data,
      };
    default:
      return state;
  }
};
export default NftStore;
