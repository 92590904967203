

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// DPS UI  React icons
import { IoBuild } from "react-icons/io5";
import { IoCube } from "react-icons/io5";
import { useHistory } from "react-router-dom";

function DocumentType() {
  const history = useHistory();

  const customButtonStyles = ({
    functions: { pxToRem },
    borders: { borderWidth },
    palette: { transparent, info },
  }) => ({
    width: pxToRem(150),
    height: pxToRem(150),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      border: `${borderWidth[2]} solid ${info.main} !important`,
    },
  });

  return (
    <VuiBox mt={10} pb={5} mb={8} shadow="lg" borderRadius="lg">
      <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <VuiBox mb={1}>
          <VuiTypography variant="lg" fontWeight="bold" color="white">
            SELECT DOCUMENT TYPE
          </VuiTypography>
        </VuiBox>
      </VuiBox>
      <Grid container spacing={2} justifyContent="center">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={2.5}>
            <VuiBox textAlign="center">
              <VuiButton
                color="info"
                variant={"outlined"}
                onClick={() => {
                  history.push("#");
                }}
                sx={customButtonStyles}
              >
                <IoBuild size="54px" color="white" />
              </VuiButton>
              <VuiTypography variant="h6" color="dark">
                ORIGINAL DOCUMENT
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={2.5}>
            <VuiBox textAlign="center">
              <VuiButton
                color="info"
                variant={"outlined"}
                onClick={() => {
                  history.push("#");
                }}
                sx={customButtonStyles}
              >
                <IoCube size="54px" color="white" />
              </VuiButton>
              <VuiTypography variant="h6" color="dark">
                NOTARIZED
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
      </Grid>
    </VuiBox>
  );
}

export default DocumentType;
