

import checkout from "layouts/applications/verify-wizard/schemas/form";
import moment from "moment";
const dateFormat = "MM-DD-YYYY";
var antd_default_date = new Date();
antd_default_date.setDate(antd_default_date.getDate() + 5);

const {
  formField: {
    verifyName,
    nftId,
    verifyType
  },
} = checkout;

export default {
  [verifyName.name]: "",
  [nftId.name]: "",
  [verifyType.name]: ""
};
