import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import VuiTagInput from "components/VuiTagInput";
import { Typography } from "@mui/material";
import axios from "axios";


import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


const Input = styled("input")({
  display: "none",
});

function PrivateBlockChainDocumentUpload(props) {
  const {
    formField,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = props.formData;

  useEffect(() => {
    GetNFTCat();
  }, []);

  const [fileObj, setFileObj] = useState(null);
  const [SelectedFile, SetSelectedFile] = useState(null);
  const [SelectedFileType, SetSelectedFileType] = useState("");
  const [SelectedFileDetails, SetSelectedFileDetails] = useState("");

  const [documentName, setDocumentName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);

  const [documentItemId, setDocumentItemId] = useState("");
  const [documentCategory, setDocumentCategory] = useState("");
  const [CatOption, setCatOption] = useState([]);

  useEffect(() => {
    if (values?.documentTag) {
      setTags(values?.documentTag?.split(','));
    }
    if (values?.privateDocumentMedia) {
      setFileDetails(values?.privateDocumentMedia);
    }
  }, []);

  const setFileDetails = (file) => {
    SetSelectedFileType(file.type);
    SetSelectedFileDetails(
      `${file?.name} / ${parseFloat(file?.size / (1000 * 1000)).toFixed(2)} MB / ${file?.type}`
    );
    SetSelectedFile(URL.createObjectURL(file));
    setDocumentName(file.name);
    setFileObj(file);
    // setFieldValue("documentName", file.name);
    setFieldValue("privateDocumentMedia", file);
  }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setFileDetails(file)
    }
  };

  const updateTags = (newTags) => {
    setTags(newTags);
    setFieldValue("documentTag", newTags.join(","));
  };

  const GetNFTCat = (value) => {
    var url = `${process.env.REACT_APP_API_BASE_URL}/documents/category-lookup/${localStorage.ShipperHash}`;
    if (value) {
      url += `?Name=${value}`;
    }
    var headers = {
      Authorization: `Bearer ${localStorage.id_token}`,
      ShipperHash: localStorage.ShipperHash,
      "x-api-key": "6f40874a-9568-457f-8543-901fd614741e",
    };
    axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          var catList = [];
          res.data &&
            res.data.map((cat, i) => {
              catList.push({ label: cat.Name, value: cat.Name });
            });
          setCatOption(catList);
          console.log('catList > ', catList)
        }
      });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} textAlign="center">
        <Card sx={{ overflow: "visible", height: "100%" }} elevation={0}>
          {SelectedFileType?.includes("video") ? (
            <video height="180" controls>
              <source src={SelectedFile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <div>
              <b>UPLOAD ITEM</b>
              <div
                style={{
                  height: 235,
                  width: 200,
                  overflow: "hidden",
                  margin: "0 auto",
                  border: "1px dashed #314747",
                  borderRadius: "6px",
                  backgroundImage: `url(${SelectedFile})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                {/* <img src={SelectedFile} width="90%" height={"auto"} style={{ margin: "10px auto" }} /> */}
              </div>
            </div>
          )}
          <VuiBox mt={1}>
            <label htmlFor="contained-button-file">
              <Input
                id="contained-button-file"
                accept="image/*, video/*"
                onChange={onImageChange}
                type="file"
              />

              <VuiButton variant="contained" color="info" component="span">
                Choose
              </VuiButton>
              <VuiTypography
                mt={1}
                variant="h6"
                color={SelectedFileDetails ? "dark" : "error"}
                textAlign="center"
              >
                <span>{SelectedFileDetails ? SelectedFileDetails : errors.privateDocumentMedia}</span>
              </VuiTypography>
            </label>
          </VuiBox>
        </Card>
      </Grid>
      <Grid item xs={12} md={8}>
        <Card elevation={0}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <VuiBox p={1}>
                <div style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center', marginBottom: 15 }}>
                  ADD ITEM INFORMATION THAT WILL BE LINKED TO YOUR NFT / COA
                </div>


                <VuiTypography variant="div" color="dark" textAlign="center">
                  ITEM INFORMATION
                </VuiTypography>
              </VuiBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormField
                label={formField.documentName.label}
                name={formField.documentName.name}
                type={formField.documentName.type}
                value={values?.documentName}
                // value={documentName}
                disabled={values.orderChange}
                onChange={(e) => {
                  setDocumentName(e.target.value);
                  setFieldValue("documentName", e.target.value);
                }}
                placeholder={formField.documentName.placeholder}
                error={errors.documentName && touched.documentName}
                success={formField.documentName.length > 0 && !errors.documentName}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormField
                label={formField.documentDescription.label}
                name={formField.documentDescription.name}
                type={formField.documentDescription.type}
                value={values?.documentDescription}
                // value={description}
                disabled={values.orderChange}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setFieldValue("documentDescription", e.target.value);
                }}
                placeholder={formField.documentDescription.placeholder}
                error={errors.documentDescription && touched.documentDescription}
                success={formField.documentDescription.length > 0 && !errors.documentDescription}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="caption" style={{ fontWeight: 'bold' }}>Item Category</Typography>
              <Autocomplete
                freeSolo
                onChange={(event, value, reason, details) => {
                  setDocumentCategory(value);
                  setFieldValue("documentCategory", value);
                }}
                options={CatOption.map((option) => option.label)}
                renderInput={(params) => <TextField value={values?.documentCategory} onChange={(event) => {
                  setDocumentCategory(event.target.value);
                  setFieldValue("documentCategory", event.target.value);
                }} {...params} />}
              />
              <VuiTypography
                mt={0}
                variant="div"
                color={"error"}
                textAlign="center"
              >
                <span>{errors.documentCategory && touched.documentCategory ? errors.documentCategory : ""}</span>
                {/* <span>{!values?.documentCategory ? : errors.privateDocumentMedia}</span> */}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormField
                label={formField.documentItemId.label}
                name={formField.documentItemId.name}
                type={formField.documentItemId.type}
                // value={documentItemId}
                value={values?.documentItemId}
                disabled={values.orderChange}
                onChange={(e) => {
                  setDocumentItemId(e.target.value);
                  setFieldValue("documentItemId", e.target.value);
                }}
                placeholder={formField.documentItemId.placeholder}
                error={errors.documentItemId && touched.documentItemId}
                success={formField.documentItemId.length > 0 && !errors.documentItemId}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>

            <Grid item xs={12} md={12} mt={1.5}>
              <b>Item Tags</b>
              <VuiTagInput placeholder="Tags" tags={tags} onChange={updateTags} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default PrivateBlockChainDocumentUpload;
