import React, { useEffect } from 'react';


function CreditAddSuccessFailure() {

    useEffect(() => {
        localStorage.setItem("credit_added", "added");
        window.close();
    }, []);

    return (
        <h1>Thank you</h1>
    )
}
export default CreditAddSuccessFailure;
