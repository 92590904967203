import React, { useState } from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from "moment";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


import Collapse from '@mui/material/Collapse';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

function Row(props) {
    const { row } = props;

    const [tableRowOpen, setTableRowOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => setTableRowOpen(!tableRowOpen)}
                    >
                        {tableRowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <div title={row?.PrivateDocumentName} style={{ maxWidth: 130, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'flex' }}>
                        <Avatar alt={row?.PrivateDocumentName} sx={{ width: 24, height: 24 }} src={row?.PrivateNftPath} style={{ border: '1px solid grey' }} />
                        &nbsp;&nbsp;{row?.PrivateDocumentName}
                    </div>
                </TableCell>
                {/* <TableCell>
                    <div title={row?.PrivateTokenId} style={{ maxWidth: 100, cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={() => {
                        props.onRowClick(row?.PrivateTokenId, 'copy')
                    }}>
                        {row?.PrivateTokenId}
                    </div>
                </TableCell> */}
                <TableCell>
                    <div title={row?.PublicDocumentName} style={{ maxWidth: 180, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'flex' }}>
                        <Avatar alt={row?.PublicDocumentName} sx={{ width: 24, height: 24 }} src={row?.PublicCoaPath} style={{ border: '1px solid grey' }} />
                        &nbsp;&nbsp;{row?.PublicDocumentName}
                    </div>
                </TableCell>
                <TableCell align="center">
                    <a href={`https://polygonscan.com/tx/${row?.PublicTransactionHash}`} target="_blank">{row?.PublicTokenId}</a>
                </TableCell>
                <TableCell align="center">{row?.Blockchain}</TableCell>
                <TableCell>
                    {row?.PublicMintedDate != null && row?.PublicMintedDate != "None" && row?.PublicMintedDate != "" ? moment(row?.PublicMintedDate).format("MMM, DD YYYY") :
                        moment(row?.PrivateMintedDate).format("MMM, DD YYYY")
                    }
                </TableCell>
                <TableCell align="center">{row?.PublicLastStatus}</TableCell>
                <TableCell align="center">
                    {
                        !row?.PublicIsMinted ? (
                            <Button variant="contained" color="primary" size="small" style={{ paddingLeft: 10, paddingRight: 10, minWidth: 110 }} onClick={() => {
                                props?.onRowClick(row, 'mint_coa')
                            }}>Mint COA</Button>
                        ) :
                            row?.PublicIsMinted && (row?.PublicArticleNumber == '' || row?.PublicArticleNumber == null || row?.PublicArticleNumber == undefined) ? (
                                <Button variant="contained" color="primary" size="small" style={{ paddingLeft: 10, paddingRight: 10, minWidth: 110 }} onClick={() => {
                                    props?.onRowClick(row, 'ePostage')
                                }}>Send (ePostage)</Button>
                            ) :
                                row?.PublicIsMinted && row?.PublicArticleNumber != '' && row?.PublicLastStatus == 'Delivered' && !row?.PublicIsTransferred ? (
                                    <Button variant="contained" color="primary" size="small" style={{ paddingLeft: 10, paddingRight: 10, minWidth: 110 }} onClick={() => {
                                        props?.onRowClick(row, 'transfer')
                                    }}>Transfer</Button>
                                ) : "In Dispatch"
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={tableRowOpen} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Grid container>
                                <Grid item md={12}>
                                    <div style={{ border: '1px solid #ccc', borderRadius: 5, padding: 5 }}>
                                        <h6>More Details</h6>
                                        <div>
                                            <div>
                                                {row?.Description}
                                            </div>
                                            <table className="table table-bordered">
                                                <tr><td>Item ID</td><td>:</td><td>{row?.PrivateItemId}</td></tr>

                                                {/* <tr><td style={{ width: 160 }}>Private Document Hash</td><td style={{ width: 10 }}>:</td><td>{row?.PrivateDocumentHash}</td></tr> */}


                                                <tr>
                                                    <td style={{ width: 160 }}>Token</td><td style={{ width: 10 }}>:</td>
                                                    <td>
                                                        {row?.PrivateTokenId}
                                                        <a href="" onClick={(e) => {
                                                            e.preventDefault();
                                                            props.onRowClick(row?.PrivateTokenId, 'verify_token');
                                                        }}>Verify</a>

                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td style={{ width: 160 }}>Public Document Hash</td><td>:</td>
                                                    <td>
                                                        {row?.PublicDocumentHash}
                                                        {
                                                            row?.PublicDocumentHash ? (
                                                                <a href="" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    props.onRowClick(row, 'verify');
                                                                }}>Verify</a>) : null
                                                        }
                                                    </td>
                                                </tr>
                                                <tr><td>Network</td><td>:</td><td>{row?.PublicNetworkName}</td></tr>
                                                <tr><td>Qualtity</td><td>:</td><td>{row?.PublicQuantity}</td></tr>
                                                <tr>
                                                    <td>Minted At</td><td>:</td>
                                                    <td>
                                                        {row?.PublicMintedDate != null && row?.PublicMintedDate != "None" && row?.PublicMintedDate != "" ? moment(row?.PublicMintedDate).format("MMM, DD YYYY") : ""}
                                                    </td>
                                                </tr>
                                                <tr><td>Transfered</td><td>:</td><td>{row?.IsTransferred ? "Yes" : "No"}</td></tr>
                                                <tr>
                                                    <td>Transfered At</td><td>:</td>
                                                    <td>
                                                        {row?.PublicTransferredDate != null && row?.PublicTransferredDate != "None" && row?.PublicTransferredDate != "" ? moment(row?.PublicTransferredDate).format("MMM, DD YYYY") : ""}
                                                    </td>
                                                </tr>
                                                <tr><td>Tags</td><td>:</td><td>{row?.PublicTags}</td></tr>


                                            </table>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )

}

export default Row;