import { useEffect, useState } from "react";
import VuiButton from "components/VuiButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
export default function ShowPdfPopup(props) {
  const { url, title } = props;

  const [open, SetOpen] = useState(false);

  useEffect(() => { }, []);

  const handleClose = () => {
    SetOpen(false);
    props.SetOpen(false);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
        <DialogTitle>{title ? title : "VIEW LABEL"}</DialogTitle>
        <DialogContent>
          <div className="App">
            <iframe src={url} height="800" width={"100%"} />
          </div>
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={handleClose} variant="contained" color="info">
            Close
          </VuiButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
