import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.6)'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function HazardousPopUp() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: 'contents' }}>
      <a onClick={handleClickOpen} style={{ color: '#2c56a5' }}>
        hazardous materials
      </a>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg" fullWidth={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Shipping Hazardous Materials
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            Common items like nail polish, perfume, and hair spray are considered hazardous material, and require special procedures when shipping them.
          </Typography>
          <Typography variant="h5" mt={1} gutterBottom>
            Shipping Hazardous Materials with USPS®
          </Typography>
          <Typography variant="body2" gutterBottom>
            It's important to read all the USPS rules about Hazardous Materials when shipping anything considered dangerous. You are liable for any failure to follow the rules.
            <br />
            For common, less-hazardous items like perfume or hand sanitizer, you're simply not allowed to use air-based services like First Class Package, Priority Mail, and Priority Mail Express. This means <span style={{ textDecoration: 'underline' }}>Parcel Select Ground</span> is your only option. You also need to follow a few steps, explained below.
          </Typography>
          <Typography variant="h5" mt={1} gutterBottom>
            How to ship hazardous materials
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you want to ship hazardous materials with USPS, you’ll need to abide by the following guidelines:
            <br />
            <List>
              <ListItem>
                <ListItemText
                  secondary="• Choose Parcel Select Ground as your service"
                />
              </ListItem>
              <ListItem >
                <ListItemText
                  style={{ display: 'contents' }}
                  secondary='• Place a "limited quantity" sticker on the label side of your package. You can buy them'
                />&nbsp;<a href='https://www.labelmaster.com/shop/labels/regulated-markings-and-labels/limited-quantity-labels/limited-quantity-labels-blank/limited-quantity-label-blank-101mm-x-101mm-paper-lql40s' target="_blank">here</a>
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary='• Select the "Hazardous Materials" checkbox on the DPS Package Wizard.'
                />
              </ListItem>
            </List>
          </Typography>
          <Typography variant="h5" mt={1} gutterBottom>
            How to ship hazardous materials
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                secondary="• Aerosols, like hair spray"
              />
            </ListItem>
            <ListItem >
              <ListItemText
                style={{ display: 'contents' }}
                secondary='• Cleaning agents and solvents'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='• Cigarette lighter fluid'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='• Compressed oxygen'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='• Fuels'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='• Lithium ion batteries'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='• Inks'
              />
            </ListItem>

            <ListItem>
              <ListItemText
                secondary='• Matches'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='• Nail polish'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='• Paint'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='• Perfumes/colognes'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='• Pre-owned or damaged electronics with lithium ion batteries'
              />
            </ListItem>
          </List>
          <Typography variant="h5" mt={1} gutterBottom>
            Rules for shipping electronic devices containing lithium ion batteries
          </Typography>
          <Typography variant="body2" gutterBottom>
            Brand new electronics with unopened manufacturer’s packaging that contain lithium ion batteries inside can be shipped via any USPS service… but if you’re shipping a used, damaged, or defective electronic device with a lithium ion battery (such as a phone, tablet, laptop, camera, power tool, etc.), you’ll need to follow these guidelines:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                secondary="• You may only use the Parcel Select Ground shipping service, as electronics with used or damaged lithium ion batteries cannot be transported via air." />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary="• If you’re shipping multiple items, separate the lithium ion batteries or electronics containing lithium ion batteries into a different package." />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary='• Label the package with: “Restricted Electronic Device – Surface Transportation Only.”' />
            </ListItem>
          </List>
          <Typography variant="body2" gutterBottom>
            Shipments containing pre-owned or damaged electronics devices that contain lithium ion batteries are prohibited from being shipped internationally or to military addresses and certain parts of the United States where USPS doesn’t have ground transportation available.
          </Typography>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
