import VuiBox from "components/VuiBox";
// @mui material components
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import VuiTypography from "components/VuiTypography";
import { RadioGroup, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import TokenIcon from '@mui/icons-material/Token';
import dps_logo from "../../../../../../assets/images/dps.png";

const RecordManagement = (props) => {
  const { prices } = props;
  const [CertificateOfMailing, setCertificateOfMailing] = useState(
    props.CertificateOfMailing?.Code || "COM"
  );

  useEffect(() => {
    if (props.CertificateOfMailing?.Code) {
      setCertificateOfMailing(props.CertificateOfMailing?.Code);
    } else {
      var data = _.find(prices || [], { Code: "COM" });
      props.setActCertificateOfMailing(data);
    }
  }, [props.CertificateOfMailing, prices]);

  const handleChange = (e) => {
    setCertificateOfMailing(e.target.value);
  };

  const HandleRadioClick = (data) => {
    props.setActCertificateOfMailing(data);
  };

  return (
    <VuiBox
      mt={1}
      color="white"
      bgColor="white"
      shadow="lg"
      opacity={1}
      p={2}
      style={{ borderRadius: "10px", height: "260px", }}
    >
      <RadioGroup name="CertificateOfMailing" value={CertificateOfMailing} onChange={handleChange}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <img src={dps_logo} style={{ width: 90 }} />
            {/* <TokenIcon color="dark" style={{ width: '4rem', height: '4rem' }} /> */}
          </Grid>
          <Grid xs={12} pl={3.3}>
            <VuiTypography variant="lg" color="dark">
              Certificate of Mailing
            </VuiTypography>
          </Grid>
        </Grid>
        <Grid container>
          {_.map(prices || [], (price, i) => (

            <Grid item xs={12} md={12}>
              <FormControlLabel value={price.Code} control={<Radio />} label={
                <span style={{ color: "#344747", fontSize: 15 }}>
                  {price?.Name}
                  &nbsp;&nbsp;&nbsp;
                  <span>
                    {
                      price?.Price <= 0 ? "Included" : `$${price?.Price}`
                    }
                  </span>
                </span>
              } onClick={() => {
                HandleRadioClick(price);
              }} />
            </Grid>


          ))}

          <Grid item xs={12} md={12}>
            <FormControlLabel value={'No'} control={<Radio />} label={"None"} onClick={() => {
              HandleRadioClick({ Code: 'No', Name: 'None', Price: 0 });
            }} />
          </Grid>

        </Grid>


      </RadioGroup>
    </VuiBox>
  );
};
export default RecordManagement;
