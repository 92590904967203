import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

function Spin(props) {
    return (
        <div>
            {
                props?.spinning ? (
                    <div className='dps-loader'>
                        <CircularProgress />
                        <h5>{props?.tip}</h5>
                    </div>
                ) : null
            }
            {
                props?.children
            }
        </div>
    )
}


export default Spin;