import { Paper } from "@mui/material";

function PrivacyPolicy(props) {
    return (
        <div style={{ width: '80%', margin: '0px auto', marginBottom: 50 }}>
            <Paper style={{ padding: 20 }}>
                <div style={{ textAlign: 'center' }}>
                    <h4>Privacy Policy</h4>
                    <span>Effective January, 2022</span>
                    <hr />
                </div>
                <div>
                    <p>
                    The privacy of our customers is of the utmost importance to Digital Postal Services (DPS). We have developed our services and solutions so that your personal information is used in a responsible manner. When you use or access the services and solutions offered by DPS in any capacity, including as a customer, recipient of a parcel, or if you visit our website, you are acknowledging that you understand and accept the practices and policies which are explained in this Privacy Policy. You also consent to allowing DPS to collect, use, and share your information in the ways outlined in the Privacy Policy. It is important to note that this Privacy Policy is not a contract and does not create any legal rights or obligations.
                    </p>
                    <p>
                    Whenever you use the services or solutions from DPS, you are subject to the Terms of Use, which includes the Privacy Policy. Terms are defined in either the Privacy Policy or Terms of Use. 
                    <br/>Continue reading to learn more about our privacy policy.
                    </p>
                </div>
                <h5>Sale of Personal Information</h5>
                <p>We do not sell or rent your personal or company information to third parties.</p>
                <h5>Collection of Personal Information</h5>
                <p>We collect personal information about consumers and allow third parties to collect personal information on our behalf. In the last twelve months, we have collected the following categories of personal information for the business purpose(s ) identified below (some categories may overlap):</p>
                <div>
                        <table className="table table-bordered" style={{ width: '100%' }} border="1" cellSpacing={1} cellPadding={1}>
                            <thead>
                                <tr>
                                    <th style={{ width: '45%' }}>Category of Information</th>
                                    <th style={{ width: '5%' }}>Collected?</th>
                                    <th style={{ width: '50%' }}>Business Purpose(s) for Use</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Identifiers such as a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name.</td>
                                    <td>YES</td>
                                    <td>
                                        <ul>
                                            <li>To comply with legal obligations</li>
                                            <li>To maintain an ongoing business relationship with you</li>
                                            <li>To verify your identity</li>
                                            <li>To provide goods and services to you</li>
                                            <li>To improve our internal processes and the services we offer</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Any categories of personal information described in subdivision (e) of Section 1798.80 (the California Customer Records statute).</td>
                                    <td>YES</td>
                                    <td>
                                        <ul>
                                            <li>To comply with legal obligations</li>
                                            <li>To maintain an ongoing business relationship with you</li>
                                            <li>To verify your identity</li>
                                            <li>To provide goods and services to you</li>
                                            <li>To improve our internal processes and the services we offer</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Characteristics of protected classifications under California or federal law.

                                    </td>
                                    <td>NO</td>
                                    <td>
                                        We do not collect this information. This type of information may be received incidental to other information you provide to us for business purposes, such as when you provide a copy of a drivers’ license. However, we do not use this information.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Commercial information, including records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
                                    <td>NO</td>
                                    <td>We do not collect this information.</td>
                                </tr>
                                <tr>
                                    <td>Biometric information</td>
                                    <td>YES</td>
                                    <td>
                                    We do collect this information. This type of information may be received incidental to other information you provide to us for business purposes, such as when you provide a copy of a drivers’ license. However, we do not use this information.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement.</td>
                                    <td>NO</td>
                                    <td>We do not collect this information.</td>
                                </tr>
                                <tr>
                                    <td>Geolocation data.</td>
                                    <td>YES</td>
                                    <td>We may collect this information to ensure account authentication and for service performance</td>
                                </tr>
                                <tr>
                                    <td>Professional or employment-related information.

                                    </td>
                                    <td>YES</td>
                                    <td>
                                        <ul>
                                            <li>To comply with legal obligations.</li>
                                            <li>To maintain an ongoing business relationship with you.</li>
                                            <li>To verify your identity.</li>
                                            <li>To provide goods and services to you.</li>
                                            <li>To improve our internal processes and the services we offer</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    <h5>Privacy Notice for California Residents</h5>
                    <p>Privacy Notice for California Residents (this “California Notice”) to supplement the information and disclosures contained in our Website Privacy Policy. This California Notice applies to all customers, website visitors, and others who reside in the State of California. We adopt this California Notice to comply with the California Consumer Privacy Act of 2018 (“CCPA”), which is effective January 1, 2015. Any terms defined in the CCPA have the same meaning when used in this California Notice.<br/>
                        California residents have the right to request that a business disclose what personal information it collects, uses, discloses, and sells. The CCPA allows California residents to request that a business that collects consumers’ personal information disclose to consumers the categories of personal information that the business collects from consumers, the business or commercial purposes for collecting the information, and the categories of third parties with which the information is shared. California residents also have the right to submit a “Verifiable Consumer Request” to a business to request access in a portable and readily usable form (if technologically feasible) to the specific pieces and categories of personal information that the business has collected about the consumer, and to request the deletion of information under certain circumstances. Consumers have the right not to receive discriminatory treatment by a business for the exercise of the privacy rights conferred by the CCPA.</p>
                    <div>
                        <h5>Sharing of Personal Information</h5>
                        <p>
                            In the last twelve months, we have shared the following categories of personal information with the following categories of third parties (some categories may overlap):
                        </p>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Category of Information</th>
                                <th>Shared?</th>
                                <th>Categories of Third Parties</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Identifiers such as a real name, Company alias, postal address, or other similar identifiers.</td>
                                <td>YES</td>
                                <td>
                                    <ul>
                                        <li>United States Postal Service (USPS)</li>
                                        <li>USPS ePrint providers</li>
                                        <li>Payment processors</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Any categories of personal information described in subdivision (e) of Section 1798.80 (the California Customer Records statute).</td>
                                <td>YES</td>
                                <td>
                                    <ul>
                                        <li>United States Postal Service (USPS)</li>
                                        <li>USPS Printer Service</li>
                                        <li>Courts</li>
                                        <li>Payment processors</li>
                                        <li>Debt collectors</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Characteristics of protected classifications under California or federal law.</td>
                                <td>NO</td>
                                <td>We do not collect this information. This type of information may be received incidental to other information you provide to us for business purposes, such as when you provide a copy of a driver's license. However, we do not use this information.</td>
                            </tr>
                            <tr>
                                <td>Commercial information, including records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
                                <td>NO</td>
                                <td>We do not collect this information.</td>
                            </tr>
                            <tr>
                                <td>Biometric information</td>
                                <td>YES</td>
                                <td>We do collect this information. This type of information may be received incidental to other information you provide to us for business purposes, such as when you provide a copy of a driver's license. However, we do not use this information.</td>
                            </tr>
                            <tr>
                                <td>Browser type, device type information</td>
                                <td>YES</td>
                                <td>Collected when submitting a support ticket. This information is used only to resolve the ticket.</td>
                            </tr>
                            <tr>
                                <td>Geolocation data</td>
                                <td>YES</td>
                                <td>We do not collect this information.</td>
                            </tr>
                            <tr>
                                <td>Audio, electronic, visual, or similar information.</td>
                                <td>NO</td>
                                <td>We do not collect this information. This type of information may be received incidental to other information you provide to us for business purposes, such as email correspondence or voice mail.</td>
                            </tr>
                        </tbody>
                    </table>
               
                    <div>
                        <h5>Right to Know Requests</h5>
                        <p>
                            If you are a California resident, you have the right to request that we disclose to you:
                        </p>
                        <div style={{ marginLeft: 30 }}>
                            <p>1. The categories of personal information we have collected about you.</p>
                            <p>2. The categories of sources from which the personal information is collected.</p>
                            <p>3. The business or commercial purpose for collecting personal information.</p>
                            <p>4. The categories of third parties with whom we share personal information.</p>
                            <p>5. The specific pieces of personal information we have collected about you.</p>
                        </div>
                        <div>
                            To submit a request, please review "How to Submit a Request" below.
                        </div>
                        <br />
                        <div>
                            <h5>Deletion Requests</h5>
                            <p>
                                If you are a California resident, you have the right to request the deletion of your personal information. We may claim legal exemptions for deletion of certain types of personal information pursuant to various CCPA exemptions. To submit a request, please review <b>“How to Submit a Request” below</b>.
                            </p>
                        </div>
                        <div>
                            <h5>How to Submit a Request</h5>
                            <p>
                                To submit a request under the CCPA, please call our toll-free number: <a href="tel:+18003089113">800-308-9113</a> X 1 or email request to: <a href="mailto:privacy@dps.io">privacy@dps.io</a>
                            </p>
                        </div>
                        <div>
                        <h5>Is Personal Information about me secure?</h5>
                        <p>We use all appropriate technical, organizational and administrative security measures to protect any information we hold in our records from loss, misuse, and unauthorized access, disclosure, alteration and destruction. Unfortunately, no company or service can guarantee complete security. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time.<br/>
                        <br/>Among other practices, your account is protected by a password for your privacy and security. If you access your account via a third party site or service, you may have additional or different sign-on protections via that third party site or service. You must prevent unauthorized access to your account and Personal Information by selecting and protecting your password and/or other sign-on mechanism appropriately and limiting access to your computer or device and browser by signing off after you have finished accessing your account.
                        </p>
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    );
}
export default PrivacyPolicy;
