import { useEffect, useState } from "react";
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { setActiveStep } from "redux/Actions/wizard";
import { CircularProgress } from "@mui/material";
import {
  ResetWizard,
} from "redux/Actions/wizard";
import { setLoaderStatus } from "redux/Actions/common";
import { useLocation } from "react-router-dom";
import validations from "layouts/applications/wizard/schemas/nft-validations";
import form from "layouts/applications/wizard/schemas/nft-form";
import initialValues from "layouts/applications/wizard/schemas/initialValues";
import NFTDocumentUpload from "./nft_document_upload";
import PrivateBlockChainDocumentUpload from "./private-blockchain-document-upload.js"

import axios from "axios";
import { setNftRes } from "redux/Actions/nft";
import { Prompt } from "react-router";
import Link from "@mui/material/Link";

import { useHistory } from "react-router-dom";
import Web3 from "web3";
import abi from "./abi";

let nftTokenAddress = "";
let marketplaceAddress = "";
let contractOwner = "";
let user = "";
const web3 = new Web3(Web3.givenProvider || "https://cloudflare-eth.com");


const packageTypeStepper = [
  "Delivery Method",
  "Upload Document",
  // "General",
  // "Recipient",
  // "Package",
  // "Postage",
  // "Certificates",
  // "Ship Date",
  // "Summary",
];


function getStepContent(
  stepIndex,
  getSendNextBtnState,
  dpsToken,
  formData
) {
  switch (stepIndex) {
    case 0:
      return <PrivateBlockChainDocumentUpload formData={formData} />;
    case 1:
      return <NFTDocumentUpload formData={formData} onDocumentUploaded={getSendNextBtnState} dpsToken={dpsToken} />;
    default:
      return null;
  }
}


function NFTWizard(props) {
  const {
    active,
    loader,
  } = props;
  const history = useHistory();
  const { formId, formField } = form;
  const currentValidation = validations[active]

  const [selectedDocument, setSelectedDocument] = useState(null);

  const [dpsToken, setDpsToken] = useState("");
  const [privateDocumentHash, setPrivateDocumentHash] = useState("");
  const [privateDocumentKey, setPrivateDocumentKey] = useState(null);
  const [publicDocumentHash, setPublicDocumentHash] = useState("");
  const [publicDocumentKey, setPublicDocumentKey] = useState(null);

  const [NFTToken, setNFTToken] = useState("")
  const [mintingFailed, setMintingFailed] = useState(false)


  const [polygonNetwork, setPolygonNetwork] = useState(false);
  const [nftTokenInstance, setNftTokenInstance] = useState(null);

  const initEthereum = () => {
    if (window.ethereum == null || window.ethereum == undefined)
      return;
    ethereum.request({ method: "eth_requestAccounts" }).then((accounts) => {
      user = web3.utils.toChecksumAddress(accounts[0]);

      detectNetwork().then((identify) => {
        setPolygonNetwork(identify.networkId);
        nftTokenAddress = identify.nftTokenAddress;
        let _nftTokenInstance = new web3.eth.Contract(abi.NFTToken, nftTokenAddress, {
          from: user,
        });
        setNftTokenInstance(_nftTokenInstance);
      });
    });
  }

  useEffect(() => {
    if (props?.profile?.Permissions != null && props?.profile?.Permissions?.NftAccess == true) {
      initEthereum();
    }
  }, []);


  async function detectNetwork() {
    let network = window.ethereum.networkVersion;
    let networkId = "";
    if (network == 1) {
      network = "Mainnet";
      // --- MAINNET ---
      nftTokenAddress = "";
      marketplaceAddress = "";
    } else if (network == 3) {
      alert("Not deployed on this network.");
    } else if (network == 4) {
      network = "Rinkeby";
      // --- RINKEBY ---
      nftTokenAddress = "";
      marketplaceAddress = "";
    } else if (network == 5) {
      network = "Goerly";
      alert("Not deployed on this network.");
    } else if (network == 42) {
      alert("Not deployed on this network.");
    } else if (network == 80001) {
      networkId = network;
      network = "Mumbai";
      nftTokenAddress = "0xed7dde9bc3a550bcdcd7db17e51d2fe8b3186ae5";
      marketplaceAddress = "0x902196df6d3d8233df59c8a80e718838b0f5ba41"; //NEW
      contractOwner = "0xb0F6d897C9FEa7aDaF2b231bFbB882cfbf831D95";
    } else if (network == 137) {
      networkId = network;
      network = "Polygon";
      nftTokenAddress = "0x007b174c440c4d9c50ad4f5cc1278176708b1bb1";
      marketplaceAddress = "";
    }
    return {
      network,
      nftTokenAddress,
      marketplaceAddress,
      contractOwner,
      networkId,
    };
  }

  async function getMintCost() {
    try {
      const mintCost = await nftTokenInstance.methods.cost().call();
      console.log("getMintCost:", mintCost);
      return mintCost;
    } catch (err) {
      console.log(err);
    }
  }

  async function mintToken(numberToMint, hash) {
    setMintingFailed(false);
    props.setLoaderStatus(true);
    let mintCost = await getMintCost();
    let amount = mintCost * numberToMint;
    try {
      const tx = await nftTokenInstance.methods
        .mint(numberToMint)
        .send({ from: user, value: amount });
      console.log("Mint Success Response > ", tx);
      const token = tx.events.Transfer.returnValues.tokenId;



      // createMetaData(tx, token); // This function create meta-data file in s3 bucket. ie. token.json

      // setNFTToken(token);
      setTimeout(() => {
        handleMintCompleted(tx, token, polygonNetwork, hash);
      }, 1000);
    } catch (err) {
      setMintingFailed(true);
      console.log("Minting failed Response > ", err);
      props.setLoaderStatus(false);
      alert("Minting failed!");

    }
  }


  const isLastStep = active === packageTypeStepper.length - 1;

  useEffect(() => {
    props.ResetWizard();
  }, [])

  const handleBack = () => {
    props.setActiveStep(active - 1);
  };

  const getSendNextBtnState = (file) => {
    if (active == 0) {
      setSelectedDocument(file);
    }
    if (file == "send_epostage") {
      props.setActiveStep(active + 1);
    }
  };


  const createMetaData = (tx, tokenId, polygonNetwork, hash, onComplete) => {
    var data = {
      ShipperHash: localStorage.ShipperHash,
      DocumentHash: hash,
      ChainedDocumentHash: privateDocumentHash,
      NftToken: tokenId,
      Network: polygonNetwork,
      SenderWalletAddress: "",
      NftResponse: tx,
    };
    if (polygonNetwork == 'DPS') {
      delete data["ChainedDocumentHash"];
    }

    data = JSON.stringify(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/nfts`,
      headers: {
        Authorization: `Bearer ${localStorage.id_token}`,
        ShipperHash: localStorage.ShipperHash,
        "x-api-key": "6f40874a-9568-457f-8543-901fd614741e",
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        onComplete(response);
      });
  };

  const handleMintCompleted = (nftTokenResponse, _nftToken, network, hash) => {
    props.setLoaderStatus(true);
    createMetaData(nftTokenResponse, _nftToken, network, hash, () => {
      setNFTToken(_nftToken)
      props.setLoaderStatus(false);
      history.push('/verified/blockchain?vf=true', { Id: hash, title: "NFT MINTED SUCCESSFULLY" });
    });
    props.setNftRes(_nftToken);
  };

  const create_uuid = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
  };

  const handleSubmit = (values, actions) => {
    if (active == 0) {
      if (privateDocumentHash == null || privateDocumentHash == '' || privateDocumentKey == null || privateDocumentKey == '') {
        createUploadUrl(values, actions, (hash) => {
          //after document upload create nft.
          console.log('Private Hash > ', privateDocumentHash);
          console.log('Private Hash 1 > ', hash);
          let tokenId = create_uuid();
          setDpsToken(tokenId)
          let tx = {
            "token": tokenId,
            "network": -1
          }
          setTimeout(() => {
            createMetaData(tx, tokenId, "DPS", hash, (res) => {
              props.setLoaderStatus(false);
              props.setActiveStep(active + 1);
            })
          }, 3000)
        });
      }
      else {
        props.setActiveStep(active + 1);
      }
    }
    else if (active == 1) {
      createUploadUrl(values, actions, (res) => {
        mintToken(1, res);
      });
    }
    else {

      props.setActiveStep(active + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const uploadMedia = (data, media, onComplete) => {
    props.setLoaderStatus(true);

    var xhr = new XMLHttpRequest();
    xhr.open("PUT", data.Url, true);
    xhr.setRequestHeader("Content-Type", media.type);
    xhr.onload = () => {
      onComplete();
    };
    xhr.onerror = () => {
      props.setLoaderStatus(false);
      // error...
    };
    xhr.send(media);
  };

  const createUploadUrl = (values, actions, onDocumentUploadComplete) => {
    props.setLoaderStatus(true);

    var data = {
      ShipperHash: localStorage.ShipperHash,
      FileName: values.privateDocumentMedia.name,
      DocumentName: values.documentName,
      Tags: values.documentTag,
      Description: values.documentDescription,
      ItemId: values.documentItemId,
      Category: values.documentCategory,
    };
    if (active == 1) {
      data["FileName"] = values.documentMedia.name;
      delete data["ItemId"];
    }
    data = JSON.stringify(data);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/documents`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.id_token}`,
        ShipperHash: localStorage.ShipperHash,
        "x-api-key": "6f40874a-9568-457f-8543-901fd614741e"
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.setLoaderStatus(false);
        if (response.status === 200) {
          if (active == 1) {
            // it means document created on private blockchain. Now create document on public block chain.
            setPublicDocumentHash(response.data.DocumentHash);
            // publicDocumentHash = response.data.DocumentHash;
            setPublicDocumentKey(response.data.Key);
            uploadMedia(response.data, values.documentMedia, () => {
              //on document upload complete;
              onDocumentUploadComplete(response.data.DocumentHash);
            });
          }
          else {
            setPrivateDocumentHash(response.data.DocumentHash);
            setPrivateDocumentKey(response.data.Key);

            uploadMedia(response.data, values.privateDocumentMedia, () => {
              //on document upload complete;
              // props.setActiveStep(active + 1);
              onDocumentUploadComplete(response.data.DocumentHash);
            });
          }

        }
      }).catch((error) => {
        props.setLoaderStatus(false);
        if (error.response.data.error.indexOf("already exists") > -1) {
          actions?.setFieldError("documentItemId", error.response.data.error);
        }
      })
  };

  return (
    <>
      <Prompt
        when={active > 0 && NFTToken == ""}
        message="You are editing something, your changes will be lost if you navigate from the page."
      />
      <VuiBox pt={3} pb={10} style={{ width: "80%", margin: "0px auto" }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12} lg={12}>
            <VuiBox mt={1} mb={4} textAlign="center">
              <VuiBox mb={1}>
                <VuiTypography variant="h3" fontWeight="bold" color="dark">
                  NFT / COA WIZARD
                </VuiTypography>
              </VuiBox>
            </VuiBox>
            {
              props?.profile?.Permissions == null || props?.profile?.Permissions?.NftAccess == false ? (
                <Card>
                  <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    The create NFT & Send NFT function is only available to merchants after they submit an application to prove they are a licensed business and are the manufactures or creators of the items to be shipped
                    <br />
                    <iframe frameBorder={0} style={{ height: 1500, width: '99%', border: 'none' }} src='https://forms.zohopublic.com/dpsportal8/form/NFTCOAREQUEST/formperma/T2LOjl812Ib81penBF3lVHEG1ZCxPkf_BRndYgzef3A'></iframe>
                  </div>
                </Card>
              ) : (
                <Formik
                  initialValues={initialValues}
                  validationSchema={currentValidation}
                  onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError,
                  }) => (
                    <Form id={formId} autoComplete="off" onKeyDown={(keyEvent) => {
                      if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                        keyEvent.preventDefault();
                      }
                    }}>
                      <Card>
                        <VuiBox p={2}>
                          {
                            window.ethereum == null || window.ethereum == undefined ? (
                              <div style={{ textAlign: 'center' }}>
                                <h5>Your MetaMask wallet is not installed or configured properly.</h5>

                                <Link href="https://metamask.io/download.html" target={"_blank"}>
                                  <VuiTypography variant="button" color="primary">Install</VuiTypography>
                                </Link>
                              </div>
                            ) : (
                              <VuiBox>
                                {
                                  getStepContent(
                                    active,
                                    getSendNextBtnState,
                                    dpsToken,
                                    {
                                      values,
                                      touched,
                                      formField,
                                      errors,
                                      setFieldValue,
                                      setFieldTouched,
                                      setFieldError,
                                    }
                                  )}
                                <VuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                  {active === 0 || NFTToken != "" ? (
                                    <VuiBox />
                                  ) : (
                                    <VuiButton variant="gradient" color="light" onClick={handleBack} disabled={loader}>
                                      back
                                    </VuiButton>
                                  )}
                                  {
                                    mintingFailed && NFTToken == "" ? (
                                      <VuiButton
                                        variant="contained"
                                        color="info"
                                        type="button"
                                        onClick={() => {
                                          mintToken(1, publicDocumentHash);
                                        }}
                                        disabled={loader}
                                      >
                                        Mint Again

                                        {loader ? <>&nbsp;&nbsp;<CircularProgress color={"error"} size={15} /></> : ""}
                                      </VuiButton>
                                    ) : (
                                      NFTToken == "" ? <VuiButton
                                        variant="contained"
                                        color="info"
                                        type="submit"
                                        disabled={loader}
                                      >
                                        {
                                          isLastStep ? "Approve & Mint On Polygon Network" : "Next"
                                        }

                                        {loader ? <>&nbsp;&nbsp;<CircularProgress color={"error"} size={15} /></> : ""}
                                      </VuiButton> : null
                                    )
                                  }


                                  {/* {NFTToken ? (
                                <div>
                                  <VuiButton
                                    variant="contained"
                                    color="info"
                                    onClick={() => {
                                      history.push("/send/e-postage", {
                                        step: 0,
                                        Id: publicDocumentHash,
                                      });
                                    }}
                                  >
                                    Ship Now
                                  </VuiButton>
                                  &nbsp;&nbsp;
                                  <VuiButton
                                    variant="contained"
                                    color="info"
                                    onClick={() => {
                                      history.push('/nft')
                                    }}
                                  >
                                    Ship Later
                                  </VuiButton>
                                </div>
                              ) : null} */}



                                </VuiBox>
                              </VuiBox>
                            )
                          }
                        </VuiBox>
                      </Card>
                    </Form>
                  )}
                </Formik>
              )
            }
          </Grid>
        </Grid>
      </VuiBox>
    </>
  );
}

export default connect(
  (state) => ({
    active: state.WizardStore.activeStep,
    loader: state.CommonStore.loader,
    profile: state.CommonStore.Profile,

  }),
  {
    setActiveStep,
    ResetWizard,
    setLoaderStatus,
    setNftRes,
  }
)(NFTWizard);
