

import * as Yup from "yup";
import checkout from "layouts/applications/wizard/schemas/e-notraize-form";

const {
  formField: { documentMedia, documentName, documentDescription, documentCategory },
} = checkout;

export default [
  Yup.object().shape({
    [documentMedia.name]: Yup.string().required(documentMedia.errorMsg),
    [documentName.name]: Yup.string().required(documentName.errorMsg),
    [documentDescription.name]: Yup.string().required(documentDescription.errorMsg),
    [documentCategory.name]: Yup.string().required(documentCategory.errorMsg),

  }),
  Yup.object().shape({
  }),
];
