
import { SET_VERIFY_RESPONSE, SET_VERIFY_WIZARD } from "../Type";
import { setLoaderStatus } from "./common";
import { VerificationApi } from "redux/Api/verify";

export const getVerificationApi = (data) => (dispatch) => {
  dispatch(setLoaderStatus(true));
  dispatch({
    type: SET_VERIFY_RESPONSE,
    data: null,
  });

  return VerificationApi(data).then((res) => {
    if (res.status === 200) {
      dispatch(setLoaderStatus(false));
      dispatch({
        type: SET_VERIFY_RESPONSE,
        data: res.data,
      });
      return {
        success: true,
      };
    } else {
      dispatch(setLoaderStatus(false));
      return {
        success: false,
      };
    }
  });
};

export const resetVerifyWizard = () => {
  return {
    type: SET_VERIFY_WIZARD,
  };
};
