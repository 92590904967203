

export default {
  formId: "wizard-form",
  formField: {
    documentMedia: {
      name: "documentMedia",
      label: "Document",
      type: "file",
      placeholder: "Document",
      errorMsg: "Document is required.",
    },
    documentName: {
      name: "documentName",
      label: "Item Name",
      type: "text",
      placeholder: "Item Name",
      errorMsg: "This field is required!",
    },
    documentDescription: {
      name: "documentDescription",
      label: "Item Description",
      type: "text",
      placeholder: "Item Description",
      errorMsg: "This field is required!",
    },
    documentCategory: {
      name: "documentCategory",
      label: "Item Category",
      type: "text",
      placeholder: "Item Category",
      errorMsg: "This field is required!",
    },
    documentItemId: {
      name: "documentItemId",
      label: "Item ID",
      type: "text",
      placeholder: "Item ID, SKU, Serial",
      errorMsg: "This field is required!",
    },
    documentTag: {
      name: "documentTag",
      label: "Item Tag",
      type: "text",
      placeholder: "Item Tag",
      errorMsg: "This field is required!",
    },
    caseName: {
      name: "caseName",
      label: "Order Name/ID",
      type: "text",
      placeholder: "Order Name/ID",
      errorMsg: "This field is required.",
    },
    mailingName: {
      name: "mailingName",
      label: "Shipment Name",
      type: "text",
      placeholder: "Shipment Name",
      errorMsg: "This field is required.",
    },
    IsNFT: {
      name: "IsNFT",
      label: "IsNFT",
      type: "text",
      placeholder: "IsNFT",
      errorMsg: "This field is required.",
    },
    senderName: {
      name: "senderName",
      label: "Sender Name",
      type: "text",
      placeholder: "Sender Name",
      errorMsg: "Sender name is required.",
    },
    companyName: {
      name: "companyName",
      label: "Sender Company Name",
      type: "text",
      placeholder: "Sender Company Name",
    },
    address1: {
      name: "address1",
      label: "Address 1",
      type: "text",
      placeholder: "Address 1",
      errorMsg: "Address is required.",
    },
    address2: {
      name: "address2",
      label: "Address 2",
      type: "text",
      placeholder: "Address 2",
    },
    city: {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "City",
      errorMsg: "City is required.",
    },
    state: {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "State",
      errorMsg: "State is required.",
    },
    zip: {
      name: "zip",
      label: "Zip",
      type: "text",
      placeholder: "zip",
      errorMsg: "Zip is required.",
      invalidMsg: "Invalid Zipcode",
    },
    recipientName: {
      name: "recipientName",
      label: "Recipient Name",
      type: "text",
      placeholder: "Recipient Name",
      errorMsg: "Sender name is required.",
    },
    recCompanyName: {
      name: "recCompanyName",
      label: "Recipient Company Name",
      type: "text",
      placeholder: "Recipient Company Name",
    },
    recAddress1: {
      name: "recAddress1",
      label: "Address 1",
      type: "text",
      placeholder: "Address 1",
      errorMsg: "Address is required.",
    },
    recAddress2: {
      name: "recAddress2",
      label: "Address 2",
      type: "text",
      placeholder: "Address 2",
    },
    recCity: {
      name: "recCity",
      label: "City",
      type: "text",
      placeholder: "City",
      errorMsg: "City is required.",
    },
    recState: {
      name: "recState",
      label: "State",
      type: "text",
      placeholder: "State",
      errorMsg: "State is required.",
    },
    recWallet: {
      name: "recWallet",
      label: "Wallet address",
      type: "text",
      placeholder: "Wallet address",
      errorMsg: "Wallet address is required.",
      // invalidMsg: "Invalid Blockchain Wallet Address",
    },
    recZip: {
      name: "recZip",
      label: "Zip",
      type: "text",
      placeholder: "Zip",
      errorMsg: "Zip is required.",
      invalidMsg: "Invalid Zipcode",
    },
    flatRate: {
      name: "flatRate",
      label: "Flat Rate",
      type: "text",
      placeholder: "Select Flat Rate",
      errorMsg: "Flat rate is required.",
    },
    packageWeight: {
      name: "packageWeight",
      label: "Package Weight",
      type: "number",
      placeholder: "Package Weight",
      errorMsg: "Package weight is required.",
    },
    unit: {
      name: "unit",
      label: "Unit",
      type: "text",
      placeholder: "Select Unit",
      errorMsg: "Unit is required.",
    },
    customLength: {
      name: "customLength",
      label: "Length (inches)",
      type: "number",
      placeholder: "Select Unit",
      errorMsg: "Length is required.",
    },
    customWidth: {
      name: "customWidth",
      label: "Width (inches)",
      type: "number",
      placeholder: "Select Unit",
      errorMsg: "Width is required.",
    },
    customHeight: {
      name: "customHeight",
      label: "Height (inches)",
      type: "number",
      placeholder: "Select Unit",
      errorMsg: "Height is required.",
    },
    softPackLength: {
      name: "softPackLength",
      label: "Length (inches)",
      type: "number",
      placeholder: "Select Unit",
      errorMsg: "Length is required.",
    },
    softPackWidth: {
      name: "softPackWidth",
      label: "Width (inches)",
      type: "number",
      placeholder: "Select Unit",
      errorMsg: "Width is required.",
    },
    softPackHeight: {
      name: "softPackHeight",
      label: "Height (inches)",
      type: "number",
      placeholder: "Select Unit",
      errorMsg: "Height is required.",
    },
    shipDate: {
      name: "shipDate",
      label: "Ship Date",
      type: "text",
      placeholder: "Ship Date",
      errorMsg: "Ship date is required.",
    },
    IsHazardous: {
      name: "IsHazardous",
      label: "Ship Date",
      type: "checkbox",
    },
  },
};
