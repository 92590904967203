
import { Route } from "react-router-dom";

function PublicRoute(props) {
  const { component: ComponentInRoute, token, ...rest } = props;
  return (
    <Route {...rest}>
      <ComponentInRoute />
    </Route>
  );
}

export default PublicRoute;
