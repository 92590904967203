

import VuiButton from "components/VuiButton";
import VuiBox from "components/VuiBox";
import { useHistory } from "react-router-dom";

function SidenavCard() {
  const history = useHistory();

  const handleSignOut = () => {
    const logout_uri = "/sign-in";

    localStorage.clear();
    history.push(logout_uri);
  };

  return (
    <VuiBox mt={1} md={12}>
      <VuiButton variant="outlined" fullWidth size="medium" onClick={handleSignOut} color="dark">
        Sign Out
      </VuiButton>
    </VuiBox>
  );
}

export default SidenavCard;
