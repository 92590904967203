import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import Grid from "@mui/material/Grid";
import abi from "../../nft-wizard/abi";
import Web3 from "web3";
import axios from "axios";
import Spin from "components/Spin";

let nftTokenAddress = "";
let marketplaceAddress = "";
let contractOwner = "";
let user = "";

const web3 = new Web3(Web3.givenProvider || "https://cloudflare-eth.com");

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TransferNFT(props) {
  // const { loading } = props;
  const [open, setOpen] = useState(props?.open);
  const [nftTokenInstance, setNftTokenInstance] = useState(null);
  const [toAddress, setToAddress] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setToAddress(props?.nftAddress);
    ethereum.request({ method: "eth_requestAccounts" }).then((accounts) => {
      user = web3.utils.toChecksumAddress(accounts[0]);

      detectNetwork().then((identify) => {
        // setPolygonNetwork(identify.networkId);

        nftTokenAddress = identify.nftTokenAddress;
        let _nftTokenInstance = new web3.eth.Contract(abi.NFTToken, nftTokenAddress, {
          from: user,
        });
        setNftTokenInstance(_nftTokenInstance);
      });
    });
  }, []);

  async function detectNetwork() {
    let network = window.ethereum.networkVersion;
    let networkId = "";
    if (network == 1) {
      network = "Mainnet";
      // --- MAINNET ---
      nftTokenAddress = "";
      marketplaceAddress = "";
    } else if (network == 3) {
      alert("Not deployed on this network.");
    } else if (network == 4) {
      network = "Rinkeby";
      // --- RINKEBY ---
      nftTokenAddress = "";
      marketplaceAddress = "";
    } else if (network == 5) {
      network = "Goerly";
      alert("Not deployed on this network.");
    } else if (network == 42) {
      alert("Not deployed on this network.");
    } else if (network == 80001) {
      networkId = network;
      network = "Mumbai";
      nftTokenAddress = "0xed7dde9bc3a550bcdcd7db17e51d2fe8b3186ae5";
      marketplaceAddress = "0x902196df6d3d8233df59c8a80e718838b0f5ba41"; //NEW
      contractOwner = "0xb0F6d897C9FEa7aDaF2b231bFbB882cfbf831D95";
    } else if (network == 137) {
      networkId = network;
      network = "Polygon";
      nftTokenAddress = "0x007b174c440c4d9c50ad4f5cc1278176708b1bb1";
      marketplaceAddress = "";
    }
    return {
      network,
      nftTokenAddress,
      marketplaceAddress,
      contractOwner,
      networkId,
    };
  }

  const transferComplete = (nftTransferResponse) => {
    var data = JSON.stringify({
      RecipientWalletAddress: toAddress,
      DocumentHash: props.documentHash,
      NftTransferResponse: nftTransferResponse,
    });

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_API_BASE_URL}/nfts`,
      headers: {
        Authorization: `Bearer ${localStorage.id_token}`,
        ShipperHash: localStorage.ShipperHash,
        "x-api-key": "6f40874a-9568-457f-8543-901fd614741e",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.setNftRes(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const transferNFT = async () => {
    setLoading(true);
    try {
      const tx = await nftTokenInstance.methods
        .safeTransferFrom(user, toAddress, props.tokenId)
        .send({ from: user });
      console.log("Transfer Completed Response > ", tx);
      transferComplete(tx);
      setLoading(false);
      props.actPostTrack();
      setOpen(false);
    } catch (err) {
      console.log("Transfer Error Response > ", err);
      alert("Transfer failed");
      setLoading(false);
      setOpen(false);
    } finally {
      setLoading(false);
    }
  };

  const handleToAddressChange = (e) => {
    setToAddress(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log(loading);
  return (
    <div>
      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        style={{ color: "#fff" }}
      >
        Transfer NFT
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth={true}
      >
        <Spin tip="Loading..." spinning={loading}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            NFT Transfer
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2} mb={2}>
                Destination Wallet Address
                <VuiInput
                  value={toAddress}
                  placeholder="wallet address..."
                  onChange={handleToAddressChange}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                transferNFT();
              }}
              variant="contained"
              color="primary"
              style={{ color: "#fff" }}
            >
              Transfer Now
            </Button>
          </DialogActions>
        </Spin>
      </BootstrapDialog>
    </div>
  );
}
