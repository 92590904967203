

// DPS UI  React base styles
import borders from "assets/theme/base/borders";

// DPS UI  React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;

export default {
  styleOverrides: {
    // root: {
    //   display: "block",
    //   padding: `0 ${pxToRem(16)} 0  0`,
    //   borderRadius: `${borderRadius.xl} ${borderRadius.xl} 0 0`,
    // },
  },
};
