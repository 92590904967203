import { useEffect, useState } from "react";
import VuiButton from "components/VuiButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { IconButton } from "@mui/material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
export default function ShowPdfPopup(props) {
  const { url } = props;

  const [open, SetOpen] = useState(false);

  const handleClose = () => {
    SetOpen(false);
  };

  const HandleClick = () => {
    SetOpen(true);
  };

  return (
    <div>
      <a  style={{fontSize:12}} mt={1} onClick={HandleClick}>
      View in full screen
      </a>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
        <DialogTitle>VIEW PDF</DialogTitle>
        <DialogContent style={{ minHeight: 500,overflowY:'hidden' }}>
          <div className="App">
            <embed type="application/pdf" src={url} height="1000px" width="100%" />
          </div>
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={handleClose} variant="contained" color="info">
            Close
          </VuiButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
