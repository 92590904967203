

/** 
  All of the routes for the Vision UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// DPS UI  React layouts
import Default from "layouts/dashboards/default";

// React icons
import { IoDocument } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { IoSend } from "react-icons/io5";
import { IoLocate } from "react-icons/io5";
import { IoLocateOutline } from "react-icons/io5";
import { SiChainlink } from "react-icons/si";

import { IoPeopleCircle } from "react-icons/io5";
import NftIcon from "assets/images/icons/nft-menu-icon.png";

// import nft_svg from "./assets/images/icons/nft_icon.svg";

import SvgIcon from '@mui/material/SvgIcon';
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';



const routes = [
  {
    type: "collapse",
    name: "Mailbox",
    key: "dashboard",
    icon: <IoHome size="15px" color="dark" />,
    noCollapse: true,
    collapse: [
      {
        name: "Default",
        key: "default",
        route: "/dashboard",
        component: Default,
      },
    ],
  },

  {
    type: "collapse",
    name: "Send",
    key: "send",
    icon: <IoSend size="15px" color="dark" />,
    collapse: [
      {
        name: "Document",
        key: "document",
        route: "/documents/e-notarize?k=w",
      },
      {
        name: "Package",
        key: "package",
        route: "/applications/package/type",
      },
      {
        name: "NFT",
        key: "nft_create",
        route: "/nft/create",
      },
    ],
  },
  {
    type: "collapse",
    name: "Track",
    key: "track",
    icon: <GpsFixedOutlinedIcon size="15px" color="dark" />,
    collapse: [
      {
        name: "All Mails",
        key: "all-mails",
        route: "/track/all-mails",
      },
      {
        name: "Print To Mail",
        key: "print-to-mail",
        route: "/track/print-to-mail",
      },
      {
        name: "e Postage",
        key: "e-postage",
        route: "/track/e-postage",
      },
      {
        name: "NFT",
        key: "nft",
        route: "/track/nft",
      },
      // {
      //   name: "Track",
      //   key: "track",
      //   route: "/track",
      // },
    ],
  },

  {
    type: "collapse",
    name: "Verify",
    key: "verify",
    icon: <VerifiedUserOutlinedIcon size="15px" color="dark" />,
    collapse: [
      {
        name: "Verify",
        key: "blockchain",
        route: "/verify/blockchain",
      },
    ],
  },

  {
    type: "collapse",
    name: "NFT - COA",
    key: "nft",
    // icon: <img src={NftIcon} style={{ width: 15, height: 15 }} />,
    icon: <SiChainlink size="15px" color="dark" />,
    collapse: [
      {
        name: "CREATE",
        key: "create",
        route: "/nft/create",
      },
      {
        name: "VIEW",
        key: "list",
        route: "/nft/list",
      },
    ],
  },

  {
    type: "collapse",
    name: "Documents",
    key: "documents",
    icon: <IoDocument size="15px" color="dark" />,
    collapse: [
      // {
      //   name: "E-Notarize",
      //   key: "e-notarize",
      //   route: "/documents/e-notarize",
      // },
      {
        name: "VIEW",
        key: "list",
        route: "/documents/list",
      },
    ],
  },

  {
    type: "collapse",
    name: "Contacts",
    key: "contacts",
    icon: <IoPeopleCircle size="20px" color="dark" />,
    collapse: [
      {
        name: "CREATE",
        key: "create",
        route: "/contacts/create",
      },
      {
        name: "VIEW",
        key: "list",
        route: "/contacts/list",
      },
    ],
  },

  // {
  //   type: "collapse",
  //   name: "Settings",
  //   key: "Settings",
  //   icon: <IoBuild size="15px" color="dark" />,
  //   collapse: [
  //     // {
  //     //   name: "Profile",
  //     //   key: "profile",
  //     //   route: "/profile",
  //     // },
  //     // {
  //     //   name: "Document Verification",
  //     //   key: "document-verification",
  //     //   route: "/stripe-document-verification",
  //     //   component: OrderList,
  //     // },
  //   ],
  // },
];

export default routes;
